import React from "react"

const ProjectNamesTagRender = (props) => {
  const { label, closable, onClose } = props
  return (
    <div className="selected-options">
      <span>{label}</span>
      {closable && (
        <span onClick={(event) => onClose(event)} className="close">
          x
        </span>
      )}
    </div>
  )
}

export default ProjectNamesTagRender
