import { format } from 'date-fns'
import moment from 'moment'

export const noHeaderOptions = [
  '/projectlist',
  '/predictionlist',
  '/useractivity',
  '/userlist',
  '/dashboard',
  '/revenueprediction',
  '/invoicedetails',
  '/companylist',
  '/techstack',
  '/todolist',
  '/settings/jobScheduler',
  '/settings/serverLogs',
  '/salesPersons',
  '/settings/holidays',
  '/employeeResignation',
  '/revenuelist',
  '/settings/identityProvider',
  '/invoiceDashboard',
  '/rateMaster',
]

export const YES = 'Yes'
export const NO = 'No'
export const ALL = 'All'
export const todoInvoicesText = 'invoices'
export const todoPredictionsText = 'predictions'
export const todoProjectsText = 'projects'

export const updatePredictionDesc = 'Kindly update prediction of project'
export const updateInvoiceDesc = 'Kindly update the invoice'

export const showStartDateOrEndDateFields = [
  'Revenue',
  'Commission Settlement',
  'No Invoice',
]
export const hideInoviceFields = ['Commission Settlement', 'No Invoice']
export const showReceivedDateAndReceivedMonthFields = [
  'Deposit Settlement',
  'Received',
  'Partial Received',
]
export const showReceivedDateFields = ['Deposit Settlement']

export const validDomainNames = [
  'simform.com',
  'simform.in',
  'simform.org',
  'simformsolutions.com',
  'simformsolutions.in',
  'simformsolutions.org',
]
export const activeFilterOptions = [
  {
    text: YES,
    value: true,
  },
  {
    text: NO,
    value: false,
  },
]

export const invoiceTemplateField = {
  TECHWISE: 'Techwise',
  COMBINED: 'Combined',
  DEVELOPERWISE: 'Developerwise',
}
export const invoiceTemplateOptions = Object.keys(invoiceTemplateField).map(
  (key) => ({
    label: invoiceTemplateField[key],
    value: invoiceTemplateField[key],
  })
)

export const technologiesOptions = [
  'Resource & Design',
  'Mobile Technologies',
  'Front End Technologies',
  'Back End Technologies',
  'Other Technologies',
]

export const requiredFieldsForPrediction = {
  payModel: 'Pay Model',
  cycle: 'Invoice Cycle',
  accType: 'Account Type',
  invoiceTerm: 'Invoice Term',
}
export const notRequiredInvoiceOptions = [
  'Received',
  'Partial Received',
  'Closed',
]

export const headerItemLabels = {
  PROJECT_DETAILS: 'Project Details',
  RATE_DETAILS: 'Rate Details',
  LEAVE_BALANCE: 'Developers',
  PREDICTION_AND_PENDING_INVOICES: 'Prediction and Pending Invoices',
  CLIENT_DETAILS: 'Client Details',
  PROJECT_NOTES: 'Notes',
}

export const headerItemMapping = {
  INVOICE: headerItemLabels.PREDICTION_AND_PENDING_INVOICES,
  PREDICTION: headerItemLabels.PREDICTION_AND_PENDING_INVOICES,
  RATE: headerItemLabels.RATE_DETAILS,
  SPECIAL_RATE: headerItemLabels.RATE_DETAILS,
  PROJECT: headerItemLabels.PROJECT_DETAILS,
}

export const PREPAID = 'Prepaid'

export const DATE_FORMATS = {
  DMY: 'dd-mm-yyyy', // Format date like '07-01-2023'
  mm_dd_yyyy: 'mm-dd-yyyy', // Format date like '07-01-2023'
  yyyy_mm_dd: 'yyyy-mm-dd', // Format date like '2023-01-07'
  DD_MMM_YYYY: 'DD-MMM-YYYY',
  YYYY_MM_DD: 'YYYY-MM-DD',
  DD_MM_YYYY: 'DD-MM-YYYY',
  yyyy_MM_dd: 'yyyy-MM-dd',
  dd_mmm_yyyy: 'dd-mmm-yyyy', // Format date like '07-Jan-2001'
  mmm_yyyy: 'mmm-yyyy',
  MMM_YYYY: 'MMM-YYYY',
  dd_MMM_yyyy: 'dd-MMM-yyyy',
  DATE_WITH_TIME: "MMM d 'AT' h:m a", // Formats date like 'July 13 AT 9:22 AM'
  MMM_yyyy: 'MMM-yyyy',
}

export const invoiceCycles = {
  MONTHLY: 'Monthly',
  FIFTEEN_DAYS: '15-Days',
  BI_WEEKLY: 'BiWeekly',
  WEEKLY: 'Weekly',
  FOUR_WEEKLY: '4-Weeks',
}

export const dateVariables = {
  START_DATE: 'startDate',
  END_DATE: 'endDate',
  RECEIVED_DATE: 'receivedDate',
  INVOICE_DATE: 'invoiceDate',
}

export const financialTypes = {
  BUYOUT: 'Buyout',
  EXPENSES: 'Expenses',
  COMMISSION_SETTLEMENT: 'Commission Settlement',
  REVENUE: 'Revenue',
  NO_INVOICE: 'No Invoice',
}

export const invoiceStatus = {
  PENDING: 'Pending',
  RECEIVED: 'Received',
  OVERDUE: 'Overdue',
}

export const invoiceServicesOptions = [
  'Development Services',
  'Deduction',
  'Other',
]

export const TOTAL = 'Total'
export const SUB_TOTAL = 'Sub Total'

export const STATUS = {
  ok: 200,
  created: 201,
  badRequest: 400,
  unauthorized: 401,
  forbidden: 403,
  notFound: 404,
  conflictRequest: 409,
}
export const DATE_PICKER = {
  month: 'month',
  date: 'date',
  year: 'year',
}

export const INVOICE_STATUS = {
  PENDING: 'Pending',
  RECEIVED: 'Received',
  PARTIAL: 'Partial Received',
}

export const ignorableErrorFields = {
  salesmans: 'salesmans',
  secondaryCsm: 'secondaryCsm',
  accountantsList: 'accountantsList',
  contractDocument: 'contractDocument',
  marketingChannel: 'marketingChannel',
  retainerActiveBalance: 'retainerActiveBalance',
  retainerActiveDate: 'retainerActiveDate',
}
export const logLevelOptions = ['info', 'error', 'warning']

export const INFO = 'info'
export const ERROR = 'error'
export const WARNING = 'warning'
export const DEFAULT_PAGE_SIZE = 200
export const DEFAULT_PAGE_NO = 1
export const MIN_PAGE_SIZE = 10
export const DEFAULT_PAGINATION_SIZE = 25
export const DEFAULT_INVOICE_FILTER = [{ invoiceStatus: 'Pending' }]

export const TOAST = {
  success: ' success',
  info: 'info',
  warning: 'warning',
  loading: 'loading',
  error: 'error',
  staticInfo: 'staticInfo',
}
export const afteFieldRetainerOptions = [
  { label: 'Hours', value: true },
  { label: 'Amount', value: false },
]
export const tmHoursField = {
  hours: 'Hours',
  amount: 'Amount',
}
export const projectModel = {
  TMRetainer: 'T&M Retainer',
  hourly: 'Hourly',
  MIXED: 'Mixed',
  FIXED_COST: 'Fixed Cost',
  DEDICATED: 'Dedicated',
}
export const payModel = {
  prepaid: 'Prepaid',
  postPaid: 'Postpaid',
}

export const notesStatusFilterOptions = [
  { label: 'All', value: '' },
  { label: 'Pending', value: 'false' },
  { label: 'Completed', value: 'true' },
]
export const nonTaxRegion = ['US', 'UAE']
export const validRegionPrefixes = ['US', 'IND', 'UK', 'CA', 'UAE']
export const taxApplicabilityOptions = [
  'Inclusive of Tax',
  'Exclusive of Tax',
  'Not Applicable',
]

export const predictionAndInvoicesOptions = [
  'Predictions',
  'All Invoices',
  'Pending Invoices',
  'Paid Invoices',
]
export const PREDICTION_AND_INVOICES = {
  predictions: 'Predictions',
  allInvoices: 'All Invoices',
  pendingInvoices: 'Pending Invoices',
  paidInvoices: 'Paid Invoices',
}

export const downloadTimesheetMergeByDateOptions = [
  {
    label: 'Task',
    value: false,
  },
  {
    label: 'Date',
    value: true,
  },
]
export const downloadTimesheetColumnsOption = [
  {
    label: 'Name',
    value: 'Name',
  },
  {
    label: 'Task / Issue / General',

    value: 'Task / Issue / General',
  },
  {
    label: 'Hours',
    value: 'Hours',
  },
  {
    label: 'Date',
    value: 'Date',
  },
]
export const defaultDownloadTimesheetColumnsOption = [
  'Name',
  'Task / Issue / General',
  'Hours',
]

export const afterFieldIncentiveOptions = [
  { label: 'Rate', value: false },
  { label: 'Percentage', value: true },
]
export const afterFieldIncentiveField = {
  rate: 'Rate',
  percentage: 'Percentage',
}
export const incentiveAccountOptions = [
  { label: 'Project', value: false },
  { label: 'Company', value: true },
]
export const incentivesAccountType = {
  company: 'Company',
  project: 'Project',
}
export const booksNameFields = [
  'title',
  'firstName',
  'middleName',
  'lastName',
  'suffix',
]
export const incentiveOrTransactionOptions = ['Incentives', 'Transactions']
export const salesPersonsScreen = {
  incentives: 'Incentives',
  transactions: 'Transactions',
}

export const downloadTransactionOptions = [
  {
    label: 'Date Range',
    value: false,
  },
  {
    label: 'All',
    value: true,
  },
]

export const colorIntensity = (value) => {
  if (value > -1 && value < 1) {
    return 'green'
  } else if (value > -4 && value < 4) {
    return 'light-red'
  } else {
    return 'dark-red'
  }
}

export const zohoProjectSync = 'ZOHO_PROJECT_SYNC'

export const REVSTATUS = {
  prediction: 'PREDICTION',
  invoice: 'INVOICED',
}
export const RevStatusOptions = ['PREDICTION', 'INVOICED']

const currentMonthYearString = format(new Date(), DATE_FORMATS.MMM_yyyy)
export const DEFAULT_REV_FILTER = {
  revenueStatus: [],
  projectName: [],
  projectModel: null,
  payModel: null,
  invoiceCycle: null,
  csmEmail: [],
  clientName: null,
  accountantEmail: null,
  accuracy: null,
  invoiceNumber: null,
  invoiceStatus: [],
  financialType: null,
  invoiceDate: null,
  receivedDate: [],
  startDate: [],
  endDate: [],
  receivedMonth: [currentMonthYearString],
}
export const DEFAULT_PRJ_FILTER = {
  filterValues: {
    projectName: [],
    csm: [],
    accountant: [],
    clientName: [],
    email: [],
    invoiceCycle: [],
    projectModel: [],
    payModel: [],
    accType: [],
    projectCode: [],
    isActive: [true],
    salesPeople: [],
    city: [],
    state: [],
    country: [],
    leadSource: [],
  },
  field: null,
  order: null,
}
export const DEFAULT_COMPANY_FILTER = {
  modifyInBooks: [],
  companyName: [],
  displayName: [],
  currencyCode: [],
  country: [],
  state: [],
  city: [],
  csm: [],
  accountant: [],
}

export const DEFAULT_DASHBOARD_FILTER = {
  projectName: [],
  primaryAccountant: [],
  invoiceCycle: [],
  primaryCsm: [],
}

export const DEFAULT_RATE_FILTER = {
  projectName: [],
  projectModel: [],
  csm: [],
  startDate: moment().format(DATE_FORMATS.DD_MM_YYYY),
  endDate: moment().format(DATE_FORMATS.DD_MM_YYYY),
}

export const serviceOptions = [
  { label: 'Developement Fees', value: 'Developement Fees' },
  { label: 'Deduction', value: 'Deduction' },
  { label: 'Others', value: 'Others' },
]

export const defaultbooksRow = {
  name: 'Development Fees',
  description: null,
  quantity: 0,
  rate: 0,
  taxName: '',
  taxAmount: 0,
  manuallyAdded: true,
}

export const modifyInBooksOptions = [
  {
    text: 'Mapped',
    value: true,
  },
  {
    text: 'Unmapped',
    value: false,
  },
]

export const deductedServices = ['Discount', 'Deduction', 'Bad Debts']
export const billingModelOptions = [
  {
    label: 'Dedicated',
    value: 'Dedicated',
  },
  {
    label: 'Hourly',
    value: 'Hourly',
  },
]
export const providerOptions = ['QUICK_BOOKS', 'ZOHO_BOOKS']

export const taxPreferenceOptions = [
  {
    label: 'Taxable',
    value: true,
  },
  {
    label: 'Tax Exempt',
    value: false,
  },
]

export const taxTreatmentIncludesTrnNo = [
  'business_gst',
  'business_registered_composition',
  'vat_registered',
  'gcc_vat_registered',
  'dz_vat_registered',
]

