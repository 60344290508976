import { createSlice } from '@reduxjs/toolkit'
import { resetAllReduxStates } from './resetAllReduxStatesAction'
const initialState = {
  invoiceDetails: {},
}
const invoiceDetailsSlice = createSlice({
  name: 'invoiceDetails',
  initialState,
  reducers: {
    setInvoiceDetails: (state, { payload }) => {
      state.invoiceDetails = payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetAllReduxStates, () => initialState)
  },
})

export const { setInvoiceDetails } = invoiceDetailsSlice.actions

export const invoiceDetailsSelector = (state) => state.invoiceDetails

export default invoiceDetailsSlice.reducer
