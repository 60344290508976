/* eslint-disable array-callback-return */
import React from 'react'
import moment from 'moment'
import { Collapse } from 'antd'
import { Avatar, List } from 'antd'
import './ListData.scss'
import Button from '../Button/Button'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { getProjectById } from '../../utils/api/projectServices'
import { getCompanydetailsById } from '../../utils/api/companyServices'
import { useDispatch } from 'react-redux'
import { setCurrentCompanyId } from '../../redux/reducer/companyIdSlice'
import {
  headerItemLabels,
  DATE_FORMATS,
  headerItemMapping,
} from '../../utils/constants/constant'

const { Panel } = Collapse

const ListData = ({ date, dataSource }) => {
  const [expanded, setexpanded] = useState(
    dataSource?.map((item) => {
      if (item?.simpleActivityLogs?.length)
        return {
          id: item?.id,
          value: false,
        }
    })
  )
  const dispatch = useDispatch()
  return (
    <div className="list-items-wrap add-project">
      <Collapse accordion>
        <Panel header={moment(date).format(DATE_FORMATS.DD_MMM_YYYY)} key={[1]}>
          <List
            itemLayout="horizontal"
            dataSource={dataSource}
            renderItem={({
              visitor,
              action,
              resourceName,
              projectName,
              resourcePath,
              simpleActivityLogs,
              time,
              projectId,
              id,
            }) => (
              <>
                <List.Item>
                  <List.Item.Meta
                    avatar={<Avatar src="https://joeschmoe.io/api/v1/random" />}
                    title={
                      <p className="activity-title">
                        <span className="activity-username">{visitor} </span>
                        {action}
                        <span>
                          <Link
                            onClick={() => {
                              if (
                                resourceName === 'COMPANY' ||
                                resourceName === 'CLIENT'
                              ) {
                                dispatch(setCurrentCompanyId(projectId))
                                dispatch(getCompanydetailsById(projectId))
                              } else {
                                dispatch(getProjectById(projectId))
                              }
                            }}
                            to={{
                              pathname:
                                resourceName === 'INVOICE' ||
                                resourceName === 'PREDICTION'
                                  ? `predictiondetails/${projectId}`
                                  : resourceName === 'RATE' ||
                                    resourceName === 'SPECIAL_RATE'
                                  ? `ratedetails/${projectId}`
                                  : resourceName === 'PROJECT'
                                  ? `projectdetails/${projectId}`
                                  : resourceName === 'COMPANY'
                                  ? `companydetails/${projectId}`
                                  : resourceName === 'CLIENT'
                                  ? 'companylist'
                                  : `userlist`,

                              state: {
                                activityRedirect: resourceName === 'INVOICE',
                                sidebarSelected: resourceName === 'USER' && '6',
                                headerSelectedItem:
                                  headerItemMapping[resourceName] ||
                                  headerItemLabels.PROJECT_DETAILS,
                              },
                            }}
                          >
                            {resourceName}
                          </Link>
                        </span>
                        {action === 'CREATED' && resourceName === 'CLIENT'
                          ? 'with the name'
                          : ' for the'}

                        <Link
                          className="project-name"
                          onClick={() => {
                            dispatch(getProjectById(projectId))
                            // dispatch(getPredictionDetailsById(projectId))
                          }}
                          to={{
                            pathname:
                              resourceName === 'USER'
                                ? 'userlist'
                                : resourceName === 'CLIENT'
                                ? 'companylist'
                                : resourceName === 'COMPANY'
                                ? `companydetails/${projectId}`
                                : `projectdetails/${projectId}`,
                            state: {
                              sidebarSelected: resourceName === 'USER' && '6',
                            },
                          }}
                        >
                          {projectName}
                        </Link>
                        {action === 'UPDATED' &&
                          resourceName !== 'SPECIAL_RATE' &&
                          resourceName !== 'RATE' &&
                          simpleActivityLogs?.length > 0 && (
                            <Button
                              className="link-btn"
                              type="link"
                              onClick={() =>
                                setexpanded((prev) => {
                                  const prevExpanded = [...prev]
                                  const newExpanded = prevExpanded.map(
                                    (item) => {
                                      if (item?.id === id) {
                                        return { ...item, value: !item?.value }
                                      } else {
                                        return item
                                      }
                                    }
                                  )
                                  return newExpanded
                                })
                              }
                              label={expanded?.map((item) => {
                                if (item?.id === id) {
                                  if (item?.value) {
                                    return 'See Less...'
                                  } else {
                                    return 'See More...'
                                  }
                                }
                              })}
                            />
                          )}
                      </p>
                    }
                  />
                  <div>{time}</div>
                </List.Item>

                {expanded.map((item) => {
                  if (item?.id === id) {
                    if (item?.value) {
                      return (
                        <div className="activity-discription">
                          <div className="activity-log">
                            {simpleActivityLogs?.map((item, index) => {
                              return (
                                <p>
                                  <strong>{item?.fieldName}</strong> :
                                  <span>{item?.oldValue}</span>
                                  -&gt;
                                  <span>{item?.newValue}</span>
                                </p>
                              )
                            })}
                          </div>
                        </div>
                      )
                    }
                  }
                })}
              </>
            )}
          />
        </Panel>
      </Collapse>
    </div>
  )
}

export default ListData
