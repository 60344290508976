import { createSlice } from '@reduxjs/toolkit'
import { resetAllReduxStates } from './resetAllReduxStatesAction'
const initialState = {
  companyNameOptions: [],
  projectNameOptions: [],
}
const accountNameEntitiesSlice = createSlice({
  name: 'accountNameEntities',
  initialState,
  reducers: {
    setAccountantNameEntitites: (state, { payload }) => {
      state.companyNameOptions = payload.company
      state.projectNameOptions = payload.project
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetAllReduxStates, () => initialState)
  },
})

export const { setAccountantNameEntitites } = accountNameEntitiesSlice.actions

export const accountNameEntitiesSelector = (state) => state.accountNameEntities

export default accountNameEntitiesSlice.reducer
