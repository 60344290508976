import { Col, Collapse, Form, Row } from 'antd'
import React, { useCallback, useEffect } from 'react'
import { PLACEHOLDER_MESSAGES } from '../../utils/constants/messages'
import AutoFieldComponent from '../AutoComplete/AutoComplete'
import SelectField from '../SelectField/SelectField'
import DateField from '../DateField/DateField'
import { format } from 'date-fns'
import {
  DATE_FORMATS,
  INVOICE_STATUS,
  financialTypes,
} from '../../utils/constants/constant'
import { staticOptionsSelector } from '../../redux/reducer/getOptionsSlice'
import {
  invoiceDateRule,
  invoiceDueDateRule,
  invoiceStatusRule,
} from '../../utils/constants/validationRules'
import { useDispatch, useSelector } from 'react-redux'
import {
  setTimeSheetsData,
  timeSheetsDataSelector,
} from '../../redux/reducer/timeSheetsDataSlice'
import moment from 'moment'

const { Panel } = Collapse

const InoviceDetailsHeader = ({
  projectData,
  timesheetNameFormat,
  isCurrentInvoiceAddedInQB,
}) => {
  const [form] = Form.useForm()
  const { financialTypeOptions } = useSelector(staticOptionsSelector)
  const { timeSheetsData } = useSelector(timeSheetsDataSelector)

  const currentInvoiceTimesheetData =
    timeSheetsData && timeSheetsData[timesheetNameFormat]

  const dispatch = useDispatch()
  const currentDate = new Date()

  useEffect(() => {
    form.setFieldsValue({
      startDate: moment(
        currentInvoiceTimesheetData?.invoiceGenerateDetails?.startDate,
        DATE_FORMATS.DD_MMM_YYYY
      ),
      endDate: moment(
        currentInvoiceTimesheetData?.invoiceGenerateDetails?.endDate,
        DATE_FORMATS.DD_MMM_YYYY
      ),
      invoiceDueDate: moment(
        currentInvoiceTimesheetData?.invoiceGenerateDetails?.invoiceDueDate,
        DATE_FORMATS.DD_MMM_YYYY
      ),
      currency:
        currentInvoiceTimesheetData?.invoiceGenerateDetails?.currencyCode,
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentInvoiceTimesheetData])

  /**
   *  This function updates invoice details in cached timesheet data
   */
  const editInvoiceDetails = useCallback(
    (updatedKey, newValue) => {
      const updatedInvoiceDetails = {
        ...currentInvoiceTimesheetData?.invoiceGenerateDetails,
        [updatedKey]: newValue,
      }
      const newTimesheetData = {
        ...timeSheetsData,
        [timesheetNameFormat]: {
          ...currentInvoiceTimesheetData,
          invoiceGenerateDetails: updatedInvoiceDetails,
        },
      }
      dispatch(setTimeSheetsData(newTimesheetData))
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [timeSheetsData, currentInvoiceTimesheetData]
  )

  return (
    <Collapse>
      <Panel header="Invoice Details" forceRender>
        <Form form={form}>
          <Row gutter={40}>
            <Col sm={4} md={4} lg={4}>
              <AutoFieldComponent
                label="Project Name"
                placeholder={PLACEHOLDER_MESSAGES.projectNameMessage}
                name="projectName"
                type="text"
                disabled
                existingValue={projectData?.name}
              />
            </Col>
            <Col sm={4} md={4} lg={4}>
              <SelectField
                label="Project Model"
                placeholder={PLACEHOLDER_MESSAGES.projectModelMessage}
                type="text"
                name="projectModel"
                disabled
                existingValue={projectData?.model}
              />
            </Col>
            <Col sm={4} md={4} lg={4}>
              <SelectField
                name="payModel"
                label="Pay Model"
                required
                disabled
                existingValue={projectData?.payModel}
                placeholder={PLACEHOLDER_MESSAGES?.payModelMessage}
                type="text"
              />
            </Col>
            <Col sm={4} md={4} lg={4}>
              <SelectField
                name="financialType"
                label="Financial Type"
                placeholder={PLACEHOLDER_MESSAGES.financialTypeMessage}
                options={financialTypeOptions}
                type="text"
                existingValue={
                  currentInvoiceTimesheetData?.invoiceGenerateDetails
                    ?.financialType || financialTypes?.REVENUE
                }
                disabled={isCurrentInvoiceAddedInQB}
                onChange={(value) => {
                  editInvoiceDetails('financialType', value)
                }}
              />
            </Col>
            <>
              <Col sm={4} md={4} lg={4}>
                <SelectField
                  name="invoiceStatus"
                  label="Invoice Status"
                  placeholder={PLACEHOLDER_MESSAGES.invoiceStatusMessage}
                  type="text"
                  options={['Deposit Settlement', 'Pending']}
                  existingValue={
                    currentInvoiceTimesheetData?.invoiceGenerateDetails
                      ?.invoiceStatus || INVOICE_STATUS?.PENDING
                  }
                  required
                  disabled={isCurrentInvoiceAddedInQB}
                  rules={invoiceStatusRule}
                  onChange={(value) => {
                    editInvoiceDetails('invoiceStatus', value)
                  }}
                />
              </Col>
              <Col sm={4} md={4} lg={4}>
                <DateField
                  name="invoiceDate"
                  label="Invoice Date"
                  placeholder={PLACEHOLDER_MESSAGES.invoiceDateMessage}
                  existingValue={
                    currentInvoiceTimesheetData?.invoiceGenerateDetails
                      ?.invoiceDate ||
                    format(currentDate, DATE_FORMATS.dd_MMM_yyyy)
                  }
                  type="text"
                  required
                  disabled={isCurrentInvoiceAddedInQB}
                  rules={invoiceDateRule}
                  onDateChange={(_, dateString) => {
                    editInvoiceDetails('invoiceDate', dateString)
                  }}
                />
              </Col>
              <Col sm={4} md={4} lg={4}>
                <DateField
                  name="invoiceDueDate"
                  label="Due Date"
                  placeholder={PLACEHOLDER_MESSAGES.invoiceDueDateMessage}
                  existingValue={
                    currentInvoiceTimesheetData?.invoiceGenerateDetails
                      ?.invoiceDueDate
                  }
                  type="text"
                  required
                  rules={invoiceDueDateRule}
                  onDateChange={(_, dateString) => {
                    editInvoiceDetails('invoiceDueDate', dateString)
                  }}
                />
              </Col>
            </>
            <>
              <Col sm={4} md={4} lg={4}>
                <DateField
                  name="startDate"
                  label="Start Date"
                  placeholder={PLACEHOLDER_MESSAGES.startDateMessage}
                  type="text"
                  existingValue={
                    currentInvoiceTimesheetData?.invoiceGenerateDetails
                      ?.startDate
                  }
                  required
                  onDateChange={(_, dateString) => {
                    editInvoiceDetails('startDate', dateString)
                  }}
                  suffixIcon={null}
                />
              </Col>
              <Col sm={4} md={4} lg={4}>
                <DateField
                  name="endDate"
                  label="End Date"
                  placeholder={PLACEHOLDER_MESSAGES.endDateMessage}
                  type="text"
                  suffixIcon={null}
                  existingValue={
                    currentInvoiceTimesheetData?.invoiceGenerateDetails?.endDate
                  }
                  onDateChange={(_, dateString) => {
                    editInvoiceDetails('endDate', dateString)
                  }}
                  required
                />
              </Col>
            </>

            {currentInvoiceTimesheetData?.invoiceGenerateDetails?.taxDetails
              ?.taxable && (
              <>
                {currentInvoiceTimesheetData?.provider !== 'ZOHO_BOOKS' && (
                  <Col md={4}>
                    <SelectField
                      name="globalTaxSetting"
                      label="Amount are: "
                      placeholder={PLACEHOLDER_MESSAGES.taxApplicabilityMessage}
                      disabled
                      existingValue={
                        currentInvoiceTimesheetData?.invoiceGenerateDetails
                          ?.taxDetails?.globalTaxSetting
                      }
                    />
                  </Col>
                )}
                {currentInvoiceTimesheetData?.invoiceGenerateDetails?.taxDetails
                  ?.globalTaxSetting !== 'Not Applicable' && (
                  <Col md={4}>
                    <SelectField
                      name="taxCodeId"
                      label="Tax Rate"
                      showSearch={true}
                      disabled
                      existingValue={
                        currentInvoiceTimesheetData?.invoiceGenerateDetails
                          ?.taxDetails?.taxName
                      }
                    />
                  </Col>
                )}
              </>
            )}

            <Col md={4}>
              <SelectField
                name="currency"
                label="Currency"
                disabled
                placeholder={PLACEHOLDER_MESSAGES.currencyMessage}
                existingValue={
                  currentInvoiceTimesheetData?.invoiceGenerateDetails?.currency
                }
              />
            </Col>
          </Row>
        </Form>
      </Panel>
    </Collapse>
  )
}

export default InoviceDetailsHeader
