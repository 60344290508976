import React from 'react'
import { Popconfirm } from 'antd'
import './PopupConfirm.scss'

const PopupConfirm = ({
  onConfirm,
  cancelText,
  onCancel,
  okText,
  title,
  children,
  disabled,
}) => {
  return (
    <div className="popup-wrapper">
      <Popconfirm
        placement="top"
        title={title}
        onConfirm={onConfirm}
        okText={okText}
        onCancel={onCancel}
        cancelText={cancelText}
        disabled={disabled}
      >
        {children}
      </Popconfirm>
    </div>
  )
}

export default PopupConfirm
