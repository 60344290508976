import { CloseCircleOutlined } from '@ant-design/icons'
import { Collapse, Form } from 'antd'
import moment from 'moment'
import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  AutoFieldComponent,
  Button,
  DateField,
  DrawerModule,
  PopupConfirm,
  SelectField,
} from '../../components'
import { staticOptionsSelector } from '../../redux/reducer/getOptionsSlice'
import { setInvoiceDetails } from '../../redux/reducer/invoiceDetailsSlice'
import { AuthContext } from '../../store/auth'
import {
  getFilteredInvoices,
  getInvoiceDetails,
} from '../../utils/api/predictionAndInvoiceServices'
import {
  suggestionsOptionsApiService,
} from '../../utils/api/staticOptionsServices'
import {
  DATE_FORMATS,
  DEFAULT_PAGE_NO,
  DEFAULT_PAGE_SIZE,
  NO,
  STATUS,
  TOAST,
  YES,
} from '../../utils/constants/constant'
import {
  PLACEHOLDER_MESSAGES,
  TOASTIFY_MESSAGES,
} from '../../utils/constants/messages'
import {
  filteredOptions,
  renderFeaturedElement,
} from '../../utils/helperFunctions/helperFunctions'
import './InvoiceDetailsModule.scss'
import { toastNotify } from '../../utils/helperFunctions/toastNotify'

const InvoiceDetailsModule = forwardRef(
  ({ setAppliedFilterOptions, open, onClose, searchFormInstance }, _ref) => {
    const [form] = Form.useForm()
    const [autoProjectName, setAutoProjectName] = useState([])
    const [autoInvoiceNumber, setAutoInvoiceNumber] = useState([])
    const [formDisabled, setFormDisabled] = useState(false)
    const [isPopupVisible, setIsPopupVisible] = useState(false)
    const [isDisableCancel, setIsDisableCancel] = useState(true)
    const [isDisableApply, setIsDisableApply] = useState(true)
    const [fieldsValue, setFieldValue] = useState({ invoiceStatus: 'Pending' })
    const { userRole } = useContext(AuthContext)
    const dispatch = useDispatch()
    const {
      invoiceStatusOptions,
      financialTypeOptions,
      invoiceCycleOptions,
      csmOptions,
      accountantOptions,
      projectNameOptions,
      invoiceNumberOptions,
    } = useSelector(staticOptionsSelector)

    useImperativeHandle(_ref, () => ({
      getFilterFormRef: () => {
        return {
          form,
          setFieldValue,
          setFormDisabled,
          setIsPopupVisible,
          setIsDisableCancel,
          setIsDisableApply,
        }
      },
    }))
    const { Panel } = Collapse

    useEffect(() => {
      form.setFieldValue('invoiceStatus', fieldsValue?.invoiceStatus)

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fieldsValue])

    // Functions is used for format date fields & set key-value pair as array
    const getFormatedFilterValues = (filterValues) => {
      let isFieldSelected = false
      let values = []

      Object.keys(filterValues).forEach((key) => {
        if (filterValues[key]) {
          isFieldSelected = true
          if (
            key === 'invoiceDate' ||
            key === 'startDate' ||
            key === 'endDate' ||
            key === 'receivedDate' ||
            key === 'receivedMonth'
          ) {
            values.push({
              [key]: moment(filterValues[key]).format(
                key === 'receivedMonth'
                  ? DATE_FORMATS.MMM_YYYY
                  : DATE_FORMATS.DD_MMM_YYYY
              ),
            })
          } else if (key === 'projectName') {
            values.push({ [key]: encodeURIComponent(filterValues[key]) })
          } else {
            values.push({ [key]: filterValues[key] })
          }
        }
      })
      return {
        values,
        isFieldSelected,
      }
    }
    const onFinish = async (filterValues) => {
      setFormDisabled(true)
      searchFormInstance.setFieldValue('searchInvoice', null)
      const { values, isFieldSelected } = getFormatedFilterValues(filterValues)

      if (isFieldSelected) {
        setAppliedFilterOptions(values)
        const response = await getFilteredInvoices(
          values,
          DEFAULT_PAGE_NO,
          DEFAULT_PAGE_SIZE
        )
        if (response?.status === STATUS.ok) {
          setFormDisabled(false)
          dispatch(setInvoiceDetails(response?.data?.data))
          onClose()
        } else {
          toastNotify(TOAST.error, TOASTIFY_MESSAGES.failedToApplyFilterMessage)
          setFormDisabled(false)
        }
      } else {
        toastNotify(TOAST.error, TOASTIFY_MESSAGES.atLeastOneFilterMessage)
        setFormDisabled(false)
        setAppliedFilterOptions([])
        const response = await getInvoiceDetails(
          DEFAULT_PAGE_NO,
          DEFAULT_PAGE_SIZE
        )
        if (response?.status === STATUS.ok) {
          dispatch(setInvoiceDetails(response?.data?.data))
          onClose()
        }
      }
    }
    const onFinishFailed = () => {
      toastNotify(TOAST.error, TOASTIFY_MESSAGES.failedToApplyFilterMessage)
    }

    const clearFilterHandler = (field) => {
      form.setFieldsValue({ [field]: '' })
      setFieldValue((data) => {
        return { ...data, [field]: '' }
      })
    }
    const fieldChangeHandler = (field, value) => {
      setFieldValue((data) => {
        return { ...data, [field]: value }
      })
    }

    return (
      <DrawerModule
        open={open}
        onClose={onClose}
        closable={!isPopupVisible}
        title="Apply Filter"
        size={'500px'}
        className="filter-drawer"
      >
        <Form
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          disabled={formDisabled}
          onFieldsChange={() => {
            setIsDisableCancel(false)
            setIsDisableApply(false)
          }}
          form={form}
          className="filter-content"
        >
          <Collapse accordion expandIconPosition="end">
            <Panel
              header="Project Name"
              key="1"
              extra={
                fieldsValue?.projectName && (
                  <Button
                    onClick={(e) => {
                      e.stopPropagation()
                      clearFilterHandler('projectName')
                    }}
                    icon={<CloseCircleOutlined />}
                  />
                )
              }
            >
              <AutoFieldComponent
                name="projectName"
                options={filteredOptions('label', autoProjectName)}
                placeholder={PLACEHOLDER_MESSAGES.projectNameMessage}
                value={fieldsValue?.projectName}
                type="text"
                onFocus={() => setAutoProjectName(projectNameOptions)}
                onChange={async (val) => {
                  fieldChangeHandler('projectName', val)
                  const projectNames = await suggestionsOptionsApiService(
                    'projectName',
                    encodeURIComponent(val) || ' '
                  )
                  setAutoProjectName(projectNames)
                }}
              />
            </Panel>
            <Panel
              header="Financial Type"
              key="11"
              extra={
                fieldsValue?.financialType && (
                  <Button
                    onClick={(e) => {
                      e.stopPropagation()
                      clearFilterHandler('financialType')
                    }}
                    icon={<CloseCircleOutlined />}
                  />
                )
              }
            >
              <SelectField
                name="financialType"
                value={fieldsValue?.financialType}
                onChange={(value) => fieldChangeHandler('financialType', value)}
                options={financialTypeOptions}
                placeholder={PLACEHOLDER_MESSAGES.financialTypeMessage}
                type="text"
              />
            </Panel>
            {userRole !== 'ROLE_ACCOUNTANT' && (
              <Panel
                header="Accountant"
                key="3"
                extra={
                  fieldsValue?.accountant && (
                    <Button
                      onClick={(e) => {
                        e.stopPropagation()
                        clearFilterHandler('accountant')
                      }}
                      icon={<CloseCircleOutlined />}
                    />
                  )
                }
              >
                <SelectField
                  name="accountant"
                  options={renderFeaturedElement(accountantOptions)}
                  isFeatured={true}
                  value={fieldsValue?.accountant}
                  onChange={(value) => fieldChangeHandler('accountant', value)}
                  placeholder={PLACEHOLDER_MESSAGES.accountantMessage}
                  type="text"
                />
              </Panel>
            )}
            {userRole !== 'ROLE_CSM' && (
              <Panel
                header="CSM"
                key="2"
                extra={
                  fieldsValue?.csm && (
                    <Button
                      onClick={(e) => {
                        e.stopPropagation()
                        clearFilterHandler('csm')
                      }}
                      icon={<CloseCircleOutlined />}
                    />
                  )
                }
              >
                <SelectField
                  name="csm"
                  options={renderFeaturedElement(csmOptions)}
                  value={fieldsValue?.csm}
                  onChange={(value) => {
                    fieldChangeHandler('csm', value)
                  }}
                  placeholder={PLACEHOLDER_MESSAGES.primaryCSMMessage}
                  type="text"
                  isFeatured={true}
                />
              </Panel>
            )}

            <Panel
              header="Invoice Number"
              key="4"
              extra={
                fieldsValue?.invoiceNumber && (
                  <Button
                    onClick={(e) => {
                      e.stopPropagation()
                      clearFilterHandler('invoiceNumber')
                    }}
                    icon={<CloseCircleOutlined />}
                  />
                )
              }
            >
              <AutoFieldComponent
                name="invoiceNumber"
                options={filteredOptions('label', autoInvoiceNumber)}
                value={fieldsValue?.invoiceNumber}
                type="text"
                onFocus={() => setAutoInvoiceNumber(invoiceNumberOptions)}
                onChange={async (val) => {
                  fieldChangeHandler('invoiceNumber', val)
                  const invoiceNumbers = await suggestionsOptionsApiService(
                    'invoiceNumber',
                    val
                  )
                  setAutoInvoiceNumber(invoiceNumbers)
                }}
              />
            </Panel>
            <Panel
              header="Invoice Status"
              key="10"
              extra={
                fieldsValue?.invoiceStatus && (
                  <Button
                    onClick={(e) => {
                      e.stopPropagation()
                      clearFilterHandler('invoiceStatus')
                    }}
                    icon={<CloseCircleOutlined />}
                  />
                )
              }
            >
              <SelectField
                name="invoiceStatus"
                value={fieldsValue?.invoiceStatus}
                onChange={(value) => fieldChangeHandler('invoiceStatus', value)}
                options={invoiceStatusOptions}
                placeholder={PLACEHOLDER_MESSAGES.invoiceStatusMessage}
                type="text"
              />
            </Panel>

            <Panel
              header="Invoice Cycle"
              key="12"
              extra={
                fieldsValue?.invoiceCycle && (
                  <Button
                    onClick={(e) => {
                      e.stopPropagation()
                      clearFilterHandler('invoiceCycle')
                    }}
                    icon={<CloseCircleOutlined />}
                  />
                )
              }
            >
              <SelectField
                name="invoiceCycle"
                options={invoiceCycleOptions}
                value={fieldsValue?.invoiceCycle}
                onChange={(value) => fieldChangeHandler('invoiceCycle', value)}
                placeholder={PLACEHOLDER_MESSAGES.invoiceCycleMessage}
                type="text"
              />
            </Panel>
            <Panel
              header="Invoice Date"
              key="5"
              extra={
                fieldsValue?.invoiceDate && (
                  <Button
                    onClick={(e) => {
                      e.stopPropagation()
                      clearFilterHandler('invoiceDate')
                    }}
                    icon={<CloseCircleOutlined />}
                  />
                )
              }
            >
              <DateField
                name="invoiceDate"
                type="text"
                placeholder={PLACEHOLDER_MESSAGES.invoiceDate}
                popupClassName={'date-picker-dropdown'}
                value={fieldsValue?.invoiceDate}
                onDateChange={(_, value) =>
                  fieldChangeHandler(
                    'invoiceDate',
                    moment(value).format(DATE_FORMATS.DD_MMM_YYYY)
                  )
                }
              />
            </Panel>
            <Panel
              header="Start Date"
              key="6"
              extra={
                fieldsValue?.startDate && (
                  <Button
                    onClick={(e) => {
                      e.stopPropagation()
                      clearFilterHandler('startDate')
                    }}
                    icon={<CloseCircleOutlined />}
                  />
                )
              }
            >
              <DateField
                name="startDate"
                type="text"
                placeholder={PLACEHOLDER_MESSAGES.startDateMessage}
                value={fieldsValue?.startDate}
                onDateChange={(_, value) =>
                  fieldChangeHandler(
                    'startDate',
                    moment(value).format(DATE_FORMATS.DD_MMM_YYYY)
                  )
                }
                popupClassName={'date-picker-dropdown'}
              />
            </Panel>
            <Panel
              header="End Date"
              key="7"
              extra={
                fieldsValue?.endDate && (
                  <Button
                    onClick={(e) => {
                      e.stopPropagation()
                      clearFilterHandler('endDate')
                    }}
                    icon={<CloseCircleOutlined />}
                  />
                )
              }
            >
              <DateField
                name="endDate"
                placeholder="End Date"
                value={fieldsValue?.endDate}
                onDateChange={(_, value) =>
                  fieldChangeHandler(
                    'endDate',
                    moment(value).format(DATE_FORMATS.DD_MMM_YYYY)
                  )
                }
                type="text"
                popupClassName={'date-picker-dropdown'}
              />
            </Panel>
            <Panel
              header="Received Date"
              key="8"
              extra={
                fieldsValue?.receivedDate && (
                  <Button
                    onClick={(e) => {
                      e.stopPropagation()
                      clearFilterHandler('receivedDate')
                    }}
                    icon={<CloseCircleOutlined />}
                  />
                )
              }
            >
              <DateField
                name="receivedDate"
                placeholder={PLACEHOLDER_MESSAGES.receivedDateMessage}
                value={fieldsValue?.receivedDate}
                onDateChange={(_, value) =>
                  fieldChangeHandler(
                    'receivedDate',
                    moment(value).format(DATE_FORMATS.DD_MMM_YYYY)
                  )
                }
                type="text"
                popupClassName={'date-picker-dropdown'}
              />
            </Panel>
          </Collapse>
          <div className="drawer-button btn-group">
            <PopupConfirm
              onConfirm={async () => {
                form.resetFields()
                setIsPopupVisible(false)
                setIsDisableCancel(true)
                setIsDisableApply(true)
                setFieldValue({})
                const response = await getInvoiceDetails(
                  DEFAULT_PAGE_NO,
                  DEFAULT_PAGE_SIZE
                )
                response?.data?.data &&
                  dispatch(setInvoiceDetails(response?.data?.data))
              }}
              onCancel={() => setIsPopupVisible(false)}
              cancelText={NO}
              okText={YES}
              title={'Do you want to discard the changes?'}
            >
              <Button
                danger
                label="Cancel"
                disabled={isDisableCancel}
                onClick={() => setIsPopupVisible(!isPopupVisible)}
              />
            </PopupConfirm>
            <Button
              type="primary"
              disabled={isDisableApply}
              label="Apply"
              htmlType="submit"
            />
          </div>
        </Form>
      </DrawerModule>
    )
  }
)
export default InvoiceDetailsModule
