import { createSlice } from '@reduxjs/toolkit'
import { resetAllReduxStates } from './resetAllReduxStatesAction'

const initialState = {
  timeSheetsData: {},
  verificationData: {},
}
const timeSheetsDataSlice = createSlice({
  name: 'invoiceTimesheetsData',
  initialState,
  reducers: {
    setTimeSheetsData: (state, { payload }) => {
      state.timeSheetsData = payload
    },
    setVerificationData: (state, { payload }) => {
      state.verificationData = payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetAllReduxStates, () => initialState)
  },
})

export const { setTimeSheetsData, setVerificationData } =
  timeSheetsDataSlice.actions

export const timeSheetsDataSelector = (state) => state.invoiceTimesheetsData

export default timeSheetsDataSlice.reducer
