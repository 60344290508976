import React, { lazy } from 'react'
import { Col, Row } from 'antd'

import './ProjectDetailsPanel.scss'
import { SelectField, AutoFieldComponent } from '../../components'
import { PLACEHOLDER_MESSAGES } from '../../utils/constants/messages'
import {
  zohoProjectIdRule,
  projectNameRule,
  projectModelRule,
  retainerRule,
} from '../../utils/constants/validationRules'
import { afteFieldRetainerOptions, tmHoursField } from '../../utils/constants/constant'
const AddonAfterField = lazy(() => import('../AddonAfterField/AddonAfterField'))

const ProjectDetailsPanel = ({
  projectData,
  form,
  projectModelOptions,
  isShowFields,
}) => {
  return (
    <Row gutter={40}>
      <Col lg={12} md={12} sm={12}>
        <AutoFieldComponent
          name="zohoProjectId"
          label="Zoho Project ID"
          placeholder={PLACEHOLDER_MESSAGES.zohoProjectIdMessage}
          existingValue={projectData?.zohoProjectId}
          type="text"
          required
          value={projectData?.zohoProjectId}
          disabled={true}
          rules={zohoProjectIdRule}
        />
      </Col>
      <Col lg={12} md={12} sm={12}>
        <AutoFieldComponent
          name="projectName"
          label="Project Name"
          placeholder={PLACEHOLDER_MESSAGES.projectNameMessage}
          existingValue={projectData?.name}
          type="text"
          disabled={true}
          required
          rules={projectNameRule}
        />
      </Col>
      <Col lg={12} md={12} sm={12}>
        <SelectField
          name="projectModel"
          label="Select Project Model"
          options={projectModelOptions}
          existingValue={projectData?.model}
          placeholder={PLACEHOLDER_MESSAGES.projectModelMessage}
          value={projectData?.model}
          required
          type="text"
          rules={projectModelRule}
        />
      </Col>
      {isShowFields.retainer && (
        <>
          <Col lg={12} md={12} sm={12}>
            <AddonAfterField
              name="retainer"
              label="Retainer"
              required={true}
              afteFieldOptions={afteFieldRetainerOptions}
              defaultAfterFieldValue={
                projectData?.tmHours ? tmHoursField.hours : tmHoursField.amount
              }
              initialValue={projectData?.retainer}
              rules={retainerRule}
              onChange={(value) => {
                form.setFieldValue('tmHours', value)
              }}
              afterFieldName={'tmHours'}
            />
          </Col>
        </>
      )}
      {/* 
          <Col lg={12} md={12} sm={12}>
            <InputField
              name="retainerActiveBalance"
              label="Active Balance"
              placeholder={PLACEHOLDER_MESSAGES.activeBalanceMessage}
              existingValue={projectData?.retainerActiveBalance}
              type="text"
              rules={activeBalanceRule}
            />
          </Col>
          <Col lg={12} md={12} sm={12}>
            <DateField
              name="retainerActiveDate"
              label="Active Balance Date"
              type="text"
              placeholder={PLACEHOLDER_MESSAGES.activeBalanceDateMessage}
              existingValue={projectData?.retainerActiveDate}
              rules={activeBalanceDateRule(
                form.getFieldValue('retainerActiveBalance')
              )}
            />
          </Col>
        </>
      )} */}
    </Row>
  )
}

export default ProjectDetailsPanel
