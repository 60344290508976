import { createSlice } from '@reduxjs/toolkit'
import { resetAllReduxStates } from './resetAllReduxStatesAction'
const initialState = {
  selectedUserName: null,
  selectedUserRole: null,
}
const userAccessManagementSlice = createSlice({
  name: 'userAccessManagement',
  initialState,
  reducers: {
    setSelectedUserDetails: (state, { payload }) => {
      state.selectedUserName = payload.selectedUserName
      state.selectedUserRole = payload.selectedUserRole
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetAllReduxStates, () => initialState)
  },
})
export const { setSelectedUserDetails } = userAccessManagementSlice.actions
export const userAccessManagementSelector = (state) =>
  state.userAccessManagement
export default userAccessManagementSlice.reducer
