import { createSlice } from '@reduxjs/toolkit'
import { resetAllReduxStates } from './resetAllReduxStatesAction'
const initialState = {
  loading: false,
  hasErrors: false,
  companyAndClientDetails: null,
}
const getProjectCompanyAndClientByIdSlice = createSlice({
  name: 'companyAndClientsById',
  initialState,
  reducers: {
    getProjectCompanyAndClientDetails: (state) => {
      state.loading = true
    },
    getProjectCompanyAndClientDetailsSuccess: (state, { payload }) => {
      state.companyAndClientDetails = payload
      state.loading = false
      state.hasErrors = false
    },
    getProjectCompanyAndClientDetailsFailure: (state) => {
      state.loading = false
      state.hasErrors = true
      state.companyAndClientDetails = null
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetAllReduxStates, () => initialState)
  },
})

export const {
  getProjectCompanyAndClientDetails,
  getProjectCompanyAndClientDetailsSuccess,
  getProjectCompanyAndClientDetailsFailure,
} = getProjectCompanyAndClientByIdSlice.actions

export const companyAndClientsByIdSelector = (state) =>
  state?.projectCompanyAndClients
export default getProjectCompanyAndClientByIdSlice.reducer
