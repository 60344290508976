import { lazy } from 'react'

export const routes = {
  loginpage: {
    path: '/',
    component: lazy(() => import('../pages/LoginPage/LoginPage')),
    exact: true,
    secure: false,
    noHeader: true,
    noSidebar: true,
  },
  forgotpassword: {
    path: '/forgotpassword',
    component: lazy(() => import('../pages/ForgotPassword/ForgotPassword')),
    exact: true,
    secure: false,
    noHeader: true,
    noSidebar: true,
  },
  setpassword: {
    path: '/setpassword',
    component: lazy(() => import('../pages/SetPassword/SetPassword')),
    exact: true,
    secure: false,
    noHeader: true,
    noSidebar: true,
  },
  resetpassword: {
    path: '/resetpassword',
    component: lazy(() => import('../pages/SetPassword/SetPassword')),
    exact: true,
    secure: false,
    noHeader: true,
    noSidebar: true,
  },

  projectlist: {
    path: '/projectlist',
    component: lazy(() => import('../pages/ProjectList/ProjectList')),
    secure: true,
    exact: true,
  },
  projectdetails: {
    path: `/projectdetails/:id`,
    component: lazy(() => import('../pages/ProjectDetails/ProjectDetails')),
    secure: true,
    exact: true,
  },
  ratedetails: {
    path: '/ratedetails/:id',
    component: lazy(() => import('../pages/RateDetails/RateDetails')),
    secure: true,
    exact: true,
  },
  predictiondetails: {
    path: '/predictiondetails/:id',
    component: lazy(() =>
      import('../pages/PredictionDetails/PredictionDetails')
    ),
    secure: true,
    exact: true,
  },
  revenueinvoices: {
    path: '/revenueinvoices',
    component: lazy(() => import('../pages/RevenueInvoice/RevenueInvoice')),
    secure: true,
    exact: true,
  },
  revenuedispute: {
    path: '/revenuedispute',
    component: lazy(() => import('../pages/RevenueDispute/RevenueDispute')),
    secure: true,
    exact: true,
  },
  revenueprediction: {
    path: '/revenueprediction',
    component: lazy(() =>
      import('../pages/RevenuePrediction/RevenuePrediction')
    ),
    secure: true,
    exact: true,
  },
  revenuelist: {
    path: '/revenuelist',
    component: lazy(() => import('../pages/RevenueList/RevenueList')),
    secure: true,
    exact: true,
  },
  userlist: {
    path: '/userlist',
    component: lazy(() => import('../pages/UserList/UserList')),
    secure: true,
    exact: true,
  },
  useractivity: {
    path: '/useractivity',
    component: lazy(() => import('../pages/UserActivity/UserActivity')),
    secure: true,
    exact: true,
  },
  invoiceDashboard: {
    path: '/invoiceDashboard',
    component: lazy(() => import('../pages/InvoiceDashboard/InvoiceDashboard')),
    secure: true,
    exact: true,
  },
  rateMaster: {
    path: '/rateMaster',
    component: lazy(() => import('../pages/RateMaster/RateMaster')),
    secure: true,
    exact: true,
  },
  existinginvoice: {
    path: '/existinginvoice',
    component: lazy(() => import('../pages/ExistingInvoice/ExistingInvoice')),
    secure: true,
    exact: true,
    noHeader: true,
    noSidebar: true,
  },
  existingprediction: {
    path: '/existingprediction',
    component: lazy(() =>
      import('../pages/ExistingPrediction/ExistingPrediction')
    ),
    secure: true,
    exact: true,
    noHeader: true,
    noSidebar: true,
  },
  invoicedetails: {
    path: '/invoicedetails',
    component: lazy(() => import('../pages/InvoiceDetails/InvoiceDetails')),
    secure: true,
    exact: true,
  },
  settings: {
    path: '/settings',
    component: lazy(() => import('../pages/Setting/Setting')),
    secure: true,
    exact: true,
  },
  clientdetails: {
    path: '/clientdetails/:id',
    component: lazy(() => import('../pages/ClientDetails/ClientDetails')),
    secure: true,
    exact: true,
  },
  companylist: {
    path: '/companylist',
    component: lazy(() => import('../pages/CompanyList/CompanyList')),
    secure: true,
    exact: true,
  },
  companydetails: {
    path: `/companydetails/:id`,
    component: lazy(() => import('../pages/CompanyDetails/CompanyDetails')),
    secure: true,
    exact: true,
  },
  assignedprojects: {
    path: `/assignedprojects/:fetchParam`,
    component: lazy(() => import('../pages/AssignedProjects/AssignedProjects')),
    secure: true,
    exact: true,
  },
  techStacks: {
    path: `/techstack`,
    component: lazy(() => import('../pages/TechStack/TechStack')),
    secure: true,
    exact: true,
  },
  todolist: {
    path: `/todolist`,
    component: lazy(() => import('../pages/TodoList/TodoList')),
    secure: true,
    exact: true,
  },
  leaveBalance: {
    path: `/developers/:id`,
    component: lazy(() =>
      import('../pages/ProjectDevelopers/ProjectDevelopers')
    ),
    secure: true,
    exact: true,
  },
  jobScheduler: {
    path: '/settings/jobScheduler',
    component: lazy(() => import('../pages/JobScheduler/JobScheduler')),
    secure: true,
    exact: true,
  },
  serverLogs: {
    path: '/settings/serverLogs',
    component: lazy(() => import('../pages/ServerLogs/ServerLogs')),
    secure: true,
    exact: true,
  },
  projectNotes: {
    path: '/projectnotes/:id',
    component: lazy(() => import('../pages/ProjectNotes/ProjectNotes')),
    secure: true,
    exact: true,
  },
  salesPersons: {
    path: '/salesPersons',
    component: lazy(() => import('../pages/SalesPersons/SalesPersons')),
    secure: true,
    exact: true,
  },
  incentive: {
    path: '/salesPersons/incentive/:id',
    component: lazy(() => import('../pages/IncentiveDetails/IncentiveDetails')),
    secure: true,
    exact: true,
  },
  holidays: {
    path: '/settings/holidays',
    component: lazy(() => import('../pages/Holidays/Holidays')),
    secure: true,
    exact: true,
  },
  identityProvider: {
    path: '/settings/identityProvider',
    component: lazy(() => import('../pages/IdentityProvider/IdentityProvider')),
    secure: true,
    exact: true,
  },
}

export const renderRoutes = Object.entries(routes)
