import React from 'react'

function SolidCircle({ radius, fillColor }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      strokeWidth="1.5"
      viewBox="0 0 24 24"
    >
      <circle cx="12" cy="12" r={radius || '10'} stroke={fillColor || 'black'} strokeWidth="1" fill={fillColor || 'red'} />
    </svg>
  )
}

export default SolidCircle
