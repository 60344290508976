import { createSlice } from '@reduxjs/toolkit'
import { resetAllReduxStates } from './resetAllReduxStatesAction'
const initialState = {
  loading: false,
  hasErrors: false,
  companyDetails: {},
  persistedCompanyId: null,
}
const getCompanyByIdSlice = createSlice({
  name: 'companyById',
  initialState,
  reducers: {
    getCompanyDetails: (state) => {
      state.loading = true
    },
    getCompanyDetailsSuccess: (state, { payload }) => {
      state.companyDetails = payload.companyDetails
      state.clientDetailsList = payload.clientDetailsList
      state.persistedCompanyId = payload.companyId
      state.loading = false
      state.hasErrors = false
    },
    getCompanyDetailsFailure: (state) => {
      state.loading = false
      state.hasErrors = true
      state.companyDetails = {}
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetAllReduxStates, () => initialState)
  },
})

export const {
  getCompanyDetails,
  getCompanyDetailsSuccess,
  getCompanyDetailsFailure,
} = getCompanyByIdSlice.actions

export const companyDetailsSelector = (state) => state.companyById

export default getCompanyByIdSlice.reducer
