import axios from 'axios'
import { API_END_POINTS } from '../constants/apiEndPointConstants'
import { toastNotify } from '../helperFunctions/toastNotify'
import {
  DEFAULT_PAGE_NO,
  DEFAULT_PAGE_SIZE,
  TOAST,
} from '../constants/constant'

export const getRateDetailsByIdAndStartDate = async (projectId, startDate) => {
  const URL = `${API_END_POINTS.rates}?projectId=${projectId}&startDate=${
    startDate ? startDate : ''
  }`

  try {
    const response = await axios.get(URL)
    return response?.data?.data
  } catch (error) {
    console.log('error while getting special rate history by id', error)
    toastNotify(TOAST.error, error?.data?.message || 'Failed to get rate by id')
    return error
  }
}

export const addNewRates = async (data, projectId, startDate, endDate) => {
  const URL = `${
    API_END_POINTS.rates
  }?projectId=${projectId}&startDate=${startDate}&endDate=${
    endDate ? endDate : ''
  }`
  try {
    const response = await axios.post(URL, data)
    return response
  } catch (err) {
    console.log('error in updateRates API call', err)
    return err.response
  }
}
export const updateRates = async (
  data,
  projectId,
  oldStartDate,
  oldEndDate,
  newStartDate,
  newEndDate
) => {
  const URL = `${
    API_END_POINTS.rates
  }/v2?projectId=${projectId}&startDate=${oldStartDate}&endDate=${
    oldEndDate || ''
  }&newStartDate=${newStartDate || ''}&newEndDate=${newEndDate || ''}`

  try {
    const response = await axios.put(URL, data)
    return response
  } catch (err) {
    console.log('error in updateRates API call', err)
    return err.response
  }
}

export const updateSpecialRates = async (
  data,
  projectId,
  oldStartDate,
  oldEndDate,
  newStartDate,
  newEndDate
) => {
  const URL = `${
    API_END_POINTS.rates
  }/specialRates/v2?projectId=${projectId}&startDate=${oldStartDate}&endDate=${
    oldEndDate || ''
  }&newStartDate=${newStartDate || ''}&newEndDate=${newEndDate || ''}`
  try {
    const response = await axios.put(URL, data)
    return response
  } catch (err) {
    return err.response
  }
}

export const getRateDetailsRangeOptions = async (projectId) => {
  const URL = `${API_END_POINTS.rates}/dates?projectId=${projectId}`
  try {
    const response = await axios.get(URL)
    return response?.data?.data
  } catch (error) {
    console.log(
      'Getting error while fetching rate details range Options',
      error
    )
    return []
  }
}

export const addValuesForEmptyRates = async (
  data,
  projectId,
  invoiceEndDate
) => {
  const URL = `${API_END_POINTS.rates}/empty?projectId=${projectId}&invoiceEndDate=${invoiceEndDate}`
  try {
    const response = await axios.post(URL, data)
    return response
  } catch (err) {
    console.log('error in addValuesForEmptyRates API call', err)
    return err.response
  }
}

export const deleteRatesTemplateService = async (
  projectId,
  startDate,
  endDate
) => {
  const URL = `${
    API_END_POINTS.rates
  }/dates?projectId=${projectId}&startDate=${startDate}${
    endDate ? `&endDate=${endDate}` : ''
  }`

  try {
    const response = await axios.delete(URL)
    return response
  } catch (error) {
    return error?.response
  }
}

export const getRateListService = async (
  page,
  pageSize,
  filterSource,
  signal
) => {
  const URL = `${API_END_POINTS.rates}/rateCard?pageNo=${page}&pageSize=${pageSize}`
  try {
    const response = await axios.post(URL, filterSource, { signal })
    return response
  } catch (error) {
    return error?.response
  }
}

export const fetchSearchRateMasterService = async (
  projectName,
  signal,
  page = DEFAULT_PAGE_NO,
  pageSize = DEFAULT_PAGE_SIZE,
  startDate,
  endDate
) => {
  const URL = `${
    API_END_POINTS.rates
  }/rateCard/search?projectName=${encodeURIComponent(
    projectName
  )}&pageNo=${page}&pageSize=${pageSize}&startDate=${startDate}&endDate=${endDate}`

  try {
    const response = await axios.get(URL, { signal })
    return response
  } catch (err) {
    return err?.response
  }
}