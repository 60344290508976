import React from 'react'
import { Popover } from 'antd'

import './InvoiceTimesheet.scss'
import Button from '../Button/Button'
import { exportTimesheetService } from '../../utils/api/predictionAndInvoiceServices'
import { STATUS, TOAST } from '../../utils/constants/constant'
import { toastNotify } from '../../utils/helperFunctions/toastNotify'

const TimesheetActionPopover = ({
  setIsDownloadModalOpen,
  zohoProjectId,
  startDate,
  endDate,
  timesheetStartDate,
  timesheetEndDate,
  predictionId,
  sheetURL,
  setIsLoading,
  isLoading,
}) => {
  const exportTimesheetClickHandle = async () => {
    setIsLoading(true)
    if (sheetURL) {
      setIsLoading(false)
      window.open(sheetURL, '_blank')
    } else {
      const response = await exportTimesheetService(
        zohoProjectId,
        startDate,
        endDate,
        predictionId,
        timesheetStartDate,
        timesheetEndDate
      )
      if (response?.status === STATUS.ok) {
        setIsLoading(false)
        window.open(response?.data?.data, '_blank')
      } else {
        setIsLoading(false)
        response?.data?.message &&
          toastNotify(TOAST.error, response?.data?.message)
      }
    }
  }
  const content = (
    <div className="timesheet-action">
      <Button
        danger
        label="Download Timesheet"
        onClick={() => setIsDownloadModalOpen(true)}
      />
      <Button
        danger
        label={sheetURL ? 'View Google sheet' : 'Export Google sheet'}
        onClick={() => exportTimesheetClickHandle()}
        disabled={isLoading}
      />
    </div>
  )
  return (
    <Popover placement="bottomLeft" content={content} trigger="click">
      <Button danger label="Timesheet Action" />
    </Popover>
  )
}

export default TimesheetActionPopover
