import React from 'react'
import { Divider } from 'antd'
import './DividerLine.scss'

const DividerLine = ({ dividerText, textOrientation, ...rest }) => {
  return (
    <Divider orientation={textOrientation} {...rest}>
      <p className="divider-text">{dividerText}</p>
    </Divider>
  )
}

export default DividerLine
