import { Modal } from 'antd'
import React from 'react'

const DeleteLineItemModal = ({
  deleteRowHandler,
  selectedEmployeeData,
  setShowRemoveEmployeeModal,
  showRemoveEmployeeModal,
  setSelectedEmployeeData,
}) => {
  return (
    <Modal
      title="Do you want to remove this employee?"
      open={showRemoveEmployeeModal}
      onOk={() => deleteRowHandler(selectedEmployeeData)}
      onCancel={() => {
        setShowRemoveEmployeeModal(false)
        setSelectedEmployeeData()
      }}
      okText="Yes"
      cancelText="No"
    >
      <p>
        <span className="bold-text">Name:</span>
        {selectedEmployeeData?.isService
          ? selectedEmployeeData?.name
          : selectedEmployeeData?.employeeName}
      </p>
      {selectedEmployeeData?.isService ? (
        <p>
          <span className="bold-text">Amount:</span> {selectedEmployeeData?.rate}
        </p>
      ) : (
        <>
          <p>
            <span className="bold-text">Rate:</span>{' '}
            {selectedEmployeeData?.rate}
          </p>
          <p>
            <span className="bold-text">Billed Hours:</span>
            {selectedEmployeeData?.calculatedBillingHours}
          </p>
        </>
      )}
    </Modal>
  )
}
export default DeleteLineItemModal
