import { createSlice } from '@reduxjs/toolkit'
import { resetAllReduxStates } from './resetAllReduxStatesAction'
const initialState = {
  loading: false,
  hasErrors: false,
  salesPersonDetails: {},
  incentiveDetails: [],
}
const getIncentiveDetailsByIdSlice = createSlice({
  name: 'incentiveDetailsById',
  initialState,
  reducers: {
    getIncentiveDetails: (state) => {
      state.loading = true
    },
    getIncentiveDetailsSuccess: (state, { payload }) => {
      state.incentiveDetails = payload.incentiveDetails
      state.salesPersonDetails = payload.salesPersonDetails
      state.loading = false
      state.hasErrors = false
    },
    getIncentiveDetailsFailure: (state) => {
      state.loading = false
      state.hasErrors = true
      state.incentiveDetails = []
      state.salesPersonDetails = {}
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetAllReduxStates, () => initialState)
  },
})

export const {
  getIncentiveDetails,
  getIncentiveDetailsSuccess,
  getIncentiveDetailsFailure,
} = getIncentiveDetailsByIdSlice.actions

export const incentiveDetailsSelector = (state) => state.incentiveDetailsById

export default getIncentiveDetailsByIdSlice.reducer
