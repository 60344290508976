import { createSlice } from '@reduxjs/toolkit'
import { resetAllReduxStates } from './resetAllReduxStatesAction'
import { DEFAULT_COMPANY_FILTER } from '../../utils/constants/constant'
import { setAllAccountantState, setAllCsmState } from './setCsmAccountantAction'
const initialState = {
  companyList: {},
  ccBccList: {},
  appliedFilter: DEFAULT_COMPANY_FILTER,
}
const companyListSlice = createSlice({
  name: 'companyList',
  initialState,
  reducers: {
    setCompanyList: (state, { payload }) => {
      state.companyList = payload
    },
    setCcBccList: (state, { payload }) => {
      state.ccBccList = payload
    },
    setAppliedFilter: (state, { payload }) => {
      state.appliedFilter = payload
    },
    clearCache: (state) => {
      state.companyList = []
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(resetAllReduxStates, () => initialState)
      .addCase(setAllCsmState, (state, { payload }) => {
        state.appliedFilter = { ...DEFAULT_COMPANY_FILTER, csm: [payload.name] }
      })
      .addCase(setAllAccountantState, (state, { payload }) => {
        state.appliedFilter = {
          ...DEFAULT_COMPANY_FILTER,
          accountant: [payload.name],
        }
      })
  },
})

export const { setCompanyList, setCcBccList, clearCache, setAppliedFilter } =
  companyListSlice.actions

export const companyListSelector = (state) => state.companyList

export default companyListSlice.reducer
