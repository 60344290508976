import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DatePicker, Form } from 'antd'
import moment from 'moment/moment'

import './DateFilterDropdown.scss'
import { Button } from '../../components'
import { DATE_PICKER } from '../../utils/constants/constant'
import CheckBox from '../CheckBox/CheckBox'
import { revenueListSelector, setAppliedFilter } from '../../redux/reducer/revenueListSlice'

const DateFilterDropdown = ({
  setSelectedKeys,
  selectedKeys,
  confirm,
  clearFilters,
  dataIndex,
  dateFrmt,
  picker = DATE_PICKER.date,
  defaultFilteredValue,
  isRevenueTab,
  isNaOption
}) => {
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const isShowRangePicker = isRevenueTab && picker === DATE_PICKER.month
  const { appliedFilter } = useSelector(revenueListSelector)
  const [isNaSelected, setIsNaSelected] = useState(defaultFilteredValue?.includes(null))

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
   const newFilterValues = { ...appliedFilter }

   if (isNaSelected) {
     const dataIndexArray = newFilterValues?.[dataIndex]

     if (Array.isArray(dataIndexArray)) {
       // Add null to the end of the array
       newFilterValues[dataIndex] = [...dataIndexArray, null]
     } else {
       // If dataIndex does not exist or is not an array, create a new array with null
       newFilterValues[dataIndex] = [null]
     }
   } else {
     // If isNaSelected is false, remove null from the array (if it exists)
     if (Array.isArray(newFilterValues?.[dataIndex])) {
       newFilterValues[dataIndex] = newFilterValues[dataIndex].filter(
         (value) => value !== null
       )
     }
   }
    dispatch(setAppliedFilter(newFilterValues))
    confirm()
  }
  
  React.useEffect(() => {
    if (defaultFilteredValue?.length) {
      !isShowRangePicker
        ? defaultFilteredValue[0] && form.setFieldsValue({
            [dataIndex]: moment(defaultFilteredValue[0]), // Set the default value of the DatePicker using the prop
          })
        : form.setFieldsValue({
            [dataIndex]: [
              moment(defaultFilteredValue[0]),
              moment(defaultFilteredValue[1] || defaultFilteredValue[0]),
            ], // Set the default value of the DatePicker using the prop
          })
    }
  }, [defaultFilteredValue, dataIndex, form, isShowRangePicker])
  return (
    <div className="filter-popup">
      <Form form={form}>
        <Form.Item name={dataIndex}>
          {isShowRangePicker ? (
            <DatePicker.RangePicker
              format={dateFrmt}
              picker={picker}
              onChange={(dates) => {
                const months = dates?.map((date) => date?.format(dateFrmt))
                setSelectedKeys(months)
              }}
              getPopupContainer={(triggerNode) => {
                return triggerNode.parentElement
              }}
              allowClear={false}
            />
          ) : (
            <DatePicker
              format={dateFrmt}
              picker={picker}
              onChange={(e) => {
                setSelectedKeys([e.format(dateFrmt)])
              }}
              getPopupContainer={(triggerNode) => {
                return triggerNode.parentElement
              }}
              allowClear={false}
            />
          )}
        </Form.Item>
        <div className="filter-btn-group">
          <div>
            <Button
              type="link"
              onClick={() => {
                form.resetFields()
                setSelectedKeys([])
                clearFilters()
                setIsNaSelected(false)
                confirm()
              }}
              size="small"
              htmlType="submit"
              disabled={!selectedKeys.length}
              label="Reset"
            />
           { isNaOption && <CheckBox
              label="N/A"
              onChange={(e) => setIsNaSelected(e.target.checked)}
              checked={isNaSelected}
            />}
          </div>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            label="Ok"
            htmlType="submit"
            size="small"
          />
        </div>
      </Form>
    </div>
  )
}

export default DateFilterDropdown
