import React, { useCallback, useEffect, useState } from 'react'
import { Spin, Tooltip } from 'antd'
import { useDispatch, useSelector } from 'react-redux'

import './TimeSheetVerification.scss'
import DataTable from '../DataTable/DataTable'
import { ReactComponent as CorrectIcon } from '../../assets/icons/correct.svg'
import { ReactComponent as IncorrectIcon } from '../../assets/icons/incorrect.svg'
import { ReactComponent as Refresh } from '../../assets/icons/refresh-icon.svg'
import WeeksVerification from '../WeeksVerification/WeeksVerification'
import { generateVerificationWeeksService, getVerificationWeeksByPredId } from '../../utils/api/timesheetServices'
import {
  setVerificationData,
  timeSheetsDataSelector,
} from '../../redux/reducer/timeSheetsDataSlice'
import { toastNotify } from '../../utils/helperFunctions/toastNotify'
import { STATUS, TOAST } from '../../utils/constants/constant'
import { TOASTIFY_MESSAGES } from '../../utils/constants/messages'
import Button from '../Button/Button'

const TimeSheetVerification = ({
  predictionData,
  todoVerificationId,
  setShowGenerateInvoiceModal,
  isTMretainerPrepaidModel,
}) => {
  const { verificationData } = useSelector(timeSheetsDataSelector)
  const { projectId } = useSelector((state) => state?.projectId)
  const dispatch = useDispatch()
  const [showWeekVerification, setShowWeekVerificaton] = useState(
    !!todoVerificationId
  )
  const [isLoading, setIsLoading] = useState(false)
  const [selectedWeek, setSelectedWeek] = useState('1')
  const verificationNameFormat = `${projectId}_${predictionData?.startDate}_${
    predictionData?.endDate || 'noEndDate'
  }`

  const fetchVerificationData = useCallback(
    async () => {
      setIsLoading(true)
      const data = await getVerificationWeeksByPredId(
        predictionData?.predictionId ?? predictionData?.billingId
      )
      const newVerificationData = {
        ...verificationData,
        [verificationNameFormat]: {
          ...verificationData[verificationNameFormat],
          verificationTableData: data,
        },
      }
      dispatch(setVerificationData(newVerificationData))
      setIsLoading(false)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [predictionData]
  )

  const fetchGenerateVerificationData = useCallback(
    async () => {
      setIsLoading(true)
      const response = await generateVerificationWeeksService(
        predictionData?.predictionId ?? predictionData?.billingId
      )
      if (response?.status === STATUS.ok) {
        const newVerificationData = {
          ...verificationData,
          [verificationNameFormat]: {
            ...verificationData[verificationNameFormat],
            verificationTableData: response?.data?.data,
          },
        }
        dispatch(setVerificationData(newVerificationData))
      } else {
        response?.data?.message &&
          toastNotify(TOAST.error, response?.data?.message)
      }
      setIsLoading(false)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [predictionData]
  )

  useEffect(() => {
    if (
      !!predictionData &&
      !verificationData[verificationNameFormat]?.verificationTableData
    ) {
      fetchVerificationData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [predictionData])

  const verificationTableColumns = [
    {
      title: 'Week',
      dataIndex: 'week',
    },
    {
      title: 'Start Date',
      dataIndex: 'startDate',
    },
    {
      title: 'End Date',
      dataIndex: 'endDate',
    },
    {
      title: 'Is Verified',
      dataIndex: 'verified',
      render: (verified) => (verified ? <CorrectIcon /> : <IncorrectIcon />),
    },
  ]

  return (
    <>
      {!showWeekVerification && (
        <div>
          <Spin spinning={isLoading}>
            <DataTable
              title={() => (
                <div className="table-title">
                  <h3>
                    Verification details for {predictionData?.startDate} to{' '}
                    {predictionData?.endDate}
                  </h3>
                  <div>
                    <Button
                      label={'Generate'}
                      type="primary"
                      onClick={() => fetchGenerateVerificationData()}
                    />
                    <Tooltip
                      color="#ffffff"
                      overlayClassName="tooltip"
                      title="Refresh"
                    >
                      <Refresh onClick={fetchVerificationData} />
                    </Tooltip>
                  </div>
                </div>
              )}
              rowKey={(record) => record?.week || record?.index}
              className="verification-base-table"
              columns={verificationTableColumns}
              scroll={{ x: 1000, y: 460 }}
              dataSource={
                verificationData[verificationNameFormat]?.verificationTableData
              }
              onRow={(record) => {
                return Boolean(isTMretainerPrepaidModel && !record?.endDate)
                  ? {
                      onClick: () => {
                        toastNotify(
                          TOAST.error,
                          TOASTIFY_MESSAGES.shortDurationVerificationMessge
                        )
                      },
                    }
                  : {
                      onClick: () => {
                        const clickedWeek = record?.week
                        setShowWeekVerificaton(true)
                        setSelectedWeek(clickedWeek)
                      },
                    }
              }}
              pagination={false}
              disabled={true}
            />
          </Spin>
        </div>
      )}

      {showWeekVerification && (
        <WeeksVerification
          predictionData={predictionData}
          selectedWeek={selectedWeek}
          setShowWeekVerificaton={setShowWeekVerificaton}
          verificationNameFormat={verificationNameFormat}
          fetchVerificationData={fetchVerificationData}
          todoVerificationId={todoVerificationId}
          setShowGenerateInvoiceModal={setShowGenerateInvoiceModal}
        />
      )}
    </>
  )
}

export default TimeSheetVerification
