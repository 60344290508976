import React from 'react'
import { Checkbox } from 'antd'

const CheckBox = ({ label, className, onChange, defaultChecked, ...rest }) => (
  <Checkbox
    onChange={onChange}
    defaultChecked={defaultChecked}
    className={className}
  >
    {label}
  </Checkbox>
)

export default CheckBox
