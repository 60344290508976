import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react'
import { Dropdown, Form } from 'antd'
import './CustomizeFilterPopup.scss'
import Filter from '../Filter/Filter'
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg'
import SelectField from '../SelectField/SelectField'
import { activityFilter } from '../../utils/api/activityServices'
import { getActivityOptions } from '../../utils/api/staticOptionsServices'
import { PopupConfirm, Button } from '../../components'
import { useDispatch } from 'react-redux'
import {
  setFilteredActivities,
  setFilterObj,
  setLoadedPage,
} from '../../redux/reducer/userActivitiesSlice'
import { YES, NO, TOAST } from '../../utils/constants/constant'
import { toastNotify } from '../../utils/helperFunctions/toastNotify'
import { TOASTIFY_MESSAGES } from '../../utils/constants/messages'

const CustomizeFilterPopup = forwardRef(
  (
    {
      openFilter,
      setOpenFilter,
      setIsLoading,
      setClearFilter,
      setCurrentPage,
      setTotalPage,
    },
    _ref
  ) => {
    const [userOptions, setUserOptions] = useState([])
    const [projectOptions, setProjectOptions] = useState([])
    const [actionOptions, setActionOptions] = useState([])
    const [formDisabled, setFormDisabled] = useState(false)
    const [isDisableCancel, setIsDisableCancel] = useState(true)
    const [form] = Form.useForm()
    const dispatch = useDispatch()

    const clearFilterHandler = () => {
      form.resetFields()
      setOpenFilter(false)
      setClearFilter(true)
      dispatch(
        setFilterObj({
          project: '',
          user: '',
          action: '',
        })
      )
      setCurrentPage(1)
      dispatch(setLoadedPage(0))
      setIsDisableCancel(true)
    }
    const onFinish = async (values) => {
      setIsLoading(true)
      setFormDisabled(true)
      const { project, user, action } = values
      if (project || user || action) {
        setIsDisableCancel(false)
        dispatch(
          setFilterObj({
            project: project || '',
            user: user || '',
            action: action || '',
          })
        )
        const res = await activityFilter(user, project, action, 1)
        setIsLoading(false)
        if (res) {
          setFormDisabled(false)
        }
        res?.activityDetails?.length <= 0 &&
          toastNotify(TOAST.error, TOASTIFY_MESSAGES.notFindResultMessage)
        setCurrentPage(1)
        dispatch(setLoadedPage(1))
        setTotalPage(res?.paginationDetails?.totalPages)
        dispatch(setFilteredActivities(res?.activityDetails || []))
        setOpenFilter(false)
      } else {
        toastNotify(TOAST.error, TOASTIFY_MESSAGES.selectAnyoneFilterMessage)
        setFormDisabled(false)
        setIsLoading(false)
      }
    }
    useImperativeHandle(_ref, () => ({
      getClearFilter: () => {
        return {
          clearFilterHandler,
        }
      },
    }))
    useEffect(() => {
      ;(async () => {
        const users = await getActivityOptions('user')
        const projects = await getActivityOptions('project')
        const actions = await getActivityOptions('action')
        setUserOptions(users)
        setProjectOptions(projects)
        setActionOptions(actions)
      })()

      return () => {
        setUserOptions([])
        setProjectOptions([])
        setActionOptions([])
      }
    }, [])
    const menu = (
      <div className="apply-filter">
        <div className="apply-filter-head">
          <h2>Apply Filter</h2>
          <Button icon={<CloseIcon />} onClick={() => setOpenFilter(false)} />
        </div>

        <Form onFinish={onFinish} form={form} disabled={formDisabled}>
          <SelectField
            name="user"
            label="User"
            placeholder="Select User"
            type="text"
            options={userOptions}
          />
          <SelectField
            name="project"
            label="Project"
            options={projectOptions}
            placeholder="Select Project"
            type="text"
          />
          <SelectField
            name="action"
            label="Action"
            options={actionOptions}
            placeholder="Action"
            type="text"
          />
          <div className="btn-group">
            <PopupConfirm
              onConfirm={() => clearFilterHandler()}
              cancelText={NO}
              okText={YES}
              title={'Do you want to clear the filter?'}
            >
              <Button danger label="Cancel" disabled={isDisableCancel} />
            </PopupConfirm>
            <Button type="primary" label="Apply" htmlType="submit" />
          </div>
        </Form>
      </div>
    )
    return (
      <>
        <Dropdown
          overlay={menu}
          open={openFilter}
          trigger={['click']}
          placement="bottomLeft"
          getPopupContainer={(triggerNode) => triggerNode.parentElement}
        >
          <Filter openFilter={openFilter} setOpenFilter={setOpenFilter} />
        </Dropdown>
      </>
    )
  }
)

export default CustomizeFilterPopup
