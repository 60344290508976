import React, { useEffect, useState, useCallback } from 'react'
import './EditProfileModule.scss'
import { Avatar, Col, Form, Image, Row, Input } from 'antd'

import DrawerModule from '../Drawer/Drawer'
import InputField from '../InputField/InputField'
import Button from '../Button/Button'
import { images } from '../../config/images'
import EditIcon from '../../assets/icons/editCircle.svg'
import ImageUpload from '../ImageUpload/ImageUpload'
import { getUserProfile, updateUserProfile } from '../../utils/api/userServices'
import PopupConfirm from '../PopupConfirm/PopupConfirm'
import { phoneNumberValidation } from '../../utils/validations'
import {
  PLACEHOLDER_MESSAGES,
  TOASTIFY_MESSAGES,
  VALIDATION_MESSAGES,
} from '../../utils/constants/messages'
import { YES, NO, STATUS, TOAST } from '../../utils/constants/constant'
import { toastNotify } from '../../utils/helperFunctions/toastNotify'

const EditProfileModule = ({ open, onClose }) => {
  const [formDisabled, setFormDisabled] = useState(false)
  const [isPopupVisible, setIsPopupVisible] = useState(false)
  const [isDisableCancel, setIsDisableCancel] = useState(true)
  const [profileData, setProfileData] = useState()
  const [form] = Form.useForm()
  const loggedInUserData = JSON.parse(localStorage.getItem('userData'))
  // get user profile's data
  const fetchUserProfile = useCallback(
    async () => {
      const response = await getUserProfile(loggedInUserData?.userId)
      setProfileData(response?.data?.data)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )
  useEffect(() => {
    fetchUserProfile()
    return () => {
      form.resetFields()
      setProfileData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])
  // given dependency 'open' used to reset fields to autoPopulate data
  const onFinish = async (values) => {
    const { name, phoneNumber } = values
    setFormDisabled(true)
    const userId = loggedInUserData?.userId
    const response = await updateUserProfile({ userId, name, phoneNumber })
    if (response?.status === STATUS.ok) {
      toastNotify(TOAST.success, TOASTIFY_MESSAGES.updateProfileMessage)
      form.resetFields()
      setFormDisabled(false)
      const response = await getUserProfile(userId)
      setProfileData(response?.data?.data)
      onClose()
    } else {
      setFormDisabled(false)
      toastNotify(TOAST.error, response?.data?.message)
    }
  }
  const onFinishFailed = () => {
    toastNotify(TOAST.error, TOASTIFY_MESSAGES.failedUpdateProfileMessage)
  }
  return (
    <DrawerModule
      open={open}
      onClose={onClose}
      closable={!isPopupVisible}
      title="Edit Profile"
      size={'600px'}
    >
      <Form
        layout="vertical"
        className="edit-profile"
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        onFieldsChange={() => setIsDisableCancel(false)}
        disabled={formDisabled}
      >
        <div className="upload-image">
          <ImageUpload
            customPreview={
              <div className="edit-profile-image">
                <Avatar
                  src={images.profile}
                  alt=""
                  size={93}
                  className="profile-image"
                />
                <Image preview={false} src={EditIcon} />
              </div>
            }
            showUploadList={false}
          />
        </div>
        <Row gutter={40}>
          <Col sm={12} md={12} lg={12}>
            <Form.Item
              label="Name"
              name="name"
              initialValue={profileData?.name}
              rules={[
                {
                  required: true,
                  message: VALIDATION_MESSAGES.nameMessage,
                },
              ]}
            >
              <Input
                type="text"
                placeholder={PLACEHOLDER_MESSAGES.nameMessage}
              />
            </Form.Item>
          </Col>
          <Col sm={12} md={12} lg={12}>
            <InputField
              label="Role"
              name="role"
              placeholder={PLACEHOLDER_MESSAGES.userRoleMessage}
              type="text"
              existingValue={profileData?.role}
              readOnly={true}
            />
          </Col>
          <Col sm={24} md={24} lg={24}>
            <Form.Item
              label="Phone Number"
              name="phoneNumber"
              initialValue={profileData?.phoneNumber}
              rules={[
                {
                  required: true,
                  message: VALIDATION_MESSAGES.phoneNumberMessage,
                },
                {
                  validator: phoneNumberValidation,
                },
              ]}
            >
              <Input
                type="text"
                placeholder={PLACEHOLDER_MESSAGES.phoneNumberMessage}
              />
            </Form.Item>
          </Col>
          <Col lg={24} md={24} sm={24}>
            <InputField
              name="email"
              label="Email ID"
              placeholder={PLACEHOLDER_MESSAGES.emailAddressMessage}
              type="text"
              existingValue={profileData?.email}
              readOnly={true}
            />
          </Col>
        </Row>
        <div className="drawer-button btn-group">
          <PopupConfirm
            onConfirm={() => {
              form.resetFields()
              setIsPopupVisible(false)
              setIsDisableCancel(true)
            }}
            onCancel={() => setIsPopupVisible(false)}
            cancelText={NO}
            okText={YES}
            title={'Do you want to discard the changes?'}
          >
            <Button
              danger
              label="Cancel"
              disabled={isDisableCancel}
              onClick={() => setIsPopupVisible(!isPopupVisible)}
            />
          </PopupConfirm>
          <Button type="primary" label="Update" htmlType="submit" />
        </div>
      </Form>
    </DrawerModule>
  )
}

EditProfileModule.propTypes = {}

EditProfileModule.defaultProps = {}

export default EditProfileModule
