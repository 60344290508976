import { createSlice } from '@reduxjs/toolkit'
import { resetAllReduxStates } from './resetAllReduxStatesAction'
const initialState = {
  jobSchedulerList: [],
}
const jobSchedulerListSlice = createSlice({
  name: 'jobSchedulerList',
  initialState,
  reducers: {
    setjobSchedulerList: (state, { payload }) => {
      state.jobSchedulerList = payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetAllReduxStates, () => initialState)
  },
})

export const { setjobSchedulerList } = jobSchedulerListSlice.actions

export const jobSchedulerListSelector = (state) => state.jobSchedulerList

export default jobSchedulerListSlice.reducer
