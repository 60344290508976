import { createSlice } from '@reduxjs/toolkit'
import { resetAllReduxStates } from './resetAllReduxStatesAction'
const initialState = {
  projectNotesList: {},
}
const projectNotesListSlice = createSlice({
  name: 'projectNotesList',
  initialState,
  reducers: {
    setProjectNotesList: (state, { payload }) => {
      state.projectNotesList = payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetAllReduxStates, () => initialState)
  },
})
export const { setProjectNotesList } = projectNotesListSlice.actions

export const projectNotesListSelector = (state) => state?.projectNotesList

export default projectNotesListSlice.reducer
