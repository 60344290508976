import React, { useState, useEffect, useCallback } from 'react'
import { Col, Form, Row } from 'antd'
import { useDispatch, useSelector } from 'react-redux'

import InputField from '../InputField/InputField'
import PopupConfirm from '../PopupConfirm/PopupConfirm'
import Button from '../Button/Button'
import SelectField from '../SelectField/SelectField'
import SearchBar from '../SearchBar/SearchBar'
import {
  setCompanyBasedOptions,
  staticOptionsSelector,
} from '../../redux/reducer/getOptionsSlice'
import {
  addNewClients,
  getCompanyAndClientsByProjectId,
} from '../../utils/api/companyServices'
import {
  getCompanyOptionsLabel,
  updateCompanyBasedOptions,
} from '../../utils/helperFunctions/helperFunctions'
import { emailValidation, phoneNumberValidation } from '../../utils/validations'
import { suggestionsOptionsApiService } from '../../utils/api/staticOptionsServices'
import {
  PLACEHOLDER_MESSAGES,
  TOASTIFY_MESSAGES,
  VALIDATION_MESSAGES,
} from '../../utils/constants/messages'
import { YES, NO, STATUS, TOAST } from '../../utils/constants/constant'
import { toastNotify } from '../../utils/helperFunctions/toastNotify'

const AssignNewClientForm = ({ existingCompanyDetails, onClose }) => {
  const nameRegex = /^[a-zA-Z]+( [a-zA-Z]+)*$/

  const { companyOptions } = useSelector(staticOptionsSelector)

  const { projectId } = useSelector((state) => state.projectId)
  const [selectedCompanyId, setSelectedCompanyId] = useState()
  const [isFormDisabled, setIsFormDisabled] = useState(false)
  const [isFormDataChanged, setIsFormDataChanged] = useState(false)
  const [searchText, setSearchText] = useState()
  const [form] = Form.useForm()
  const [searchedCompanyOptions, setSearchedCompanyOptions] =
    useState(companyOptions)
  const dispatch = useDispatch()
  useEffect(() => {
    const getSearchedData = setTimeout(async () => {
      if (searchText) {
        const searchedCompanyList = await suggestionsOptionsApiService(
          'company',
          searchText
        )
        setSearchedCompanyOptions(searchedCompanyList)
      } else {
        setSearchedCompanyOptions(companyOptions)
      }
    }, 500)

    return () => clearTimeout(getSearchedData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText])
  useEffect(() => {
    if (existingCompanyDetails) {
      form.setFieldsValue({
        selectedCompany: `${existingCompanyDetails?.companyName} (${existingCompanyDetails?.region})`,
      })
      setSelectedCompanyId(existingCompanyDetails?.id)
    } else {
      form.setFieldValue('selectedCompany', null)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [existingCompanyDetails])
  async function onFinish(values) {
    setIsFormDisabled(true)
    const fullName = `${values.firstName} ${values.lastName}`
    const clientData = [
      {
        name: fullName,
        email: values.email,
        phoneNumber: values.phoneNumber,
        primary: existingCompanyDetails?.id !== selectedCompanyId,
      },
    ]
    const response = await addNewClients(
      selectedCompanyId,
      clientData,
      projectId
    )
    if (response?.status === STATUS.ok) {
      toastNotify(TOAST.success, TOASTIFY_MESSAGES.companyClientSuccessMessage)
      onClose()
      dispatch(getCompanyAndClientsByProjectId(projectId))
      setIsFormDisabled(false)
      form.resetFields()
      form.setFieldsValue({
        selectedCompany: companyOptions.filter(
          (item) => item.id === selectedCompanyId
        )[0]?.name,
      })
      const companyBasedOptions = await updateCompanyBasedOptions()
      dispatch(setCompanyBasedOptions(companyBasedOptions))
    } else if (response?.status === STATUS.badRequest) {
      toastNotify(TOAST.error, response?.data?.message)
      setIsFormDisabled(false)
    } else {
      toastNotify(TOAST.error, TOASTIFY_MESSAGES.failedAddCompanyMessage)
      setIsFormDisabled(false)
    }
  }

  async function onFinishFailed() {
    toastNotify(TOAST.error, TOASTIFY_MESSAGES.failedToAddNewClientMessage)
  }

  const companySelectHandler = useCallback(async (value) => {
    setSelectedCompanyId(value)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const handlePopupConfirm = () => {
    form.resetFields()
    existingCompanyDetails &&
      form.setFieldsValue({
        selectedCompany: `${existingCompanyDetails?.companyName} (${existingCompanyDetails?.region})`,
      })
    setIsFormDataChanged(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }
  return (
    <Form
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      form={form}
      disabled={isFormDisabled}
      onFieldsChange={() => setIsFormDataChanged(true)}
    >
      <Row gutter={40}>
        <Col md={24}>
          <SelectField
            name="selectedCompany"
            label="Existing companies in this portal"
            className={'select-company'}
            options={searchedCompanyOptions?.map((option) => {
              return {
                label: getCompanyOptionsLabel(option),
                value: option?.id,
              }
            })}
            isFeatured={true}
            type="text"
            placeholder={PLACEHOLDER_MESSAGES.companyNameMessage}
            onChange={companySelectHandler}
            required
            rules={[
              {
                required: true,
                message: VALIDATION_MESSAGES.companyNameMessage,
              },
            ]}
            dropdownRender={(menu) => (
              <>
                <SearchBar
                  placeholder={'Search'}
                  searchP="search-project exis_com-search"
                  searchText={searchText}
                  setSearchText={setSearchText}
                />
                {menu}
              </>
            )}
          />
        </Col>
        <Col md={12}>
          <InputField
            name="firstName"
            type="text"
            placeholder={PLACEHOLDER_MESSAGES.firstNameMessage}
            label="First Name"
            required
            rules={[
              {
                required: true,
                message: VALIDATION_MESSAGES.firstNameMessage,
              },
              {
                pattern: nameRegex,
                message: VALIDATION_MESSAGES.firstNamePatternMessage,
              },
            ]}
          />
        </Col>
        <Col md={12}>
          <InputField
            name="lastName"
            placeholder={PLACEHOLDER_MESSAGES.lastNameMessage}
            label="Last Name"
            type="text"
            required
            rules={[
              {
                required: true,
                message: VALIDATION_MESSAGES.lastNameMessage,
              },
              {
                pattern: nameRegex,
                message: VALIDATION_MESSAGES.lastNamePatternMessage,
              },
            ]}
          />
        </Col>
        <Col md={24}>
          <InputField
            name="email"
            placeholder={PLACEHOLDER_MESSAGES.userEmailMessage}
            label="Client User Email"
            type="email"
            required
            rules={[
              {
                required: true,
                message: VALIDATION_MESSAGES.userEmailMessage,
              },
              {
                validator: emailValidation,
              },
            ]}
          />
        </Col>
        <Col md={24}>
          <InputField
            name="phoneNumber"
            placeholder={PLACEHOLDER_MESSAGES.phoneNumberMessage}
            label="Phone Number"
            type="text"
            rules={[
              {
                validator: phoneNumberValidation,
              },
            ]}
          />
        </Col>
      </Row>
      <div className="drawer-button btn-group">
        <PopupConfirm
          onConfirm={() => handlePopupConfirm()}
          cancelText={NO}
          okText={YES}
          title={'Do you want to discard the changes?'}
        >
          <Button danger label="Cancel" disabled={!isFormDataChanged} />
        </PopupConfirm>
        <Button
          type="primary"
          label="Add Client"
          htmlType="submit"
          disabled={!isFormDataChanged}
        />
      </div>
    </Form>
  )
}

export default AssignNewClientForm
