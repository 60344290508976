import './Drawer.scss'
import React from 'react'
import { Drawer } from 'antd'

const DrawerModule = ({
  size,
  title,
  onClose,
  open,
  children,
  className,
  closable = true,
  destroyOnClose,
}) => {
  return (
    <Drawer
      width={size}
      className={`drawer-wrapper ${className}`}
      title={title}
      placement="right"
      onClose={onClose}
      open={open}
      closable={closable}
      forceRender
      destroyOnClose={destroyOnClose}
    >
      {children}
    </Drawer>
  )
}
export default DrawerModule
