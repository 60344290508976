import React from 'react'
import { Form, Input } from 'antd'

import './InputField.scss'

const InputField = ({
  label,
  placeholder,
  type,
  required,
  name,
  tooltip,
  prefix,
  size,
  disabled,
  inputText,
  readOnly = false,
  rules,
  existingValue,
  className,
  onInputFieldChange = () => {},
  onChange,
  onBlur,
  onKeyDown,
  step,
  suffix,
  onPressEnter,
}) => {
  return (
    <Form.Item
      className="text-field"
      label={label}
      required={required}
      name={name}
      tooltip={tooltip}
      rules={rules}
      initialValue={existingValue}
    >
      <Input
        className={className}
        value={inputText}
        onChange={onChange}
        placeholder={placeholder}
        type={type}
        prefix={prefix}
        size={size}
        disabled={disabled}
        onPressEnter={
          onPressEnter ? onPressEnter : () => onInputFieldChange(inputText)
        }
        autoComplete="off"
        readOnly={readOnly}
        min={0}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        step={step}
        suffix={suffix}
      />
    </Form.Item>
  )
}

export default InputField
