import React, { useState, useEffect } from 'react'
import './EditProjectModule.scss'
import { Collapse, Form } from 'antd'
import dateFormat from 'dateformat'
import { format } from 'date-fns'

import { DrawerModule, Button } from '..'
import { getProjectById, updateProject } from '../../utils/api/projectServices'
import { fetchProjectOptionsService } from '../../utils/api/staticOptionsServices'
import { getPredictionDetailsById } from '../../utils/api/predictionAndInvoiceServices'
import { PopupConfirm } from '..'
import moment from 'moment'
import {
  setProjectBasedOptions,
  staticOptionsSelector,
} from '../../redux/reducer/getOptionsSlice'
import { useDispatch, useSelector } from 'react-redux'
import BillingPanel from '../BillingPanel/BillingPanel'
import OtherDetailsPanel from '../OtherDetailsPanel/OtherDetailsPanel'
import ProjectDetailsPanel from '../ProjectDetailsPanel/ProjectDetailsPanel'
import {
  YES,
  NO,
  DATE_FORMATS,
  ignorableErrorFields,
  STATUS,
  TOAST,
  projectModel,
  payModel,
} from '../../utils/constants/constant'
import { TOASTIFY_MESSAGES } from '../../utils/constants/messages'
import { toastNotify } from '../../utils/helperFunctions/toastNotify'
import { useWatch } from 'antd/lib/form/Form'

const { Panel } = Collapse

const EditProjectModule = ({
  projectId,
  open,
  onClose,
  onChange,
  projectData,
  primaryCsm,
  secondaryCsmList,
  primaryAccountant,
  secondaryAccountantList,
}) => {
  const [document, setDocument] = useState({ addFiles: [], removeFiles: [] })
  let projectStartDate
  const [formDisabled, setFormDisabled] = useState(false)
  const [prjStartDate, setPrjStartDate] = useState('')
  const [show, setShow] = useState(true)
  const [isPopupVisible, setIsPopupVisible] = useState(false)
  const [isFormDataChanged, setIsFormDataChanged] = useState(false)
  const [isDisabledUpdate, setIsDisabledUpdate] = useState(true)
  const [isValidSize, setIsValidSize] = useState(true)
  const [isDisableAdd, setIsDisableAdd] = useState({
    salesPersons: false,
    secondaryCsm: false,
    accountantsList: false,
  })
  const [isShowFields, setIsShowFields] = useState({
    retainer: false,
    invoiceTemplate: false,
  })
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const selectedCsm = projectData?.csmUsers
  const projectModelFieldValue = useWatch('projectModel', form)
  const payModelFieldValue = useWatch('payModel', form)

  // below useEffect will hide/unhide Quantity field initially
  useEffect(() => {
    const isShowRetainer = Boolean(
      projectModelFieldValue === projectModel.TMRetainer &&
        payModelFieldValue === payModel.prepaid
    )
    const isShowInvoiceTemplate = Boolean(
      projectModelFieldValue === projectModel.hourly
    )
    setIsShowFields((prevState) => {
      return {
        ...prevState,
        retainer: isShowRetainer,
        invoiceTemplate: isShowInvoiceTemplate,
      }
    })
    form.setFieldValue('tmHours', projectData?.tmHours)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectModelFieldValue, payModelFieldValue, projectData])

  const {
    accTypeOptions,
    invoiceCycleOptions,
    payModelOptions,
    projectModelOptions,
    csmOptions,
    accountantOptions,
    leadSourceOptions,
    marketingChannelOptions,
    activeBillingOptions,
    salesPersonOptions,
  } = useSelector(staticOptionsSelector)
  const projectDetailsOptions = [
    accTypeOptions,
    invoiceCycleOptions,
    payModelOptions,
    projectModelOptions,
    csmOptions,
    accountantOptions,
    leadSourceOptions,
    marketingChannelOptions,
    activeBillingOptions,
    salesPersonOptions,
  ]

  const hasEmptyOptions = projectDetailsOptions.some(
    (option) => option?.length === 0
  )
  const getProjectDetailOptions = async () => {
    const projectsOptions = await fetchProjectOptionsService()
    dispatch(setProjectBasedOptions(projectsOptions))
  }

  // will fetch required options if one of them is empty
  useEffect(() => {
    if (hasEmptyOptions) {
      getProjectDetailOptions()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const projectStartDateString = projectData?.projectStartDate
    const dates = ['projectStartDate']
    const dateStrings = [projectStartDateString]

    dates.forEach((item, index) => {
      onDateChange(null, dateStrings[index], item)
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectData])

  const updateProjectBasedOptions = async () => {
    const projectsOptions = await fetchProjectOptionsService()
    dispatch(setProjectBasedOptions(projectsOptions))
  }

  function hasDuplicates(salesPersons) {
    const checkSet = new Set(salesPersons)

    return salesPersons.length !== checkSet.size
  }

  async function onFinish(values) {
    let selectedCsmList = []
    let selectedCsmData = []
    selectedCsmData.push({ email: values.csm, primary: true })
    selectedCsmList.push(values.csm)

    values.secondaryCsm &&
      values.secondaryCsm.forEach((item) => {
        selectedCsmData.push({
          email: item?.csm,
          primary: false,
        })
        selectedCsmList.push(item?.csm)
      })

    const selectedAccountantList = values?.accountantsList?.map((item) => {
      return { email: item?.accountant, primary: false }
    })
    const accountantUsers = [
      ...selectedAccountantList,
      { email: values.accountant, primary: true },
    ]
    let salesPersons = []
    Object.keys(values).forEach((key) => {
      if (key.includes('salesPerson')) {
        salesPersons.push(values[key])
      }
    })

    values.salesmans &&
      values.salesmans.forEach((item) => {
        salesPersons.push(item.salesperson)
      })

    const sameSalesmans = hasDuplicates(salesPersons)
    const sameCsm = hasDuplicates(selectedCsmList)
    const sameAccoutants = hasDuplicates(
      accountantUsers?.map((acc) => acc?.email)
    )

    if (salesPersons.length <= 0) {
      toastNotify(TOAST.error, TOASTIFY_MESSAGES.oneSalesmanMessage)
    } else if (sameSalesmans || sameCsm || sameAccoutants) {
      if (sameSalesmans && sameCsm && sameAccoutants) {
        toastNotify(TOAST.error, TOASTIFY_MESSAGES.uniqueNameMessage)
      } else if (sameSalesmans) {
        toastNotify(TOAST.error, TOASTIFY_MESSAGES.sameSalesPersonsMessage)
      } else if (sameCsm) {
        toastNotify(TOAST.error, TOASTIFY_MESSAGES.sameCsmMessage)
      } else if (sameAccoutants) {
        toastNotify(TOAST.error, TOASTIFY_MESSAGES.sameAccountantMessage)
      }
    } else if (isValidSize) {
      setFormDisabled(true)

      const activeBillingFlag = values.activeBillingFlag === YES

      const projectStartDate = dateFormat(
        form.getFieldsValue().projectStartDate,
        DATE_FORMATS.dd_mmm_yyyy
      )
      const salesPersonIds = salesPersons?.map((salesPersonId) => ({
        id: salesPersonId,
      }))

      const response = await updateProject(
        {
          projectId: parseInt(projectId),
          name: values.projectName,
          zohoProjectId: values.zohoProjectId,
          model: values.projectModel,
          cycle: values.cycle,
          invoiceTerm: values.invoiceTerm,
          payModel: values.payModel,
          accType: values.accType,
          projectStartDate,
          csmUsers: selectedCsmData,
          accountantUsers,
          salesPersons: salesPersonIds,
          leadSource: values.leadSource,
          channel: values.marketingChannel,
          activeBillingFlag,
          invoiceTemplate: values.invoiceTemplate,
          leaveTerm: values.leaveTerm,
          retainer: values.retainer,
          tmHours: values.tmHours,
          retainerActiveBalance: values?.retainerActiveBalance,
          enableInvoiceOverdueNotification:
            values?.enableInvoiceOverdueNotification,
          retainerActiveDate: Boolean(values?.retainerActiveBalance)
            ? format(
                new Date(values?.retainerActiveDate),
                DATE_FORMATS.dd_MMM_yyyy
              )
            : null,
        },
        document
      )
      if (response?.status === STATUS.ok) {
        setFormDisabled(false)
        toastNotify(TOAST.success, response?.data?.message)
        onClose()
        form.resetFields()
        setShow(true)
        setIsDisabledUpdate(true)
        setIsFormDataChanged(false)
        updateProjectBasedOptions()
        dispatch(getProjectById(projectId))
        dispatch(getPredictionDetailsById(projectId))
      } else {
        setFormDisabled(false)
        toastNotify(
          TOAST.error,
          response?.data?.message ?? TOASTIFY_MESSAGES?.wentWrongMessage
        )
      }
    }
  }

 function onFinishFailed() {
   toastNotify(TOAST.error, TOASTIFY_MESSAGES.failedUpdateProjectMessage)
 }

 const onDateChange = (date, dateString, name) => {
   const data = dateString && dateFormat(dateString, DATE_FORMATS.dd_mmm_yyyy)
   if (name === 'projectStartDate') {
     projectStartDate = data
     setPrjStartDate(projectStartDate ? moment(projectStartDate) : '')
   }
 }

 const handleFormChange = (changedFields, allFields) => {
   // To hide/unhide Quantity field
   const fields = allFields?.filter((field) =>
     Boolean(field?.name[0] === 'projectModel' || field?.name[0] === 'payModel')
   )
   const isShowRetainer = Boolean(
     fields[0].value === projectModel.TMRetainer &&
       fields[1].value === payModel.prepaid
   )
   const isShowInvoiceTemplate = Boolean(
     fields[0].value === projectModel.hourly
   )
   setIsShowFields((prevState) => {
     return {
       ...prevState,
       retainer: isShowRetainer,
       invoiceTemplate: isShowInvoiceTemplate,
     }
   })

   // To disable update if form has empty required empty fields
   const ignorableErrorFieldsArr = Object.values(ignorableErrorFields)
   const errorFields = allFields?.filter(
     (field) =>
       !ignorableErrorFieldsArr.includes(field?.name[0]) &&
       (field?.errors.length ||
         field?.value === undefined ||
         field?.value === null)
   )
   // check whether salemans,secondaryCsm & accountantsList is valid or not
   const validDynamicErrorFields = ignorableErrorFieldsArr.slice(0, -1)
   const dynamicFields = changedFields?.filter((field) =>
     validDynamicErrorFields.includes(field?.name[0])
   )

   const dynamicErrorFields =
     dynamicFields?.length &&
     dynamicFields?.filter((field) => {
       const fieldValues = field?.value
       if (
         Array.isArray(fieldValues) &&
         fieldValues?.some((value) => value === undefined)
       ) {
         // make disable dynamic field's add
         switch (field?.name[0]) {
           case ignorableErrorFields.salesmans:
             setIsDisableAdd((prevState) => ({
               ...prevState,
               salesPersons: true,
             }))
             break
           case ignorableErrorFields.secondaryCsm:
             setIsDisableAdd((prevState) => ({
               ...prevState,
               secondaryCsm: true,
             }))
             break
           case ignorableErrorFields.accountantsList:
             setIsDisableAdd((prevState) => ({
               ...prevState,
               accountantsList: true,
             }))
             break
           default:
             break
         }
         return true // indicate that the filter condition is met
       } else {
         switch (field?.name[0]) {
           case ignorableErrorFields.salesmans:
             setIsDisableAdd((prevState) => ({
               ...prevState,
               salesPersons: false,
             }))
             break
           case ignorableErrorFields.secondaryCsm:
             setIsDisableAdd((prevState) => ({
               ...prevState,
               secondaryCsm: false,
             }))
             break
           case ignorableErrorFields.accountantsList:
             setIsDisableAdd((prevState) => ({
               ...prevState,
               accountantsList: false,
             }))
             break
           default:
             break
         }
         return false // indicate that the filter condition is not met
       }
     })
   Array.isArray(dynamicErrorFields) && errorFields.push(...dynamicErrorFields)
   errorFields?.length ? setIsDisabledUpdate(true) : setIsDisabledUpdate(false)
 }

 return (
   <DrawerModule
     size={'600px'}
     open={open}
     onClose={onClose}
     title={'Edit Project'}
     closable={!isPopupVisible}
     destroyOnClose
   >
     <Form
       layout="vertical"
       className="add-project"
       form={form}
       onFinish={onFinish}
       onFinishFailed={onFinishFailed}
       scrollToFirstError
       onFieldsChange={(ChangedFields, allFields) => {
         setIsFormDataChanged(true)
         handleFormChange(ChangedFields, allFields)
       }}
       disabled={formDisabled}
     >
       <div className="add-project-details">
         <h3>Project Details</h3>
         <ProjectDetailsPanel
           projectData={projectData}
           form={form}
           projectModelOptions={projectModelOptions}
           isShowFields={isShowFields}
         />
       </div>
       <Collapse defaultActiveKey={[1]} onChange={onChange}>
         <Panel header="Billing Details" key="2" forceRender>
           <BillingPanel
             invoiceCycleOptions={invoiceCycleOptions}
             projectData={projectData}
             payModelOptions={payModelOptions}
             accTypeOptions={accTypeOptions}
             onDateChange={onDateChange}
             prjStartDate={prjStartDate}
             isShowFields={isShowFields}
           />
         </Panel>
         <Panel header="Other Details" key="3" forceRender>
           <OtherDetailsPanel
             csmOptions={csmOptions}
             projectData={projectData}
             accountantOptions={accountantOptions}
             setShow={setShow}
             setDocument={setDocument}
             show={show}
             salesPersonOptions={salesPersonOptions}
             setIsValidSize={setIsValidSize}
             isValidSize={isValidSize}
             leadSourceOptions={leadSourceOptions}
             marketingChannelOptions={marketingChannelOptions}
             activeBillingOptions={activeBillingOptions}
             form={form}
             selectedCsm={selectedCsm}
             primaryCsm={primaryCsm}
             secondaryCsmList={secondaryCsmList}
             primaryAccountant={primaryAccountant}
             secondaryAccountantList={secondaryAccountantList}
             isDisableAdd={isDisableAdd}
           />
         </Panel>
       </Collapse>
       <div className="drawer-button btn-group">
         <PopupConfirm
           onConfirm={() => {
             form.resetFields()
             setIsPopupVisible(false)
             setIsDisableAdd({
               salesPersons: false,
               secondaryCsm: false,
             })
             setPrjStartDate(
               moment(projectData?.projectStartDate, DATE_FORMATS.DD_MMM_YYYY)
             )
           }}
           onCancel={() => setIsPopupVisible(false)}
           cancelText={NO}
           okText={YES}
           title={'Do you want to discard the changes?'}
         >
           <Button
             danger
             label="Cancel"
             disabled={!isFormDataChanged}
             onClick={() => {
               setIsPopupVisible(!isPopupVisible)
             }}
           />
         </PopupConfirm>
         <Button
           type="primary"
           label={'Update'}
           htmlType="submit"
           disabled={isDisabledUpdate}
         />
       </div>
     </Form>
   </DrawerModule>
 )
}

export default EditProjectModule
