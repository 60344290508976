import './App.less'
import './layout/common.scss'
import React, { useEffect, useMemo, useState } from 'react'
import { renderRoutes } from './config/routes'
import ProtectedRoute from './components/ProtectedRoute/ProtectedRoute'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import { AuthContext } from './store/auth'
import AppLogout from './components/AppLogout/AppLogout'
import { useAuth } from './utils/hooks/authHook'
import { getCurrentRoleRoutes } from './utils/helperFunctions/helperFunctions'
import NotFound from './pages/NotFound/NotFound'
import { useNavigatorOnLine } from './utils/hooks/useNavigatorOnline'
import Nointernet from './components/NoInternet/NoInternet'
import AuthVerify from './utils/AuthVerify'

function App() {
  const isOnline = useNavigatorOnLine()
  const { login, logout, token, userId, userRole } = useAuth()
  const [currentUserRoutes, setCurrentUserRoutes] = useState([])

  const authContextValue = useMemo(
    () => ({
      token: token,
      userId: userId,
      userRole: userRole,
      login: login,
      logout: logout,
    }),
    [token, userId, userRole, login, logout]
  )
  useEffect(() => {
    const currentUserRoleRoutes = getCurrentRoleRoutes(renderRoutes, userRole)
    setCurrentUserRoutes(currentUserRoleRoutes)
  }, [userRole])

  return (
    <div className="App">
      <Nointernet isOnline={isOnline} />
      <AuthContext.Provider value={authContextValue}>
        <BrowserRouter>
          <AppLogout>
            <Switch>
              {currentUserRoutes.map(([key, route]) => (
                <Route
                  key={key}
                  path={route.path}
                  exact={route.exact}
                  render={() => <ProtectedRoute {...{ route }} />}
                />
              ))}
              {currentUserRoutes.length && (
                <Route>
                  <NotFound />
                </Route>
              )}
            </Switch>
          </AppLogout>
          <AuthVerify logout={logout} />
        </BrowserRouter>
      </AuthContext.Provider>
    </div>
  )
}
export default App
