import React from 'react'
import { Row, Col, Pagination } from 'antd'
import './TablePagination.scss'
import {
  DEFAULT_PAGE_NO,
  DEFAULT_PAGE_SIZE,
  DEFAULT_PAGINATION_SIZE,
} from '../../utils/constants/constant'
const TablePagination = ({
  page,
  pageSize,
  totalPages,
  totalElements,
  onTablePaginationChange,
}) => {
  return (
    totalElements > DEFAULT_PAGINATION_SIZE && (
      <Row justify="end" align="middle" gutter={26}>
        <Col>
          <span className="summary-text">
            {totalPages > DEFAULT_PAGE_NO && `Total ${totalPages} pages`}
          </span>
        </Col>
        <Col>
          <Pagination
            current={page}
            defaultCurrent
            defaultPageSize={DEFAULT_PAGE_SIZE}
            pageSize={pageSize}
            pageSizeOptions={['25', '50', '75', '100', '200']}
            total={totalElements}
            onChange={(page, pageSize) => {
              onTablePaginationChange(page, pageSize)
            }}
            showSizeChanger={true}
          />
        </Col>
      </Row>
    )
  )
}

export default TablePagination
