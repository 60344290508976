import { createSlice } from '@reduxjs/toolkit'
import { resetAllReduxStates } from './resetAllReduxStatesAction'
import { DEFAULT_PRJ_FILTER } from '../../utils/constants/constant'
import { setAllAccountantState, setAllCsmState } from './setCsmAccountantAction'

const initialState = {
  projectList: {},
  appliedFilter: DEFAULT_PRJ_FILTER,
}
const projectListSlice = createSlice({
  name: 'projectList',
  initialState,
  reducers: {
    clearCache: (state) => {
      state.projectList = []
    },
    setProjectList: (state, { payload }) => {
      state.projectList = payload
    },
    setAppliedFilter: (state, { payload }) => {
      state.appliedFilter = payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(resetAllReduxStates, () => initialState)
      .addCase(setAllCsmState, (state, { payload }) => {
        state.appliedFilter = {
          ...DEFAULT_PRJ_FILTER,
          filterValues: {
            ...DEFAULT_PRJ_FILTER.filterValues,
            csm: [payload.name],
          },
        }
      })
      .addCase(setAllAccountantState, (state, { payload }) => {
        state.appliedFilter = {
          ...DEFAULT_PRJ_FILTER,
          filterValues: {
            ...DEFAULT_PRJ_FILTER.filterValues,
            accountant: [payload.name],
          },
        }
      })
  },
})

export const { setProjectList, setAppliedFilter,clearCache } = projectListSlice.actions

export const projectListSelector = (state) => state.projectList

export default projectListSlice.reducer
