import axios from 'axios'
import React from 'react'
import moment from 'moment'
import {
  suggestionsOptionsApiService,
} from '../api/staticOptionsServices'
import DateFilterDropdown from '../../components/DateFilterDropdown/DateFilterDropdown'
import { getClientOptions } from '../getOptions'
import {
  booksNameFields,
  requiredFieldsForPrediction,
} from '../constants/constant'
import VerifiedIcon from '../../assets/icons/VerifiedIcon'
import { addDays, eachMonthOfInterval, format, isAfter } from 'date-fns'

export const filteredOptions = (field, Options, naOption) => {
  if (['csmEmail', 'accountantEmail'].includes(naOption)) {
    const filterOptions =
      Options?.length > 0
        ? Options?.map((option) => {
            return {
              [field]: option?.name,
              value: option?.email,
            }
          })
        : []
    filterOptions.push({ [field]: 'N/A', value: null })
    return filterOptions
  } else {
    const filterOptions =
      Options?.length > 0
        ? Options?.map((option) => {
            return {
              [field]: option,
              value: option,
            }
          })
        : []
    Boolean(naOption || naOption === null) &&
      filterOptions.push({ [field]: 'N/A', value: naOption })
    return filterOptions
  }
}

export const getHeaderOptions = (commonHeaders) => {
  const headerOptions = commonHeaders?.map((item, index) => {
    const path = item.path
    const key = item.key
    return {
      path,
      key,
      label: item.label,
    }
  })
  return headerOptions
}
export const getSideBarOptions = (userRole, commonSideBarOptions) => {
  const accountantSidebarOptions = commonSideBarOptions.filter(
    (item) => item.accountant
  )
  const csmSidebarOptions = commonSideBarOptions.filter((item) => item.csm)

  let options
  switch (userRole) {
    case 'ROLE_ACCOUNTANT':
      options = accountantSidebarOptions
      break
    case 'ROLE_CSM':
      options = csmSidebarOptions
      break
    default:
      options = commonSideBarOptions
      break
  }

  const sidebarOptions = options.map((item, index) => {
    const key = item.key
    const path = item.path
    return {
      path,
      key: `${key}`,
      icon: React.createElement(item.icon),
      label: item.label,
    }
  })
  return sidebarOptions
}
export function getCurrentRoleRoutes(routes, currentUserRole) {
  const accountantAccessibleRoutesArray = [
    'loginpage',
    'forgotpassword',
    'setpassword',
    'resetpassword',
    'projectlist',
    'projectdetails',
    'predictionlist',
    'predictiondetails',
    'dashboard',
    'invoicedetails',
    'clientdetails',
    'companylist',
    'companydetails',
    'useractivity',
    'ratedetails',
    'todolist',
    'leaveBalance',
    'projectNotes',
    'incentive',
    'revenuelist',
    'serverLogs',
    'invoiceDashboard',
  ]
  const csmNotAccessibleRoutesArray = [
    'userlist',
    'jobScheduler',
    'salesPersons',
    'identityProvider',
  ]
  switch (currentUserRole) {
    case 'ROLE_ADMIN':
      return routes

    case 'ROLE_CSM':
      const csmRoutes = []
      routes.forEach((routeItem) => {
        if (!csmNotAccessibleRoutesArray.includes(routeItem[0])) {
          csmRoutes.push(routeItem)
        }
      })
      return csmRoutes

    case 'ROLE_ACCOUNTANT':
      const accountantRoutes = []
      routes.forEach((routeItem) => {
        accountantAccessibleRoutesArray.forEach((requiredItem) => {
          if (routeItem[0] === requiredItem) {
            accountantRoutes.push(routeItem)
          }
        })
      })
      return accountantRoutes
    default:
      return routes
  }
}

export const setAPIHeader = (token, logout) => {
  if (token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
  } else if (logout) {
    axios.defaults.headers.common['Authorization'] = null
  }
}

export const getColumnSearchProps = (
  dataIndex,
  dateFrmt,
  picker,
  isRevenueTab = false,
  defaultFilteredValue,
  isNaOption = false
) => ({
  filterDropdown: ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
  }) => (
    <DateFilterDropdown
      setSelectedKeys={setSelectedKeys}
      selectedKeys={selectedKeys}
      confirm={confirm}
      clearFilters={clearFilters}
      dataIndex={dataIndex}
      dateFrmt={dateFrmt}
      picker={picker}
      defaultFilteredValue={defaultFilteredValue}
      isRevenueTab={isRevenueTab}
      isNaOption={isNaOption}
    />
  ),
  onFilter: isRevenueTab
    ? undefined
    : (value, record) => {
        return record[dataIndex]
          ? moment(record[dataIndex]).format(dateFrmt) === value
          : false
      },
  filteredValue: defaultFilteredValue,
})

export const updateCompanyBasedOptions = async () => {
  const companyOptions = await suggestionsOptionsApiService('company')
  const clientNameOptions = await getClientOptions()
  const companyCountry = await suggestionsOptionsApiService('companyCountry')
  const companyState = await suggestionsOptionsApiService('companyState')
  const companyCity = await suggestionsOptionsApiService('companyCity')
  const displayNameOptions = await suggestionsOptionsApiService('displayName')
  return {
    companyOptions,
    clientNameOptions,
    companyCountry,
    companyState,
    companyCity,
    displayNameOptions,
  }
}
export const renderFeaturedElement = (Options) => {
  return Options?.map((option) => {
    return {
      value: option.email,
      label: `${option?.name} | ${option?.email}`,
    }
  })
}

export const getTotalInvoicevalues = (list) => {
  let editableBilledSum = 0,
    billedSum = 0,
    nonBilledSum = 0,
    totalSum = 0,
    totalAmount = 0,
    totalLogDifference = 0

  list.forEach((record) => {
    if (record?.index !== 'Total') {
      billedSum += parseFloat(record?.billedHours) || 0
      nonBilledSum += parseFloat(record?.nonBilledHours) || 0
      editableBilledSum += record?.editableBilledHours || 0
      totalAmount += record?.amount || 0
      totalSum +=
        record?.calculatedBillingHours +
          record?.extraLoggedHours +
          record?.nonBilledHours || 0
      totalLogDifference +=
        record?.onTimeHoursSum - record?.overAllZohoLogs || 0
    }
  })

  return {
    billedSum,
    nonBilledSum,
    editableBilledSum,
    totalSum,
    totalAmount,
    totalLogDifference,
  }
}

/**
 * This function receives projectDetails and returns the value of validation fields and
 * missing fields while adding prediction and invoice
 */

export const validateRequiredFieldsForPredictions = (projectDetails) => {
  const validationFields = {}
  const missingFields = []

  for (const key in projectDetails) {
    if (Object.keys(requiredFieldsForPrediction).includes(key)) {
      validationFields[key] = projectDetails[key]
    }
  }
  Object.entries(validationFields).forEach((item) => {
    if (item[1] === '-') {
      missingFields.push(requiredFieldsForPrediction[item[0]])
    }
  })
  return { validationFields, missingFields }
}

export const isEmpty = (string) => string?.trim() === ''

export const debounce = (func, timeout) => {
  let timer
  return (...args) => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      func.apply(this, args)
    }, timeout)
  }
}

export const calculateTotal = (data, type) => {
  switch (type) {
    case 'leave':
      return data?.reduce(
        (total, currentValue) => total + currentValue?.numberOfDays,
        0
      )

    case 'hours':
      return data?.reduce(
        (total, currentValue) =>
          total + parseFloat(currentValue?.totalHours?.toFixed(2)),
        0
      )
    case 'onTime':
      return data?.reduce(
        (total, currentValue) => total + currentValue?.ontimeLogs?.hoursWorked,
        0
      )

    default:
      break
  }
}

/**
 @function mapOptionsByLabelValue

* @description to map select field option by label and value

* @params [options]

* @returns [{label:"abc",value:"id"}]

*

*/
export const mapOptionsByLabelValue = (options) => {
  return options?.map((option) => {
    return { label: option?.name, value: option?.id }
  })
}

/**
 @function getCustomerName

* @description return customer name from given companyDetails using first name ,last name etc fields 

* @params {comapnyDetails}

* @returns "customerName" || ''

*

*/
export const getCustomerName = (companyDetails) => {
  if (companyDetails) {
    const name = Object.values(companyDetails)
      .filter(
        (value, index) =>
          value !== null &&
          value !== undefined &&
          booksNameFields.includes(Object.keys(companyDetails)[index])
      )
      .join(' ')
    return name
  }
  return ''
}

export const removeExtraFieldsFromTimesheetData = (
  timesheetData,
  extraKeysArray
) => {
  for (const key in timesheetData) {
    if (extraKeysArray?.includes(key)) delete timesheetData[key]
  }
  return timesheetData
}

export const shallowCopyObject = (originalObj) => {
  return { ...originalObj }
  /**
 @function getCompanyOptionsLabel

* @description return options label with icon based on boolean value of quickbook/zohobooks checkbox

* @params {option}

* @returns <>"string (string) icon"</>

*

*/
}
export const getCompanyOptionsLabel = (option) => {
  return (
    <>
      {`${option?.name} (${option?.region})`}
      {option.modifyInBooks ? <VerifiedIcon /> : null}
    </>
  )
}

export const getMonthsBetween = (startMonth, endMonth) => {
  // Convert start and end months to date objects
  const startDate = new Date(startMonth)
  const endDate = new Date(endMonth)

  // Get the start and end of the month for the interval
  const interval = { start: startDate, end: endDate }

  // Get the months within the interval (inclusive)
  const months = eachMonthOfInterval(interval)

  // Format the months
  const formattedMonths = months.map((month) => format(month, 'MMM-yyyy'))

  return formattedMonths
}
export const calculateGetTimesheetEndDate = (startDate) => {
  const timeSheetStartDate = new Date(startDate)
  // Get today's date
  const today = new Date()

  // Check if today is after the start date
  if (isAfter(today, timeSheetStartDate)) {
    return today
  } else {
    // If today is not after the start date, add 1 day to the start date
    const endDate = addDays(timeSheetStartDate, 1)
    return endDate
  }
}

export const  getAccountNameById = (id, accountNameOptions) => {
  const account = accountNameOptions?.find((account) => id === account?.id)
  if (account) {
    return account?.name
  }
}


export const getSubstringBeforeUnderscore = (str) => {
  if (str) {
    const underscoreIndex = str.indexOf('_')
    if (underscoreIndex === -1) {
      return str
    }
    return str.substring(0, underscoreIndex)
  }
}
