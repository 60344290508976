import React from 'react'
import { AutoComplete, Form, Input } from 'antd'
import './SearchInvoiceField.scss'
import { filteredOptions } from '../../utils/helperFunctions/helperFunctions'

const SearchInvoiceField = ({
  label,
  placeholder,
  searchFormInstance,
  onClear,
  searchBarOptions,
  inputRef,
  setSearchText,
  autoSearchOptions,
  setAutoSearchOptions,
  onChange,
}) => {
  return (
    <Form.Item
      className={`text-field search-invoice-field`}
      label={label}
      name="searchInvoice"
    >
      <AutoComplete
        allowClear={true}
        name="searchInvoice"
        onChange={onChange}
        options={filteredOptions('label', autoSearchOptions)}
        onFocus={() => {
          setAutoSearchOptions(searchBarOptions)
        }}
        onSelect={() => searchFormInstance.submit()}
        onClear={onClear}
      >
        <Input.Search
          placeholder={placeholder || '1234'}
          onSearch={() => {
            searchFormInstance.submit()
          }}
          ref={inputRef}
        />
      </AutoComplete>
    </Form.Item>
  )
}

export default SearchInvoiceField
