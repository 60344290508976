import React from 'react'
import { AutoComplete, Select, Form } from 'antd'
/**
 * Custom AutoFieldComponent created with AutoComplete component of ant design
 */
const AutoFieldComponent = ({
  label,
  placeholder,
  name,
  tooltip,
  size,
  disabled,
  options,
  value,
  rules,
  filterOption,
  onSelect,
  dropdownMatchSelectWidth,
  existingValue,
  onChange,
  onFocus,
  onBlur,
  popupClassName,
  className,
  ...res
}) => {
  const [result, setResult] = React.useState([])
  const handleSearch = (value) => {
    let filteredOptions = []

    if (!value) {
      filteredOptions = options
    } else {
      filteredOptions = options.filter((option) =>
        option.value.toLowerCase().includes(value.toLowerCase())
      )
    }

    setResult(filteredOptions)
  }

  return (
    <Form.Item
      className={`text-field ${className}`}
      label={label}
      name={name}
      tooltip={tooltip}
      initialValue={existingValue}
      value={value}
      rules={rules}
    >
      <AutoComplete
        onSearch={handleSearch}
        placeholder={placeholder}
        size={size}
        disabled={disabled}
        filterOption={filterOption}
        options={options}
        value={value}
        onChange={onChange}
        onFocus={onFocus}
        onSelect={onSelect}
        onBlur={onBlur}
        dropdownMatchSelectWidth={dropdownMatchSelectWidth}
        popupClassName={popupClassName}
        getPopupContainer={(triggerNode) => triggerNode.parentElement}
      >
        {result.map((email) => (
          <Select.Option key={`${email}-${label}`} value={email}>
            {email}
          </Select.Option>
        ))}
      </AutoComplete>
    </Form.Item>
  )
}

export default AutoFieldComponent
