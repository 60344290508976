import React from 'react'
import './UploadFile.scss'
import { Form, Upload } from 'antd'
import Button from '../Button/Button'
import { ReactComponent as UploadIcon } from '../../assets/icons/uploadicon.svg'
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete.svg'

const UploadFile = ({
  label,
  placeholder,
  name,
  onChange,
  show,
  maxCount,
  rules,
  beforeUpload,
  docNames,
  isValidSize,
  documentNames,
}) => {
  const props = {
    name,
    onChange,
    maxCount,
    beforeUpload,
    defaultFileList: documentNames?.map((docName, index) => ({
      uid: index,
      name: docName,
    })),
    showUploadList: {
      showRemoveIcon: true,
      removeIcon: <DeleteIcon />,
    },
  }

  return (
    <>
      <Form.Item
        label={label}
        name={name}
        rules={rules}
        className={`select-field text-field upload-field ${
          !isValidSize ? 'invalid-file-size' : null
        }`}
      >
        <Upload {...props} multiple>
          <Button type="link" icon={<UploadIcon />} />
          {show && <p>{placeholder}</p>}
        </Upload>
      </Form.Item>
      <span className={`${!isValidSize ? 'file-upload-warning' : ''}`}>
        {!isValidSize &&
          'File is too large ,please upload file less than 10 MB'}
      </span>
    </>
  )
}

export default UploadFile
