import React from 'react'
import { useHistory } from 'react-router-dom'

import { Button } from '../../components'
import './NotFound.scss'

const NotFound = () => {
  const history = useHistory()
  return (
    <div className="not-found">
      <div className="not-found-content">
        <div className="not-found-404">
          <h1>404</h1>
        </div>
        <h2>OOPS, THE PAGE YOU ARE LOOKING FOR CAN'T BE FOUND!</h2>
        <Button
          type="primary"
          label="Back To Home"
          onClick={() => {
            history.replace('/')
          }}
        />
      </div>
    </div>
  )
}

export default NotFound
