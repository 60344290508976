import { API_END_POINTS } from '../constants/apiEndPointConstants'
import axios from 'axios'

export const getActivityOptions = async (optionsString) => {
  try {
    const response = await axios.get(
      `${API_END_POINTS.userActivities}/dd?key=${optionsString}`
    )
    return response?.data?.data || []
  } catch (error) {
    return []
  }
}

export const getInvoiceNumberSuggestions = async (value, projectId) => {
  const URL = `${API_END_POINTS.projectSuggestions}?key=invoiceNumber&value=${
    value ?? ''
  }&projectId=${projectId ?? 0}`
  try {
    const response = await axios.get(URL)
    return response?.data?.data
  } catch (error) {
    return []
  }
}
export const getClientNameSuggestions = async (companyId, value) => {
  const URL = `${API_END_POINTS.clients}?companyId=${companyId ?? '0'}&name=${
    value ?? ''
  }`
  try {
    const response = await axios.get(URL)
    return response?.data?.data
  } catch (error) {
    return []
  }
}
export const fetchRegionBasedCompanyOptionsService = async (
  provider,
  region
) => {
  const URL = `${API_END_POINTS.companyOptions}?provider=${provider}&region=${region}`
  try {
    const response = await axios.get(URL)
    return response
  } catch (err) {
    return err?.response
  }
}

export const fetchProjectOptionsService = async () => {
  const URL = `${API_END_POINTS.projectOptions}`
  try {
    const response = await axios.get(URL)
    return response?.data?.data
  } catch (error) {
    return error?.response
  }
}

export const fetchSalesPersonsOptionsService = async () => {
  const URL = `${API_END_POINTS.salesPersonOptions}`
  try {
    const response = await axios.get(URL)
    return response?.data?.data
  } catch (error) {
    return error?.response
  }
}

export const suggestionsOptionsApiService = async (key,value) => {
  const URL = `${API_END_POINTS.suggetionOptions}?key=${key}&value=${
    value ?? ''
  }`
  try {
    const response = await axios.get(URL)
    return response?.data?.data
  } catch (err) {
    return []
  }
}

export const fetchOptionsByProjectIdService = async (key,projectId) => {
  const URL = `${API_END_POINTS.suggetionOptions}?key=${key}&value=''&id=${projectId}`
  try {
    const response = await axios.get(URL)
    return response?.data?.data
  } catch (error) {
    return []
  }
}
