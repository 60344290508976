import React from 'react'
import { Result } from 'antd'
import CustomizeModal from '../Modal/Modal'

const Nointernet = ({ isOnline }) => {
  return (
    <CustomizeModal open={!isOnline} footer={null} closable={false}>
      <Result
        status="error"
        title="No Internet !"
        subTitle="Please check your internet connection"
      />
    </CustomizeModal>
  )
}
export default Nointernet
