import { Tabs } from 'antd'
import React from 'react'

const DataTabs = ({ className, onChange, items }) => (
  <div className="DataTabs">
    <Tabs
      className={className}
      items={items}
      defaultActiveKey="1"
      onChange={onChange}
    />
  </div>
)

export default DataTabs