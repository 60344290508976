/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */

import './DropDown.scss'
import { Dropdown, Menu } from 'antd'
import React, { useState, useEffect } from 'react'
import { ReactComponent as Action } from '../../assets/icons/action.svg'

const getItem = (label, key, icon, children) => {
  return {
    key,
    icon,
    children,
    label,
  }
}
const DropDown = ({
  onFirstItemClick,
  onSecondItemClick,
  onThirdItemClick = () => {},
  listItems,
  record,
}) => {
  const [menuItem, setMenuitem] = useState([])

  useEffect(() => {
    listItems.forEach((e) => {
      setMenuitem((item) => [
        ...item,
        {
          key: e.key,
          menuLabel: e.menuLabel,
          menuIcon: e.menuIcon,
        },
      ])
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const items = menuItem.map((menuitem) => {
    return getItem(menuitem.menuLabel, menuitem.key, menuitem.menuIcon)
  })

  const menu = (
    <div className="menu-list">
      <Menu
        selectable
        items={items}
        onClick={(e) => {
          if (e.key === '1' || e.key === '3') {
            onFirstItemClick(record, e.key)
          } else if (e.key === '2') {
            onSecondItemClick(record)
          } else {
            onThirdItemClick(record, e.key)
          }
        }}
      />
    </div>
  )
  return (
    <>
      <Dropdown
        overlay={menu}
        placement="bottomRight"
        arrow
        destroyPopupOnHide={true}
      >
        <Action />
      </Dropdown>
    </>
  )
}

export default DropDown
