import React, { useState } from "react";
import {Popover } from "antd";

import Button from "../Button/Button";
import InputTextArea from "../InputTextArea/InputTextArea";

const NotePopover = ({notes,onSave}) => {
    const [editedNotes, setEditedNotes] = useState(notes);
    const [open,setOpen] = useState(false)
    

    const handleNotesChange = (e) => {
      setEditedNotes(e.target.value);
    };
    const handleOpenChange = (isOpen) => {
        setOpen(isOpen);
        if (!isOpen) {
          // Save the edited notes when the popover is closed
          onSave(editedNotes);
        }
      };
    
   const title = (
       <div className="note-title">
         <div>Note</div>
         <Button  danger label={"save"} onClick ={()=>{
            setOpen(false)
            onSave(editedNotes)}} />
       </div>
       )

    return (
      <Popover
        content={
          <InputTextArea
            value={editedNotes}
            onChange={handleNotesChange}
            />    
        }
        placement="bottomRight"
        title={title}
        trigger="click"
        open={open}
        onOpenChange={handleOpenChange}
      >
      <Button
      type="link"
      className="add-package"
      label="note"         
      />
      </Popover>
    );
}

export default NotePopover;