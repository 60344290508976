import { Card } from 'antd'
import React from 'react'
import { ReactComponent as ThumpsUP } from '../../assets/icons/thumps_up.svg'
import './CardSmall.scss'
const { Meta } = Card

const CardSmall = ({ badge, description }) => {
  return (
    <div className="smallcard-wrap">
      <Card bordered={false}>
        <Meta avatar={<ThumpsUP />} title={badge} description={description} />
      </Card>
    </div>
  )
}

export default CardSmall
