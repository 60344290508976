import { createSlice } from '@reduxjs/toolkit'
import { resetAllReduxStates } from './resetAllReduxStatesAction'
const initialState = {
  companyId: null,
}
const companyIdSlice = createSlice({
  name: 'companyId',
  initialState,
  reducers: {
    setCurrentCompanyId: (state, action) => {
      state.companyId = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetAllReduxStates, () => initialState)
  },
})
export const { setCurrentCompanyId } = companyIdSlice.actions
export default companyIdSlice.reducer
