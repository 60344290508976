import './Empty.scss'
import React from 'react'
import { Empty } from 'antd'
import Button from '../Button/Button'
import { images } from '../../config/images'

const CustomizeRenderEmpty = ({ setDataSource, inputRef }) => {
  return (
    <div className="Empty">
      <Empty
        image={images.Empty}
        imageStyle={{
          height: 300,
        }}
        description={
          <p>No results found. Try searching with another Invoice number</p>
        }
      >
        <Button
          type="primary"
          label={'Search another Invoice'}
          onClick={() => {
            setDataSource(null)
            inputRef.current.focus()
          }}
        />
      </Empty>
    </div>
  )
}

export default CustomizeRenderEmpty
