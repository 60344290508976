import axios from 'axios'
import { API_END_POINTS } from '../constants/apiEndPointConstants'
import { toastNotify } from '../helperFunctions/toastNotify'
import { TOAST } from '../constants/constant'

export const fetchProjectNotesByProjectId = async (
  projectId,
  pageNo,
  pageSize,
  statusFilterValue
) => {
  const URL = `${API_END_POINTS.notes}/?status=${
    statusFilterValue || ''
  }&pageNo=${pageNo}&pageSize=${pageSize}&projectId=${projectId}`

  try {
    const response = await axios.get(URL)
    return response?.data?.data
  } catch (error) {
    toastNotify(
      TOAST.error,
      error?.data?.message || 'Failed to get project notes list.'
    )
    console.log(error?.message)
  }
}

export const addNewProjectNote = async (projectNotesDetails) => {
  const URL = `${API_END_POINTS.notes}`
  try {
    const response = await axios.post(URL, projectNotesDetails)
    return response
  } catch (err) {
    console.log('error while adding new project note', err)
    return err.response
  }
}

export const editProjectNote = async (projectNotesDetails) => {
  const URL = `${API_END_POINTS.notes}`
  try {
    const response = await axios.put(URL, projectNotesDetails)
    return response
  } catch (err) {
    console.log('error while editing project notes', err)
    return err.response
  }
}

export const deleteProjectNoteById = async (projectNoteId) => {
  const URL = `${API_END_POINTS.notes}?projectNotesId=${projectNoteId}`
  try {
    const response = await axios.delete(URL)
    return response
  } catch (error) {
    return error?.response
  }
}
