import React, { useCallback, useEffect, useState } from 'react'
import InputField from '../InputField/InputField'
import { ReactComponent as Search } from '../../assets/icons/searchicon.svg'
import { ReactComponent as CloseIcon } from '../../assets/icons/smallcloseicon.svg'
import './SearchBar.scss'
import { debounce } from '../../utils/helperFunctions/helperFunctions'

const SearchBar = ({
  label,
  placeholder,
  searchText,
  setSearchText,
  onSearchFieldChange,
  setDataSource = () => {},
  searchP,
}) => {
  const [inputText, setInputText] = useState('')

  const debouncedSetSearchText = useCallback(
    debounce((text) => setSearchText(text), 500),
    []
  )

  useEffect(() => {
    // Call the debounced function whenever inputText changes
    debouncedSetSearchText(inputText)
  }, [inputText, debouncedSetSearchText])

  return (
    <div className={`search-bar ${searchP}`}>
      <InputField
        inputText={inputText}
        onChange={(e) => {
          setInputText(e.target.value)
        }}
        label={label}
        placeholder={placeholder}
        type="text"
        prefix={
          searchText ? (
            <CloseIcon
              onClick={() => {
                setInputText('')
                setSearchText('')
                setDataSource(null)
              }}
            />
          ) : (
            <Search />
          )
        }
        onInputFieldChange={onSearchFieldChange}
      />
    </div>
  )
}

export default SearchBar
