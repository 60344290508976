import React from 'react'
import { Input, Form } from 'antd'
import './Textarea.scss'

const { TextArea } = Input

const Textarea = ({
  label,
  required,
  name,
  tooltip,
  rules,
  existingValue,
  placeholder,
  autoSize,
  onChange,
  value,
  ...res
}) => {
  return (
    <Form.Item
      className="text-field"
      label={label}
      required={required}
      name={name}
      tooltip={tooltip}
      rules={rules}
      initialValue={existingValue}
    >
      <TextArea
        {...res}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        autoSize={autoSize}
      />
    </Form.Item>
  )
}

export default Textarea
