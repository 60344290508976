import React from 'react'
import { Typography } from 'antd'

function TypographyText({ text, disabled, ...rest }) {
  return (
    <Typography.Text disabled={disabled} {...rest}>
      {text}
    </Typography.Text>
  )
}

export default TypographyText
