import React from 'react'
import { Table } from 'antd'
import { deductedServices } from '../../utils/constants/constant'

export const defaultRowData = (uniqueUserId) => {
  return {
    userId: uniqueUserId,
    employeeName: null,
    userRole: '',
    rate: 0,
    userLeaveData: {
      leaveTaken: 0,
      leaveUsed: 0,
      leaveDeduced: 0,
      currentLeaveBalance: 0,
      previousLeaveBalance: 0,
    },
    ontimeVsZoho: 0,
    missingHours: 0,
    billedHours: 0,
    nonBilledHours: 0,
    onTimeHoursSum: 0,
    calculatedBillingHours: 0,
    extraLoggedHours: 0,
    totalHours: 0,
    amount: 0,
    billingModel: null,
    isDeletable: true,
  }
}
export const defaultServiceRowData = (uniqueUserId) => {
  return {
    id: uniqueUserId,
    name: null,
    rate: 0,
    quantity: 1,
    description: null,
    isDeletable: true,
    isService: true,
    manuallyAdded: true,
  }
}

export const timesheetSummary = (
  pageData,
  showDeductionColumn,
  isDisplayCalculateAmount,
  currentInvoiceTimesheetData,
  showModelColumn,
  isTMretainerPrepaidModel
) => {
  let totalBillable = 0
  let totalNonBillable = 0
  let totalOntimeHours = 0
  let totalOntimeVsZoho = 0
  let totalMissingTime = 0
  let totalHoursSum = 0
  let totalAmount = 0
  let totalBilled = 0
  let totalExtraLoggedHours = 0
  let totalDeduction = 0
  let totalDiscountedHours = 0
  pageData.forEach(
    ({
      billedHours,
      nonBilledHours,
      onTimeHoursSum,
      ontimeVsZoho,
      missingTime,
      extraLoggedHours,
      calculatedBillingHours,
      discountedHours,
      rate,
      userLeaveData,
      isService,
      name,
    }) => {
      const serviceAmount = isService
        ? deductedServices.includes(name)
          ? -rate
          : rate
        : 0
      const developerAmount = !isService
        ? rate *
          (calculatedBillingHours +
            extraLoggedHours -
            userLeaveData?.leaveDeduced -
            discountedHours)
        : 0
      const calculatedAmount = developerAmount + serviceAmount
      const calculatedTotalHours =
        calculatedBillingHours +
        extraLoggedHours +
        nonBilledHours -
        userLeaveData?.leaveDeduced -
        discountedHours
      totalBillable += Number(billedHours) || 0
      totalNonBillable += Number(nonBilledHours) || 0
      totalOntimeHours += Number(onTimeHoursSum) || 0
      totalOntimeVsZoho += Number(ontimeVsZoho) || 0
      totalMissingTime += Number(missingTime) || 0
      totalBilled += Number(calculatedBillingHours) || 0
      totalExtraLoggedHours += Number(extraLoggedHours) || 0
      totalHoursSum += Number(calculatedTotalHours) || 0
      totalAmount += Number(calculatedAmount) || 0
      totalDeduction += Number(userLeaveData?.leaveDeduced) || 0
      totalDiscountedHours += Number(discountedHours) || 0
    }
  )

  return (
    <>
      <Table.Summary.Row className="table-summary bold-text">
        <Table.Summary.Cell>Total</Table.Summary.Cell>
        <Table.Summary.Cell />
        {showModelColumn && <Table.Summary.Cell />}
        <Table.Summary.Cell />
        <Table.Summary.Cell />
        <Table.Summary.Cell />
        <Table.Summary.Cell>{totalBillable?.toFixed(2)}</Table.Summary.Cell>
        <Table.Summary.Cell>{totalNonBillable?.toFixed(2)}</Table.Summary.Cell>
        <Table.Summary.Cell>{totalOntimeHours?.toFixed(2)}</Table.Summary.Cell>
        <Table.Summary.Cell>{totalOntimeVsZoho?.toFixed(2)}</Table.Summary.Cell>
        <Table.Summary.Cell>{totalMissingTime?.toFixed(2)}</Table.Summary.Cell>
        <Table.Summary.Cell>{totalBilled?.toFixed(2)}</Table.Summary.Cell>
        <Table.Summary.Cell>
          {totalExtraLoggedHours?.toFixed(2)}
        </Table.Summary.Cell>
        {showDeductionColumn && (
          <Table.Summary.Cell>{totalDeduction?.toFixed(2)}</Table.Summary.Cell>
        )}
        {!isTMretainerPrepaidModel && (
          <Table.Summary.Cell>
            {totalDiscountedHours?.toFixed(2)}
          </Table.Summary.Cell>
        )}
        <Table.Summary.Cell>{totalHoursSum?.toFixed(2)}</Table.Summary.Cell>
        <Table.Summary.Cell />
        <Table.Summary.Cell>
          {isDisplayCalculateAmount
            ? currentInvoiceTimesheetData?.invoiceGenerateDetails?.invoiceAmount?.toFixed(
                2
              )
            : totalAmount?.toFixed(2)}
        </Table.Summary.Cell>
        <Table.Summary.Cell />
      </Table.Summary.Row>
    </>
  )
}

export const getEmployeeServiceOptions = (isService, options) => {
  return isService
    ? options?.map((data) => {
        return {
          label: data?.name,
          value: data?.name,
        }
      })
    : options?.map((data) => {
        return {
          label: `${data?.employeeName} (${data?.userRole})`,
          value: `${data?.employeeName} (${data?.userRole})`,
        }
      })
}
