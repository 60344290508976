import axios from 'axios'
import { API_END_POINTS } from '../constants/apiEndPointConstants'
import { toastNotify } from '../helperFunctions/toastNotify'
import { TOAST } from '../constants/constant'

export const getVerificationWeeksByPredId = async (predictionId) => {
  const URL = `${API_END_POINTS.verification}/weeks?predictionId=${predictionId}`
  try {
    const response = await axios.get(URL)
    return response?.data?.data
  } catch (error) {
    console.log(error?.message)
  }
}

export const getTimesheetByVerificationId = async (verificationId) => {
  const URL = `${API_END_POINTS.verification}/${verificationId}`
  try {
    const response = await axios.get(URL)

    return response?.data?.data
  } catch (error) {
    toastNotify(
      TOAST.error,
      error?.response?.data?.message ||
        error?.message ||
        'Failed to get week timesheet data'
    )
    return []
  }
}

export const updateVerification = async (data) => {
  const URL = `${API_END_POINTS.verification}`

  try {
    const response = await axios.put(URL, data)
    return response
  } catch (err) {
    console.log('error while updating verification', err)
  }
}

export const reCalculateLeavesService = async (
  projectName,
  startDate,
  endDate,
  timesheetStartDate,
  timesheetEndDate,
  zohoEmployeeMetadata
) => {
  const URL = `${
    API_END_POINTS.timesheet
  }/recalculate/leave?projectName=${encodeURIComponent(projectName)}&startDate=${startDate}&endDate=${endDate}&timesheetStartDate=${timesheetStartDate}&timesheetEndDate=${timesheetEndDate}`
  try {
    const response = await axios.post(URL, zohoEmployeeMetadata)
    return response
  } catch (error) {
    error?.response?.data?.message &&
      toastNotify(TOAST.error, error?.response?.data?.message)
  }
}

export const generateVerificationWeeksService =async (predictionId) => {
  const URL = `${API_END_POINTS.verification}?predictionId=${predictionId}`
  try {
    const response = await axios.post(URL)
    return response
  }
  catch (error) {
    return error?.response
  }
}