import { Col, Row } from 'antd'
import React from 'react'
import InputField from '../InputField/InputField'

const TechStackInputForm = ({ techStackData }) => {
  return (
    <div>
      <Row gutter={40}>
        <Col md={24}>
          <InputField
            label={`${techStackData?.techStack}`}
            name={[techStackData?.techStack]}
            type="number"
            existingValue={techStackData?.value}
          />
        </Col>
      </Row>
    </div>
  )
}
export default TechStackInputForm
