const BASE_URL = process.env.REACT_APP_BASE_URL
const QUICKBOOK_BASE_URL = process.env.REACT_APP_QUICKBOOK_URL

export const API_END_POINTS = {
  authenticate: `${BASE_URL}/authenticate`,
  projects: `${BASE_URL}/projects`,
  projectSuggestions: `${BASE_URL}/projects/suggestion`,
  predictionProjects: `${BASE_URL}/predictions`,
  projectsInvoices: `${BASE_URL}/invoices`,
  userActivities: `${BASE_URL}/activities`,
  users: `${BASE_URL}/users`,
  qrCode: `${BASE_URL}/qrCode`,
  gAuthenticate: `${BASE_URL}/gAuthenticate`,
  getContractDocumentByProjectId: `${BASE_URL}/projects/contract`,
  clients: `${BASE_URL}/clients`,
  company: `${BASE_URL}/company`,
  rates: `${BASE_URL}/rates`,
  techStack: `${BASE_URL}/techStack`,
  timesheet: `${BASE_URL}/zoho/timesheet`,
  todo: `${BASE_URL}/todo`,
  employees: `${BASE_URL}/employees`,
  quickbook: `${BASE_URL}/quickbook`,
  job: `${BASE_URL}/job`,
  serverLogs: `${BASE_URL}/serverLogInfo`,
  notes: `${BASE_URL}/notes`,
  verification: `${BASE_URL}/verification`,
  salesPerson: `${BASE_URL}/sales-person`,
  quickbookConnect: {
    simform: `${BASE_URL}/oauth/QUICK_BOOKS_SIMFORM`,
    simformTech: `${BASE_URL}/oauth/QUICK_BOOKS_SIMFORM_TECH`,
    us: `${BASE_URL}/oauth/QUICK_BOOKS_US`,
    uk: `${BASE_URL}/oauth/QUICK_BOOKS_UK`,
    ca: `${BASE_URL}/oauth/QUICK_BOOKS_CA`,
    ind: `${BASE_URL}/oauth/ZOHO_BOOKS_IND`,
    uae: `${BASE_URL}/oauth/ZOHO_BOOKS_UAE`,
  },
  holiday: `${BASE_URL}/holiday`,
  sheets: `${BASE_URL}/google/sheets`,
  googleOAuth: `${BASE_URL}/google/oauth2`,
  revenue: `${BASE_URL}/revenue`,
  ccBcc: `${BASE_URL}/company/ccBcc`,
  projectOptions: `${BASE_URL}/options/project`,
  salesPersonOptions: `${BASE_URL}/options/salesperson`,
  companyOptions: `${BASE_URL}/options/company`,
  logout: `${BASE_URL}/blacklist`,
  suggetionOptions: `${BASE_URL}/options/suggestion`,
  identityProvider: `${BASE_URL}/identity-provider`,
  invoiceDashboard: `${BASE_URL}/invoices/dashboard`,
}

export const QUICKBOOK_END_POINTS = {
  viewInQuickbook: `${QUICKBOOK_BASE_URL}/app/invoice`,
}
