import React from 'react'

import DrawerModule from '../Drawer/Drawer'
import DataTabs from '../DataTabs/DataTabs'
import AssignNewClientForm from './AssignNewClientForm'
import AssignExistingClientsForm from './AssignExistingClientsForm'

const AssignClientsModule = ({
  open,
  onClose,
  title,
  existingCompanyandClientsInProject,
}) => {
  const items = [
    {
      label: 'Existing Client Details',
      key: 1,
      children: [
        <AssignExistingClientsForm
          existingCompanyDetails={
            existingCompanyandClientsInProject?.companyDetails
          }
          existingClientsList={
            existingCompanyandClientsInProject?.clientDetailsList
          }
          onClose={onClose}
          open={open}
        />,
      ],
    },
    {
      label: 'New Client Details',
      key: 2,
      children: [
        <AssignNewClientForm
          existingCompanyDetails={
            existingCompanyandClientsInProject?.companyDetails
          }
          onClose={onClose}
        />,
      ],
    },
  ]
  return (
    <DrawerModule title={title} open={open} onClose={onClose} size={600}>
      <DataTabs items={items} />
    </DrawerModule>
  )
}

export default AssignClientsModule
