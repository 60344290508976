import { message } from 'antd'
import { useEffect } from 'react'
import { useAuth } from '../../utils/hooks/authHook'

const AppLogout = ({ children }) => {
  const { logout } = useAuth()

  const events = [
    'load',
    'mousemove',
    'mousedown',
    'click',
    'scroll',
    'keypress',
  ]
  let timer
  // this function sets the timer that logs out the user after 10 Minutes of inactivity
  const handleLogoutTimer = () => {
    const timeOfUserInactivity = 30 * 60 * 1000
    timer = setTimeout(() => {
      // clears any pending timer.
      resetTimer()
      // Listener clean up. Removes the existing event listener from the window
      Object.values(events).forEach((item) => {
        window.removeEventListener(item, resetTimer)
      })
      // logs out user
      message.loading({
        content: `Signing you out...`,
        duration: [5],
        onClose: logout,
      })
    }, timeOfUserInactivity)
  }
  // this resets the timer if it exists.
  const resetTimer = () => {
    if (timer) clearTimeout(timer)
  }
  // when component mounts, it adds an event listeners to the window
  // each time any of the event is triggered, i.e on mouse move, click, scroll, keypress etc, the timer to logout user after 10 Minutes of inactivity resets.
  // However, if none of the event is triggered within 10 Minutes, that is app is inactive, the app automatically logs out.
  useEffect(() => {
    Object.values(events).forEach((item) => {
      window.addEventListener(item, () => {
        resetTimer()
        if (localStorage.getItem('userData')) {
          handleLogoutTimer()
        }
      })
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  // logs out user by clearing out auth token in localStorage and redirecting url to login page.
  return children
}

export default AppLogout
