import { VALIDATION_MESSAGES } from './constants/messages'

export const phoneNumberValidation = (rule, value) => {
  if (
    !value ||
    /^(\+\d{1,3}(\s)?)?((\(\d{3}\))|\d{3})[- .]?\d{3}[- .]?\d{4}$/.test(value)
  ) {
    return Promise.resolve()
  }
  return Promise.reject(VALIDATION_MESSAGES.phoneNumberPatternMessage)
}

export const emailValidation = (rule, value) => {
  if (value) {
    const emailAddresses = value.split(',')
    for (const emailAddress of emailAddresses) {
      if (
        !/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(
          emailAddress.trim()
        )
      ) {
        return Promise.reject(VALIDATION_MESSAGES.emailAddressPatternMessage)
      }
    }
  }

  return Promise.resolve()
}
export const whitespaceRegEx = /^\S+$/
export const nameRegex = /^[a-zA-Z]+( [a-zA-Z]+)*$/
export const websiteRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i
export const passwordRegEx =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.*\d)(?=.*[^A-Za-z0-9])[A-Za-z\d!@#$%^&*]{8,20}$/

