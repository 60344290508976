import { notification } from 'antd'
import { TOAST } from '../constants/constant'

export const toastNotify = (type, description, message) => {
  switch (type) {
    case TOAST.success:
      notification.success({
        message: '',

        description: description,

        duration: '5',

        placement: 'bottomRight',
      })

      break

    case TOAST.error:
      notification.error({
        message: '',

        description: description,

        duration: '5',

        placement: 'bottomRight',
      })

      break

    case TOAST.info:
      notification.info({
        message: '',

        description: description,

        duration: '5',

        placement: 'bottomRight',
      })

      break
    case TOAST.loading:
      notification.loading({
        message: '',

        description: description,

        duration: 5,

        placement: 'bottomRight',
      })
      break
    case TOAST.warning:
      notification.warning({
        message: '',

        description: description,

        duration: 5,

        placement: 'bottomRight',
      })
      break
    case TOAST.staticInfo:
      notification.info({
        message: message,

        description: description,

        duration: 0,

        placement: 'bottomRight',
      })
      break
    default:
      break
  }
}
