import React, { useCallback, useEffect, useState } from 'react'
import { Tooltip, Layout, Menu, Row, Col, Spin, Table, Modal } from 'antd'
import { useDispatch, useSelector } from 'react-redux'

import './WeeksVerification.scss'
import DataTable from '../DataTable/DataTable'
import {
  DATE_FORMATS,
  PREPAID,
  STATUS,
  TOAST,
  colorIntensity,
  projectModel,
} from '../../utils/constants/constant'
import { ReactComponent as BackIcon } from '../../assets/icons/backicon.svg'
import { ReactComponent as CorrectIcon } from '../../assets/icons/correct.svg'
import { ReactComponent as IncorrectIcon } from '../../assets/icons/incorrect.svg'
import { ReactComponent as Refresh } from '../../assets/icons/refresh-icon.svg'
import Button from '../Button/Button'
import {
  getTimesheetByVerificationId,
  updateVerification,
} from '../../utils/api/timesheetServices'
import {
  PLACEHOLDER_MESSAGES,
  TOASTIFY_MESSAGES,
} from '../../utils/constants/messages'
import { toastNotify } from '../../utils/helperFunctions/toastNotify'
import {
  setVerificationData,
  timeSheetsDataSelector,
} from '../../redux/reducer/timeSheetsDataSlice'
import { format } from 'date-fns'
import DetailsModal from '../DetailsModal/DetailsModal'
import SolidCircle from '../../assets/icons/SolidCircle'
import InputTextArea from '../InputTextArea/InputTextArea'
import CheckBox from '../CheckBox/CheckBox'
import { projectDetailsSelector } from '../../redux/reducer/getProjectsByIdSlice'
import EmptyBillingWarningModal from '../EmptyBillingWanrningModal/EmptyBillingWarningModal'
import { useHistory } from 'react-router-dom'
const { Sider } = Layout
const WeeksVerification = ({
  setShowWeekVerificaton,
  selectedWeek,
  verificationNameFormat,
  fetchVerificationData,
  todoVerificationId,
  setShowGenerateInvoiceModal,
}) => {
  const [currentSelectedWeek, setCurrentSelectedWeek] = useState(selectedWeek)
  const [isLoading, setIsLoading] = useState(false)
  const [showConfirmVerifyModal, setShowConfirmVerifyModal] = useState(false)
  const { verificationData } = useSelector(timeSheetsDataSelector)
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [showDetailsModal, setShowDetailsModal] = useState(false)
  const [modalType, setModalType] = useState('')
  const [detailData, setDetailData] = useState([])
  const [timesheetNote, setTimesheetNote] = useState('')
  const [isWeekVerified, setIsWeekVerified] = useState(false)
  const [isEmptyBillingModalOpen, setIsEmptyBillingModalOpen] = useState(false)
  const [emptyBillingModelList, setEmptyBillingModelList] = useState([])

  const dispatch = useDispatch()
  const history = useHistory()
  const currentTime = format(new Date(), DATE_FORMATS.DATE_WITH_TIME)
  const { projectDetails } = useSelector(projectDetailsSelector)
    const { projectId } = useSelector((state) => state?.projectId)

  const isMixedPrepaidModel = Boolean(
    projectDetails?.model === projectModel?.MIXED &&
      projectDetails?.payModel === PREPAID
  )

  const allWeeksVerificationData =
    verificationData[verificationNameFormat]?.verificationTableData
  const currentWeekVerificationData = allWeeksVerificationData?.filter(
    ({ week }) => week === Number(currentSelectedWeek)
  )[0]

  const verificationId = currentWeekVerificationData?.verificationId
  const weekStartDate = currentWeekVerificationData?.startDate
  const weekEndDate = currentWeekVerificationData?.endDate

  const currentWeekNameFormat = `${verificationId}_${weekStartDate}_${weekEndDate}`

  const currentWeekTimesheetData = todoVerificationId
    ? verificationData[`todo_${todoVerificationId}`]
    : verificationData[verificationNameFormat]?.weeksTimesheetData &&
      verificationData[verificationNameFormat]?.weeksTimesheetData[
        currentWeekNameFormat
      ]
  const disableVerificationOps =
    currentWeekTimesheetData?.zohoEmployeeMetadataList?.length === 0
  const showModelColumn = projectDetails?.model === projectModel?.MIXED

  const fetchWeekTimesheetData = useCallback(
    async (newVerificationId) => {
      setIsLoading(true)
      const data = await getTimesheetByVerificationId(
        newVerificationId || currentWeekVerificationData?.verificationId
      )
      // check empty billing model for mixed+prepaid model
      const emptyBillingModelList =
        isMixedPrepaidModel &&
        data?.zohoEmployeeMetadataList?.filter(
          (employee) => employee?.billingModel === null
        )
      if (emptyBillingModelList?.length) {
        setIsEmptyBillingModalOpen(true)
        setEmptyBillingModelList(emptyBillingModelList)
      }
      const verificationId =
        todoVerificationId || currentWeekVerificationData?.verificationId
      const weekStartDate = data?.invoiceGenerateDetails?.timesheetStartDate
      const weekEndDate = data?.invoiceGenerateDetails?.timesheetEndDate
      const weekTimesheetNameFormat = `${
        newVerificationId || verificationId
      }_${weekStartDate}_${weekEndDate}`
      const newVerificationData = {
        ...verificationData,
        [verificationNameFormat]: {
          ...verificationData[verificationNameFormat],
          weeksTimesheetData: {
            ...verificationData[verificationNameFormat]?.weeksTimesheetData,
            [weekTimesheetNameFormat]: {
              ...data,
              lastSyncedTime: currentTime,
            },
          },
        },
      }
      dispatch(setVerificationData(newVerificationData))
      setIsLoading(false)
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentSelectedWeek, currentWeekVerificationData, todoVerificationId]
  )
  const fetchWeekTimesheetDataForTodo = useCallback(async () => {
    setIsLoading(true)
    const data = await getTimesheetByVerificationId(todoVerificationId)
    const todoWeekTimesheetNameFormat = `todo_${todoVerificationId}`
    const newVerificationData = {
      ...verificationData,
      [todoWeekTimesheetNameFormat]: {
        ...data,
        lastSyncedTime: currentTime,
      },
    }
    dispatch(setVerificationData(newVerificationData))
    setIsLoading(false)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [todoVerificationId])

  useEffect(() => {
    if (todoVerificationId) {
      if (!verificationData[`todo_${todoVerificationId}`])
        fetchWeekTimesheetDataForTodo()
    } else if (
      (verificationData[verificationNameFormat]?.weeksTimesheetData &&
        !verificationData[verificationNameFormat]?.weeksTimesheetData[
          currentWeekNameFormat
        ]) ||
      !verificationData[verificationNameFormat]?.weeksTimesheetData
    ) {
      fetchWeekTimesheetData()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [todoVerificationId])
  useEffect(() => {
    setTimesheetNote(currentWeekVerificationData?.note)
    setIsWeekVerified(currentWeekVerificationData?.verified)
  }, [currentWeekVerificationData])

  const weekTableColumns = [
    {
      title: 'Name',
      dataIndex: 'employeeName',
      width: '10%',
      ellipsis: true,
    },
    {
      title: 'Role',
      dataIndex: 'userRole',
      width: '7%',
      ellipsis: true,
    },
    {
      title: 'Billing(%)',
      dataIndex: 'billingPercentage',
      width: '6%',
      ellipsis: true,
    },
    {
      title: 'Model',
      dataIndex: 'billingModel',
      width: '7%',
      ellipsis: true,
      hidden: !showModelColumn,
    },
    {
      title: (
        <>
          <Tooltip
            color="#ffffff"
            overlayClassName="tooltip"
            title={() => (
              <>
                <div className="color-info">
                  <span>
                    <SolidCircle radious="10" fillColor="red" />
                  </span>{' '}
                  <h4> : User specific rate</h4>
                </div>
                <div className="color-info">
                  <span>
                    <SolidCircle radious="10" fillColor="green" />
                  </span>{' '}
                  <h4> : Default rate</h4>
                </div>
              </>
            )}
          >
            Rate
          </Tooltip>
        </>
      ),
      dataIndex: 'rate',
      width: '4%',
      ellipsis: true,
      render: (rate, record) => (
        <p className={record?.defaultRateFlag ? 'green' : 'dark-red'}>{rate}</p>
      ),
    },
    {
      title: (
        <>
          <Tooltip
            color="#ffffff"
            overlayClassName="tooltip"
            title={() => (
              <>
                <p>PLB = Previous Leave balance</p>
                <p>LT = Leave Taken</p>
                <p>ULB = Used Leave balance</p>
                <p>DLB = Deducted Leave balance</p>
                <p>CLB = Current Leave balance</p>
              </>
            )}
          >
            Leaves Details
          </Tooltip>
        </>
      ),
      dataIndex: 'userLeaveData',
      width: '17%',
      ellipsis: true,
      render: (totalLeaves, record) => {
        return (
          <>
            <span>
              {totalLeaves?.previousLeaveBalance?.toFixed(2) || 0}/
              {totalLeaves?.leaveTaken?.toFixed(2) || 0}/
              {totalLeaves?.leaveUsed?.toFixed(2) || 0} /
              {totalLeaves?.leaveDeduced?.toFixed(2) || 0} /
              {totalLeaves?.currentLeaveBalance?.toFixed(2) || 0}
            </span>
            {record?.userLeaveData?.leaveDetails?.length > 0 && (
              <Button
                type="link"
                className="add-package"
                label="details"
                onClick={() => {
                  setDetailData(record)
                  setModalType('leave')
                  setShowDetailsModal(!showDetailsModal)
                }}
              />
            )}
          </>
        )
      },
    },
    {
      title: 'Billable',
      dataIndex: 'billedHours',
      ellipsis: true,
      width: '8%',
      render: (billedHours, record) => {
        return (
          <>
            <span>{billedHours}</span>
            {record?.billableTasks?.length > 0 && (
              <Button
                type="link"
                className="add-package"
                label="details"
                onClick={() => {
                  setDetailData(record)
                  setModalType('billableTasks')
                  setShowDetailsModal(!showDetailsModal)
                }}
              />
            )}
          </>
        )
      },
    },
    {
      title: 'Non Billable',
      dataIndex: 'nonBilledHours',
      ellipsis: true,
      width: '8%',
      render: (nonBilledHours, record) => {
        return (
          <>
            <span>{nonBilledHours}</span>
            {record?.nonBillableTasks?.length > 0 && (
              <Button
                type="link"
                className="add-package"
                label="details"
                onClick={() => {
                  setDetailData(record)
                  setModalType('nonBillableTasks')
                  setShowDetailsModal(!showDetailsModal)
                }}
              />
            )}
          </>
        )
      },
    },
    {
      title: 'OnTime Logs',
      dataIndex: 'onTimeHoursSum',
      ellipsis: true,
      width: '8%',
      render: (onTimeHoursSum, record) => {
        return (
          <>
            <span>{onTimeHoursSum}</span>
            {record?.onTimeLogs?.length > 0 && (
              <Button
                type="link"
                className="add-package"
                label="details"
                onClick={() => {
                  setDetailData(record)
                  setModalType('onTimeLogs')
                  setShowDetailsModal(!showDetailsModal)
                }}
              />
            )}
          </>
        )
      },
    },
    {
      title: (
        <>
          <Tooltip
            color="#ffffff"
            overlayClassName="tooltip"
            title={() => (
              <>
                <p>Ontime vs Zoho hours</p>
                <div className="color-info">
                  <span>
                    <SolidCircle radious="10" fillColor="rgb(49, 164, 51)" />
                  </span>{' '}
                  <h4>{` : -1 < difference < 1`}</h4>
                </div>
                <div className="color-info">
                  <span>
                    <SolidCircle radious="10" fillColor="orange" />
                  </span>{' '}
                  <h4>{` : -4 < difference < 4`}</h4>
                </div>
                <div className="color-info">
                  <span>
                    <SolidCircle radious="10" fillColor="rgb(229, 19, 19)" />
                  </span>{' '}
                  <h4>{` : -4 > difference > 4`}</h4>
                </div>
              </>
            )}
          >
            O vs Z
          </Tooltip>
        </>
      ),
      dataIndex: 'ontimeVsZoho',
      ellipsis: true,
      width: '5%',
      render: (ontimeVsZoho) => {
        const className = colorIntensity(ontimeVsZoho)
        return <p className={className}>{ontimeVsZoho}</p>
      },
    },
    {
      title: (
        <>
          <Tooltip
            color="#ffffff"
            overlayClassName="tooltip"
            title={() => (
              <>
                <div className="color-info">
                  <span>
                    <SolidCircle radious="10" fillColor="rgb(49, 164, 51)" />
                  </span>{' '}
                  <h4>{` : -1 < missing time < 1`}</h4>
                </div>
                <div className="color-info">
                  <span>
                    <SolidCircle radious="10" fillColor="orange" />
                  </span>{' '}
                  <h4>{` : -4 < missing time < 4`}</h4>
                </div>
                <div className="color-info">
                  <span>
                    <SolidCircle radious="10" fillColor="rgb(229, 19, 19)" />
                  </span>{' '}
                  <h4>{` : -4 > missing time > 4`}</h4>
                </div>
              </>
            )}
          >
            Missing Time
          </Tooltip>
        </>
      ),
      dataIndex: 'missingTime',
      ellipsis: true,
      width: '8%',
      render: (missingTime) => {
        const className = colorIntensity(missingTime)
        return <p className={className}>{missingTime}</p>
      },
    },
    {
      title: 'Deduction',
      dataIndex: 'userLeaveData',
      width: '7%',
      ellipsis: true,
      render: (userLeaveData) => {
        return <p>{userLeaveData?.leaveDeduced}</p>
      },
    },
    {
      title: <>Total</>,
      dataIndex: 'totalHours',
      width: '8%',
      ellipsis: true,
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      width: '8%',
    },
  ].filter((column) => !column?.hidden)

  const weeksSideBarOptions = [
    {
      label: 'Back',
      className: 'bold-text',
      icon: React.createElement(BackIcon),
      key: 'back-btn',
    },
  ]
  allWeeksVerificationData &&
    allWeeksVerificationData.forEach((item, index) => {
      weeksSideBarOptions.push({
        label: `Week ${index + 1}`,
        icon: item?.verified ? <CorrectIcon /> : <IncorrectIcon />,
        key: (index + 1).toString(),
      })
    })

  const updateCurrentWeek = async () => {
    setConfirmLoading(true)
    const currentSelectedWeekData = allWeeksVerificationData?.filter(
      (item) => item.week === Number(currentSelectedWeek)
    )[0]
    const data = todoVerificationId
      ? { verificationId: todoVerificationId, verified: true }
      : {
          ...currentSelectedWeekData,
          notes: timesheetNote,
          verified: isWeekVerified,
        }
    const response = await updateVerification(data)
    if (response?.status === STATUS.ok) {
      toastNotify(TOAST.success, response?.data?.message)
      !!todoVerificationId
        ? setShowGenerateInvoiceModal(false)
        : fetchVerificationData()
      setConfirmLoading(false)
      setShowConfirmVerifyModal(false)
    } else {
      toastNotify(
        TOAST.error,
        response?.message || TOASTIFY_MESSAGES.failedVerifyTimesheetMessage
      )
    }
  }

  return (
    <Spin spinning={isLoading} tip={'Loading week timesheet data...'}>
      <div className="week-table-wrapper">
        <div>
          <Sider width={150} className="week-select-sidebar">
            <Menu
              mode="inline"
              onClick={(item) => {
                if (item?.key === 'back-btn') {
                  todoVerificationId
                    ? setShowGenerateInvoiceModal(false)
                    : setShowWeekVerificaton(false)
                } else {
                  setCurrentSelectedWeek(item.key)
                  const newWeekVerificationData =
                    allWeeksVerificationData?.filter(
                      ({ week }) => week === Number(item.key)
                    )[0]

                  const newTimeSheetNameFormat = `${newWeekVerificationData?.verificationId}_${newWeekVerificationData?.startDate}_${newWeekVerificationData?.endDate}`
                  if (
                    verificationData[verificationNameFormat]
                      ?.weeksTimesheetData &&
                    !verificationData[verificationNameFormat]
                      ?.weeksTimesheetData[newTimeSheetNameFormat]
                  ) {
                    fetchWeekTimesheetData(
                      newWeekVerificationData?.verificationId
                    )
                  }
                }
              }}
              defaultSelectedKeys={[currentSelectedWeek.toString()]}
              items={weeksSideBarOptions}
            />
          </Sider>
        </div>
        <div className="week-timesheet-table">
          <div>
            <Row className="download-btn-row">
              <Col className="download-btn-row">
                <h3>
                  Timesheet Details
                  {` from ${
                    currentWeekTimesheetData?.invoiceGenerateDetails
                      ?.timesheetStartDate || 'Start Date'
                  }
                                 to ${
                                   currentWeekTimesheetData
                                     ?.invoiceGenerateDetails
                                     ?.timesheetEndDate || 'End Date'
                                 } `}
                </h3>
                <Tooltip
                  color="#ffffff"
                  overlayClassName="tooltip"
                  title={() =>
                    `Last Synced: ${currentWeekTimesheetData?.lastSyncedTime}`
                  }
                >
                  {!todoVerificationId && (
                    <Button
                      onClick={() => {
                        fetchWeekTimesheetData()
                      }}
                      icon={<Refresh />}
                      className="refresh-btn"
                    />
                  )}
                </Tooltip>
              </Col>
              <Col>
                <CheckBox
                  label=" Verify"
                  defaultChecked={currentWeekVerificationData?.verified}
                  onChange={(e) => setIsWeekVerified(e.target.checked)}
                />
                <Button
                  className="verify-btn"
                  label={'Save'}
                  type="primary"
                  onClick={() => setShowConfirmVerifyModal(true)}
                  disabled={disableVerificationOps}
                />
              </Col>
            </Row>
            <DataTable
              columns={weekTableColumns}
              scroll={{ y: 440 }}
              dataSource={currentWeekTimesheetData?.zohoEmployeeMetadataList}
              rowKey={(record) => record?.userId || record?.index}
              pagination={false}
              summary={(pageData) => {
                let totalBillable = 0
                let totalNonBillable = 0
                let totalOntimeHours = 0
                let totalOntimeVsZoho = 0
                let totalMissingTime = 0
                let totalHoursSum = 0
                let totalAmount = 0
                let totalDeduction = 0
                pageData.forEach(
                  ({
                    billedHours,
                    nonBilledHours,
                    onTimeHoursSum,
                    ontimeVsZoho,
                    missingTime,
                    totalHours,
                    amount,
                    userLeaveData,
                  }) => {
                    totalBillable += billedHours || 0
                    totalNonBillable += nonBilledHours || 0
                    totalOntimeHours += onTimeHoursSum || 0
                    totalOntimeVsZoho += ontimeVsZoho || 0
                    totalMissingTime += missingTime || 0
                    totalHoursSum += totalHours || 0
                    totalAmount += amount || 0
                    totalDeduction += userLeaveData?.leaveDeduced || 0
                  }
                )

                return (
                  <>
                    <Table.Summary.Row className="table-summary bold-text">
                      <Table.Summary.Cell>Total</Table.Summary.Cell>
                      <Table.Summary.Cell />
                      {showModelColumn && <Table.Summary.Cell />}
                      <Table.Summary.Cell />
                      <Table.Summary.Cell />
                      <Table.Summary.Cell />
                      <Table.Summary.Cell>
                        {totalBillable?.toFixed(2)}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell>
                        {totalNonBillable?.toFixed(2)}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell>
                        {totalOntimeHours?.toFixed(2)}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell>
                        {totalOntimeVsZoho?.toFixed(2)}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell>
                        {totalMissingTime?.toFixed(2)}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell>
                        {totalDeduction?.toFixed(2)}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell>
                        {totalHoursSum?.toFixed(2)}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell>
                        {totalAmount?.toFixed(2)}
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  </>
                )
              }}
            />
            <div className="verification-notes">
              <InputTextArea
                name="messageOnStatement"
                label="Notes:"
                type="text"
                placeholder={PLACEHOLDER_MESSAGES.timesheetNoteMessage}
                onChange={(e) => setTimesheetNote(e.target.value)}
                maxRows={1}
                existingValue={currentWeekVerificationData?.notes}
              />
            </div>
          </div>
          <Modal
            title={`Do you want to save week-${currentSelectedWeek}'s timesheet details?`}
            open={showConfirmVerifyModal}
            onOk={() => updateCurrentWeek()}
            onCancel={() => setShowConfirmVerifyModal(false)}
            okText="Yes"
            cancelText="No"
            confirmLoading={confirmLoading}
          >
            <p>
              Start Date:{' '}
              {
                currentWeekTimesheetData?.invoiceGenerateDetails
                  ?.timesheetStartDate
              }
            </p>

            <p>
              End Date:{' '}
              {
                currentWeekTimesheetData?.invoiceGenerateDetails
                  ?.timesheetEndDate
              }
            </p>
          </Modal>
        </div>
      </div>
      <DetailsModal
        showDetailsModal={showDetailsModal}
        setShowDetailsModal={setShowDetailsModal}
        modalType={modalType}
        detailData={detailData}
      ></DetailsModal>

      <EmptyBillingWarningModal
        isEmptyBillingModalOpen={isEmptyBillingModalOpen}
        setIsEmptyBillingModalOpen={setIsEmptyBillingModalOpen}
        emptyBillingModelList={emptyBillingModelList}
        projectId={projectId}
        history={history}
     />
    </Spin>
  )
}
export default WeeksVerification
