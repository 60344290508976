import { createSlice } from '@reduxjs/toolkit'
import { resetAllReduxStates } from './resetAllReduxStatesAction'
const initialState = {
  providerList: [],
}
const providerListSlice = createSlice({
  name: 'providerList',
  initialState,
  reducers: {
    setProviderList: (state, { payload }) => {
      state.providerList = payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetAllReduxStates, () => initialState)
  },
})

export const { setProviderList } = providerListSlice.actions

export const providerListSelector = (state) => state.providerList

export default providerListSlice.reducer
