import {
  AutoComplete,
  Avatar,
  Dropdown,
  Input,
  Layout,
  Menu,
  Select,
  Space,
  Tooltip,
} from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import useReactRouter from 'use-react-router'
import { TypographyText } from '..'
import { ReactComponent as ServerLogs } from '../../assets/icons/activity.svg'
import { ReactComponent as Bellicon } from '../../assets/icons/bell-icon.svg'
import { ReactComponent as EyeOutlined } from '../../assets/icons/changepassword.svg'
import { ReactComponent as Dropicon } from '../../assets/icons/drop-arrow.svg'
import { ReactComponent as EditProfile } from '../../assets/icons/editprofile.svg'
import { ReactComponent as ProjectsIcon } from '../../assets/icons/jobIcon.svg'
import { ReactComponent as LogoutIcon } from '../../assets/icons/log-out.svg'
import { ReactComponent as Logo } from '../../assets/icons/page-logo.svg'
import { ReactComponent as SearchIcon } from '../../assets/icons/searchicon.svg'
import { ReactComponent as SettingIcon } from '../../assets/icons/setting.svg'
import { ReactComponent as Holiday } from '../../assets/icons/holiday.svg'
import { ReactComponent as Identity } from '../../assets/icons/identity.svg'
import { images } from '../../config/images'
import { projectDetailsSelector } from '../../redux/reducer/getProjectsByIdSlice'
import { AuthContext } from '../../store/auth'
import {
  headerItemLabels,
  noHeaderOptions,
} from '../../utils/constants/constant'
import {
  getHeaderOptions,
  validateRequiredFieldsForPredictions,
} from '../../utils/helperFunctions/helperFunctions'
import Button from '../Button/Button'
import ChangePassword from '../ChangePasswordModule/ChangePasswordModule'
import EditProfileModule from '../EditProfileModule/EditProfileModule'
import CustomizeModal from '../Modal/Modal'
import ReceivedInvoice from '../ReceivedInvoice/ReceivedInvoice'
import SearchBar from '../SearchBar/SearchBar'
import './Header.scss'
import GroupSelectField from '../GroupSelectFIeld/GroupSelectField'

const { Header } = Layout

function AppHeader() {
  const [receivedInvoice, setReceiveInvoice] = useState(false)
  const [showHeaderOptions, setShowHeaderOptions] = useState(true)
  const [editProfile, setEditProfile] = useState(false)
  const [changePassword, setChangePassword] = useState(false)
  const [ShowsearchField, setShowSearchField] = useState(false)
  const [options, setOptions] = useState([])
  const { projectId } = useSelector((state) => state.projectId)
  const { userRole, logout } = useContext(AuthContext)
  const { projectDetails } = useSelector(projectDetailsSelector)
  const { missingFields, validationFields } =
    validateRequiredFieldsForPredictions(projectDetails)
  const { location } = useReactRouter()
  const searchParams = new URLSearchParams(location.search)
  const headerSelectedItem =
    location.state?.headerSelectedItem || searchParams.get('headerSelectedItem')
  const [items] = useState([
    'Omneship',
    'Invoicing System',
    'Hopscoach',
    'TagB',
    'Innovation',
    'Parking',
    'jakes app',
    'Fisher Shipping',
    'Nas',
    'Prev app',
  ])

  const commonHeaders = [
    {
      label: headerItemLabels.PROJECT_DETAILS,
      path: `/projectdetails/${projectId}`,
      hide: false,
      key: headerItemLabels.PROJECT_DETAILS,
    },
    {
      label: headerItemLabels.RATE_DETAILS,
      path: `/ratedetails/${projectId}`,
      hide: false,
      key: headerItemLabels.RATE_DETAILS,
    },
    {
      label: headerItemLabels.LEAVE_BALANCE,
      path: `/developers/${projectId}`,
      hide: false,
      key: headerItemLabels.LEAVE_BALANCE,
    },

    {
      label: Object.values(validationFields).includes('-') ? (
        <Tooltip
          title={`Please add ${missingFields} in project to add prediction or invoice`}
        >
          <TypographyText
            text={headerItemLabels.PREDICTION_AND_PENDING_INVOICES}
            disabled={true}
          />
        </Tooltip>
      ) : (
        headerItemLabels.PREDICTION_AND_PENDING_INVOICES
      ),

      path: `/predictiondetails/${projectId}`,
      hide: false,
      key: headerItemLabels.PREDICTION_AND_PENDING_INVOICES,
    },
    {
      label: headerItemLabels.CLIENT_DETAILS,
      path: `/clientdetails/${projectId}`,
      hide: false,
      key: headerItemLabels.CLIENT_DETAILS,
    },
    {
      label: headerItemLabels.PROJECT_NOTES,
      path: `/projectnotes/${projectId}`,
      hide: false,
      key: headerItemLabels.PROJECT_NOTES,
    },
  ].filter((header) => !header.hide)
  const getRandomInt = (max, min = 0) =>
    Math.floor(Math.random() * (max - min + 1)) + min

  const searchResult = (query) =>
    new Array(getRandomInt(5))
      .join('.')
      .split('.')
      .map((_, idx) => {
        const category = `${query}${idx}`
        return {
          value: category,
          label: (
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <span>{query}</span>
              <span>{getRandomInt(200, 100)} results</span>
            </div>
          ),
        }
      })
  const handleSearch = (value) => {
    setOptions(value ? searchResult(value) : [])
  }
  const history = useHistory()

  const openReceivedInvoiceModule = () => {
    setReceiveInvoice(!receivedInvoice)
  }
  const openChangePasswordModule = () => {
    setChangePassword(!changePassword)
  }
  const openEditProfileMOdule = () => {
    setEditProfile(!editProfile)
  }
  const openSearchModule = () => {
    setShowSearchField(true)
  }
  const onClose = () => {
    setReceiveInvoice(false)
    setEditProfile(false)
    setChangePassword(false)
  }

  const onMenuItemClick = (event) => {
    const path = event.item.props.path
    const key = event.key
    const currentState = history.location.state || {}

    if (path.includes('/predictiondetails') && missingFields.length === 0) {
      history.push({
        pathname: path,
        state: {
          ...currentState,
          headerSelectedItem: key,
        },
      })
    } else if (!path.includes('/predictiondetails')) {
      history.push({
        pathname: path,
        state: {
          ...currentState,
          headerSelectedItem: key,
        },
      })
    }
  }
  const menu = (
    <Menu
      items={[
        {
          label: (
            <div onClick={openEditProfileMOdule} className="logout-menu-item">
              <span className="dropdown-icons">
                <EditProfile />
              </span>
              Edit Profile
            </div>
          ),
          key: '0',
        },
        {
          label: (
            <div
              onClick={openChangePasswordModule}
              className="logout-menu-item"
            >
              <span className="dropdown-icons">
                <EyeOutlined />
              </span>
              Change Password
            </div>
          ),
          key: '1',
        },
        {
          label: (
            <div className="logout-menu-item">
              <span className="dropdown-icons">
                <SettingIcon />
              </span>
              Setting
            </div>
          ),
          key: '2',
          children:
            userRole === 'ROLE_ADMIN'
              ? [
                  {
                    key: 's1',
                    label: (
                      <div
                        onClick={() => history.push('/settings/jobScheduler')}
                        className="logout-menu-item"
                      >
                        <span className="dropdown-icons">
                          <ProjectsIcon />
                        </span>
                        Jobs
                      </div>
                    ),
                  },
                  {
                    key: 's2',
                    label: (
                      <div
                        onClick={() => history.push('/settings/serverLogs')}
                        className="logout-menu-item"
                      >
                        <span className="dropdown-icons">
                          <ServerLogs />
                        </span>
                        Server Logs
                      </div>
                    ),
                  },
                  {
                    key: 's3',
                    label: (
                      <div
                        onClick={() => history.push('/settings/holidays')}
                        className="logout-menu-item"
                      >
                        <span className="dropdown-icons">
                          <Holiday />
                        </span>
                        Holidays
                      </div>
                    ),
                  },
                  {
                    key: 's4',
                    label: (
                      <div
                        onClick={() =>
                          history.push('/settings/identityProvider')
                        }
                        className="logout-menu-item"
                      >
                        <span className="dropdown-icons">
                          <Identity />
                        </span>
                        Identity Provider
                      </div>
                    ),
                  },
                ]
              : [
                  {
                    key: 's2',
                    label: (
                      <div
                        onClick={() => history.push('/settings/serverLogs')}
                        className="logout-menu-item"
                      >
                        <span className="dropdown-icons">
                          <ServerLogs />
                        </span>
                        Server Logs
                      </div>
                    ),
                  },
                ],
        },
        {
          label: (
            <div onClick={logout} className="logout-menu-item">
              <span className="dropdown-icons">
                <LogoutIcon />
              </span>
              Log Out
            </div>
          ),
          key: '3',
        },
      ]}
    />
  )
  useEffect(() => {
    if (
      noHeaderOptions.includes(location?.pathname) ||
      location?.pathname.includes('companydetails') ||
      location?.pathname.includes('assignedprojects') ||
      location?.pathname.includes('techstack') ||
      location?.pathname.includes('/salesPersons/incentive')
    ) {
      setShowHeaderOptions(false)
    }
  }, [location.pathname])

  return (
    <Layout className="header-wrap">
      <Header className="header">
        <div className="page-header">
          <div className="logo">
            <a href="/">
              <Logo />
            </a>
          </div>
          <div className="page-nav">
            <Menu
              onSelect={onMenuItemClick}
              theme="light"
              mode="horizontal"
              defaultSelectedKeys={[
                headerSelectedItem || headerItemLabels.PROJECT_DETAILS,
              ]}
              items={showHeaderOptions ? getHeaderOptions(commonHeaders) : null}
            />
          </div>
          <div className="page-action">
            <ul className="list-items">
              <li>
                <Button
                  onClick={openReceivedInvoiceModule}
                  danger
                  label={'Received Invoices'}
                  className="generate-invoice-btn"
                />
              </li>
              {userRole === 'ROLE_ADMIN' && (
                <li>
                  <GroupSelectField />
                </li>
              )}
              <li>
                <Button
                  className="search-btn"
                  onClick={openSearchModule}
                  icon={<SearchIcon />}
                />
              </li>
              <li className="bell-icon">
                <Bellicon />
                <span className="notifi-dot"></span>
              </li>
              <li>
                <Dropdown
                  overlay={menu}
                  getPopupContainer={(triggerNode) => triggerNode.parentElement}
                  trigger={['click']}
                  autoFocus={true}
                >
                  <a href="/" onClick={(e) => e.preventDefault()}>
                    <Space>
                      <div className="profile-icon">
                        <Avatar src={images.profile} size={40} />
                      </div>
                      <div className="drop-icon">
                        <Dropicon />
                      </div>
                    </Space>
                  </a>
                </Dropdown>
              </li>
            </ul>
          </div>
        </div>
      </Header>
      <ReceivedInvoice open={receivedInvoice} onClose={onClose} />
      <ChangePassword open={changePassword} onClose={onClose} />
      <EditProfileModule open={editProfile} onClose={onClose} />
      <CustomizeModal
        open={ShowsearchField}
        setShowSearchField={setShowSearchField}
        footer={null}
        size={1000}
        closable={true}
      >
        <Input.Group compact className="padding-10">
          <AutoComplete
            dropdownMatchSelectWidth={1000}
            options={options}
            onSearch={handleSearch}
            popupClassName="search-menu"
            className="search-popup-text"
          >
            <Input.Search placeholder="Type here to start your search" />
          </AutoComplete>
          <Select
            style={{ width: 300 }}
            dropdownMatchSelectWidth={250}
            className="search-project-bar"
            placeholder="All Project"
            popupClassName="select-menu"
            getPopupContainer={(triggerNode) => triggerNode.parentElement}
            dropdownRender={(menu) => (
              <>
                <SearchBar placeholder={'Search'} searchP="search-project" />
                {menu}
              </>
            )}
          >
            <Select.Option key={'all-projects'}>All Project</Select.Option>
            {items.map((item) => (
              <Select.Option key={item}>{item}</Select.Option>
            ))}
          </Select>
        </Input.Group>
      </CustomizeModal>
    </Layout>
  )
}
export default AppHeader
