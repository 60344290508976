import React from 'react'

import './EmptyBillingWarningModal.scss'
import CustomizeModal from '../Modal/Modal'
import { headerItemLabels } from '../../utils/constants/constant'
import Button from '../Button/Button'
import { Link } from 'react-router-dom'

const EmptyBillingWarningModal = ({
  isEmptyBillingModalOpen,
  setIsEmptyBillingModalOpen,
  emptyBillingModelList,
  projectId,
  history,
}) => (
  <CustomizeModal
    open={isEmptyBillingModalOpen}
    title={'Attention !'}
    centered={true}
    handleModalCancel={() => {
      setIsEmptyBillingModalOpen(false)
    }}
    footer={null}
    className="emptyBillingWarningModal"
  >
    <div className="value-container">
      <div>
        <p>
          The following employees have not been assigned a billing model. To
          ensure accurate employee data, please assign a billing model under the{' '}
          <Link
            to={{
              pathname: `/developers/${projectId}?headerSelectedItem=${headerItemLabels.LEAVE_BALANCE}`,
              // state: { headerSelectedItem: headerItemLabels.LEAVE_BALANCE },

            }}
            target="_blank"
          >
            <Button
              label="Developers"
            />
          </Link>
          tab and reload timesheet.
        </p>
        <br />
        <ol>
          {emptyBillingModelList?.map((employee) => {
            return <li>{employee?.employeeName}</li>
          })}
        </ol>
      </div>
    </div>
  </CustomizeModal>
)

export default EmptyBillingWarningModal
