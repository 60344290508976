import React, { useState } from 'react'
import { Checkbox, Form, Radio } from 'antd'
import { useSelector } from 'react-redux'
import { saveAs } from 'file-saver'

import './DownloadTimeSheetModal.scss'
import CustomizeModal from '../Modal/Modal'
import {
  STATUS,
  TOAST,
  defaultDownloadTimesheetColumnsOption,
  downloadTimesheetColumnsOption,
  downloadTimesheetMergeByDateOptions,
} from '../../utils/constants/constant'
import { downloadTimesheet } from '../../utils/api/predictionAndInvoiceServices'
import { generateInvoiceSelector } from '../../redux/reducer/generateInvoiceSlice'
import { TOASTIFY_MESSAGES } from '../../utils/constants/messages'
import { toastNotify } from '../../utils/helperFunctions/toastNotify'

const DownloadTimeSheetModal = ({
  isDownloadModalOpen,
  setIsDownloadModalOpen,
  timesheetStartDate,
  timesheetEndDate,
  zohoProjectId,
}) => {
  const [formDisabled, setFormDisabled] = useState(false)
  const { timeSheetName } = useSelector(generateInvoiceSelector)
  const [downloadTimesheetFormInstance] = Form.useForm()
  const handleModalCancel = () => {
    downloadTimesheetFormInstance.resetFields()
    setIsDownloadModalOpen(false)
  }
  const DownloadTimeSheetSubmit = async (values) => {
    setFormDisabled(true)
    const timeSheetDataFormat = {
      zohoProjectId,
      timesheetStartDate,
      timesheetEndDate,
      columnNames: values.columnNames,
      mergeByDate: values.mergeByDate,
    }

    const response = await downloadTimesheet(timeSheetDataFormat)
    if (response?.status === STATUS.ok) {
      const blob = new Blob([response?.data], {
        type: 'text/csv;charset=utf-8',
      })
      saveAs(blob, timeSheetName)
      setIsDownloadModalOpen(false)
      setFormDisabled(false)
    } else {
      toastNotify(TOAST.error, TOASTIFY_MESSAGES.failedDownloadMessage)
    }
  }
  return (
    <CustomizeModal
      open={isDownloadModalOpen}
      title={'Download Timesheet'}
      centered={true}
      okText={'Save'}
      cancelText={'Cancel'}
      handleModalCancel={handleModalCancel}
      closable={true}
      handleModalOk={() => downloadTimesheetFormInstance.submit()}
      okButtonProps={{ disabled: formDisabled }}
      cancelButtonProps={{ disabled: formDisabled, danger: true }}
      className="download-timesheet-modal"
    >
      <div className="value-container">
        <Form
          form={downloadTimesheetFormInstance}
          onFinish={(values) => DownloadTimeSheetSubmit(values)}
          disabled={formDisabled}
        >
          <div className="container-item">
            <h3>Columns Name:</h3>
            <Form.Item
              name="columnNames"
              initialValue={defaultDownloadTimesheetColumnsOption}
            >
              <Checkbox.Group options={downloadTimesheetColumnsOption} />
            </Form.Item>
          </div>
          <div className="container-item">
            <h3>Merge By:</h3>
            <Form.Item name="mergeByDate" initialValue={false}>
              <Radio.Group options={downloadTimesheetMergeByDateOptions} />
            </Form.Item>
          </div>
        </Form>
      </div>
    </CustomizeModal>
  )
}

export default DownloadTimeSheetModal
