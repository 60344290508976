import { Col, Form, Modal, Row, Switch } from 'antd'
import React, { useState } from 'react'
import { DateField, InputField, SelectField } from '..'
import {
  invoiceTemplateField,
  invoiceTemplateOptions,
} from '../../utils/constants/constant'
import {
  PLACEHOLDER_MESSAGES,
  VALIDATION_MESSAGES,
} from '../../utils/constants/messages'
import {
  invoiceCycleRule,
  invoiceTemplateRule,
  leaveTermRule,
} from '../../utils/constants/validationRules'

const BillingPanel = ({
  invoiceCycleOptions,
  projectData,
  payModelOptions,
  accTypeOptions,
  onDateChange,
  prjStartDate,
  isShowFields,
}) => {
  const [showWarningModal, setShowWarningModal] = useState(false)
  const [isEnableNotification, setIsEnableNotification] = useState(
    projectData?.enableInvoiceOverdueNotification
  )
  return (
    <>
      <Row gutter={40}>
        <Col lg={12} md={12} sm={12}>
          <SelectField
            name="cycle"
            label="Invoice Cycle"
            options={invoiceCycleOptions}
            existingValue={
              projectData?.cycle === '-' ? null : projectData?.cycle
            }
            type="text"
            placeholder={PLACEHOLDER_MESSAGES.invoiceCycleMessage}
            required
            rules={invoiceCycleRule}
          />
        </Col>
        <Col lg={12} md={12} sm={12}>
          <InputField
            name="invoiceTerm"
            label="Invoice Term"
            placeholder={PLACEHOLDER_MESSAGES.invoiceTermMessage}
            type="number"
            existingValue={projectData?.invoiceTerm}
            disabled={true}
          />
        </Col>

        <Col lg={12} md={12} sm={12}>
          <SelectField
            name="payModel"
            label="Pay Model"
            options={payModelOptions}
            existingValue={
              projectData?.payModel === '-' ? null : projectData?.payModel
            }
            placeholder={PLACEHOLDER_MESSAGES.payModelMessage}
            type="text"
            required
            rules={[
              {
                required: true,
                message: VALIDATION_MESSAGES.payModelMessage,
              },
            ]}
          />
        </Col>
        <Col lg={12} md={12} sm={12}>
          <SelectField
            name="accType"
            label="Acc Type"
            options={accTypeOptions}
            existingValue={
              projectData?.accType === '-' ? null : projectData?.accType
            }
            placeholder={PLACEHOLDER_MESSAGES.accountTypeMessage}
            type="text"
            required
            rules={[
              {
                required: true,
                message: VALIDATION_MESSAGES.accountTypeMessage,
              },
            ]}
          />
        </Col>
        <Col lg={12} md={12} sm={12}>
          <InputField
            name="leaveTerm"
            label="Leave Term"
            placeholder={PLACEHOLDER_MESSAGES.leaveTermMessage}
            type="number"
            existingValue={projectData?.leaveTerm}
            required
            rules={leaveTermRule}
            step={0.01}
          />
        </Col>
        {isShowFields.invoiceTemplate && (
          <Col lg={12} md={12} sm={12}>
            <SelectField
              name="invoiceTemplate"
              label="Invoice Template"
              options={invoiceTemplateOptions}
              isFeatured={true}
              existingValue={
                projectData?.invoiceTemplate === undefined
                  ? invoiceTemplateField.TECHWISE
                  : projectData?.invoiceTemplate
              }
              defaultValue={
                projectData?.invoiceTemplate === undefined
                  ? invoiceTemplateField.TECHWISE
                  : projectData?.invoiceTemplate
              }
              placeholder={PLACEHOLDER_MESSAGES.invoiceTemplateMessage}
              required
              type="text"
              rules={invoiceTemplateRule}
            />
          </Col>
        )}

        <Col lg={12} md={12} sm={12}>
          <DateField
            onDateChange={onDateChange}
            name="projectStartDate"
            label="Project Start Date"
            type="text"
            placeholder={PLACEHOLDER_MESSAGES.projectStartDateMessage}
            existingValue={projectData?.projectStartDate}
            valuePropName={'date'}
            value={prjStartDate}
            required
            rules={[
              {
                required: true,
                message: VALIDATION_MESSAGES.projectStartDateMessage,
              },
            ]}
          />
        </Col>
        <Col lg={12} md={12} sm={12}>
          <Form.Item
            label={'Invoice Overdue  Email Notification'}
            name={'enableInvoiceOverdueNotification'}
            initialValue={projectData?.enableInvoiceOverdueNotification}
          >
            <Switch
              checkedChildren={'Enable'}
              unCheckedChildren={'Disable'}
              onChange={() => setShowWarningModal(true)}
              checked={isEnableNotification}
            />
          </Form.Item>
        </Col>
      </Row>
      <Modal
        title={`Do you want to ${
          isEnableNotification ? 'disable' : 'enable'
        } invoice overdue notification ?`}
        open={showWarningModal}
        onOk={() => {
          setIsEnableNotification(!isEnableNotification)
          setShowWarningModal(false)
        }}
        onCancel={() => {
          setShowWarningModal(false)
        }}
        okText="Yes"
        cancelText="No"
      />
    </>
  )
}

export default BillingPanel
