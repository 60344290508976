import React from 'react'
import './NoContent.scss'
import { images } from '../../config/images'
const NoContent = ({ children }) => (
  <div className="no-content">
    <div>
      <img src={images.Empty} alt="" width={200} height={200} />
      <h1>Oops !</h1>
      {children}
    </div>
  </div>
)
export default NoContent
