import React from 'react'
import { Popover } from 'antd'
import { ReactComponent as AddIcon } from '../../assets/icons/plus.svg'
import './InvoiceTimesheet.scss'
import Button from '../Button/Button'

const AddNewRowPopover = ({
    addNewDeveloperHandler,
    addNewItemHandler,
    disabled,
    open,
    setOpen
}) => {
    const content = (
        <div className="timesheet-action">
            <Button
                danger
                label="Add Developer"
                onClick={() => {

                    addNewDeveloperHandler(true)
                }}
            />
            <Button
                danger
                label={'Add Item'}
                onClick={() => addNewItemHandler()}
            />
        </div>
    )
    return (
        <Popover placement="bottomLeft" open={open} content={content} trigger="click">
            <Button danger label="Add New Row" icon={<AddIcon />} disabled={disabled} onClick={() => setOpen((prevState) => !prevState)} />
        </Popover>
    )
}

export default AddNewRowPopover
