import { API_END_POINTS } from '../constants/apiEndPointConstants'
import axios from 'axios'

export const authenticate = async (email, password) => {
  const URL = API_END_POINTS.authenticate
  try {
    const response = await axios.post(URL, { email, password })
    return response
  } catch (error) {
    return error?.response
  }
}

export const getQrUrl = async (email) => {
  const URL = `${API_END_POINTS.qrCode}?email=${email}`
  try {
    const response = await axios.get(URL)
    return response?.data
  } catch (err) {
    return err.response
  }
}

export const getOtpAutheticate = async (code, email) => {
  const URL = API_END_POINTS.gAuthenticate

  try {
    const response = await axios.post(URL, { code, email })
    return response
  } catch (err) {
    console.log('error while Getting QR url', err)
  }
}

export const logoutService = async () => {
  const URL = API_END_POINTS.logout
  try {
    const response = await axios.post(URL)
    return response
  }
  catch (err) {
    return err?.response
  }
}