import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

const AuthVerify = ({ logout }) => {
  const history = useHistory()
  useEffect(() => {
    const unlisten = history.listen(() => {
      const currentUserData = JSON.parse(localStorage.getItem('userData'))
      if (currentUserData?.token) {
        const expiryDate = new Date(currentUserData?.tokenExpiry)
        if (expiryDate.getTime() < Date.now()) {
          logout()
        }
      }
    })
    return () => {
      unlisten()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history])

  return null
}

export default AuthVerify
