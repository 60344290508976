import React, { useEffect } from 'react'
import './BarCharts.scss'
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'
ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip
)
const BarCharts = () => {
  useEffect(() => {}, [])
  return (
    <div className="barchart-wrap">
      <Bar
        type="bar"
        width={370}
        height={300}
        data={{
          labels: ['Courtney', 'Leslie', 'Coddy', 'Diana', 'Jennie'],
          datasets: [
            {
              data: [100, 150, 500, 600, 400],
              borderWidth: 0,
              borderRadius: 100,
              barThickness: 30,
              backgroundColor: ({ chart: { ctx } }) => {
                const my_gradient = ctx.createLinearGradient(0, 0, 0, 400)
                my_gradient.addColorStop(0, '#761DAC')
                my_gradient.addColorStop(1, '#D693FF ')
                ctx.fillStyle = my_gradient
                ctx.fillRect(0, 150, 150, 100)
              },
              grouped: false,
            },
          ],
        }}
        options={{
          responsive: true,
          scales: {
            y: {
              min: 0,
              max: 600,
              ticks: {
                beginAtZero: true,
                maxTicksLimit: 6,
                stepSize: 150,
                callback(n) {
                  if (n < 1e3) return n
                  if (n >= 1e3) return +(n / 1e3).toFixed(1)
                },
              },
              grid: {
                display: false,
              },
            },
            x: {
              beginAtZero: true,
              grid: {
                display: false,
              },
            },
          },
          plugins: {
            legend: {
              display: false,
            },
          },
        }}
      />
    </div>
  )
}

export default BarCharts
