import React from 'react'

import { NO } from '../../utils/constants/constant'

const Badge = ({ status }) => {
   const statusClassMap = {
     Pending: 'danger-bg',
     Closed: 'danger-bg',
     [NO]: 'danger-bg',
     'Partial Received': 'warning-bg',
     Overdue:'due-bg',
     default: 'success-bg',
   }

   const className = `badge ${statusClassMap[status] || statusClassMap.default}`
  return (
    <div className="badge-wrap">
      <span
        className={className}
      >
        {status}
      </span>
    </div>
  )
}

export default Badge
