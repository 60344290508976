import React from 'react'
import './SelectField.scss'
import { Select, Form } from 'antd'
import { ReactComponent as SelectDropdownIcon } from '../../assets/icons/selectcheck.svg'

const SelectField = ({
  label,
  options,
  placeholder,
  disabled,
  showSearch,
  filterOption,
  filterSort,
  dropdownMatchSelectWidth,
  existingValue,
  defaultValue,
  value,
  optionFilterProp,
  name,
  rules,
  onSelect,
  onSearch,
  dropdownRender,
  getPopupContainer,
  onChange,
  onFocus,
  isFeatured,
  required,
  mode,
  allowClear,
  onDeselect,
  className,
  optionLabelProp,
  tagRender,
}) => {
  return (
    <Form.Item
      label={label}
      className={`select-field text-field ${className}`}
      name={name}
      initialValue={existingValue}
      rules={rules}
      required={required}
    >
      <Select
        showSearch={showSearch}
        disabled={disabled}
        onChange={onChange}
        optionFilterProp={optionFilterProp}
        placeholder={placeholder}
        value={value}
        menuItemSelectedIcon={<SelectDropdownIcon />}
        getPopupContainer={(triggerNode) =>
          getPopupContainer === false
            ? document.body
            : triggerNode.parentElement
        }
        filterOption={filterOption}
        filterSort={filterSort}
        defaultValue={defaultValue}
        name={name}
        onSelect={onSelect}
        onSearch={onSearch}
        allowClear={allowClear}
        dropdownMatchSelectWidth={dropdownMatchSelectWidth}
        dropdownRender={dropdownRender}
        onFocus={onFocus}
        mode={mode}
        onDeselect={onDeselect}
        optionLabelProp={optionLabelProp}
        tagRender={tagRender}
      >
        {options?.map((item, index) => {
          if (isFeatured) {
            return (
              <Select.Option value={item.value} key={index}>
                {item.label}
              </Select.Option>
            )
          }

          return (
            <Select.Option value={item} key={index}>
              {item}
            </Select.Option>
          )
        })}
      </Select>
    </Form.Item>
  )
}

export default SelectField
