import { createSlice } from '@reduxjs/toolkit'
import { resetAllReduxStates } from './resetAllReduxStatesAction'
const initialState = {
  loading: false,
  hasErrors: false,
  predictionDetails: null,
}
const getPredictionsByIdSlice = createSlice({
  name: 'predictionById',
  initialState,
  reducers: {
    getPredictionDetails: (state) => {
      state.loading = true
    },
    getPredictionDetailsSuccess: (state, { payload }) => {
      state.predictionDetails = payload
      state.loading = false
      state.hasErrors = false
    },
    getPredictionDetailsFailure: (state) => {
      state.loading = false
      state.hasErrors = true
      state.predictionDetails = {}
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetAllReduxStates, () => initialState)
  },
})

// Three actions generated from the slice
export const {
  getPredictionDetails,
  getPredictionDetailsSuccess,
  getPredictionDetailsFailure,
} = getPredictionsByIdSlice.actions

// A selector
export const predictionDetailsSelector = (state) => state.predictionById

// The reducer
export default getPredictionsByIdSlice.reducer
