import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Col, Form, InputNumber, Row, Select, Spin, Table, Tooltip } from 'antd'

import './InvoiceBooks.scss'
import {
  getPredictionDetailsById,
  saveInvoice,
  updateInvoice,
} from '../../utils/api/predictionAndInvoiceServices'
import {
  PLACEHOLDER_MESSAGES,
  TOASTIFY_MESSAGES,
} from '../../utils/constants/messages'
import AutoFieldComponent from '../AutoComplete/AutoComplete'
import VerifiedIcon from '../../assets/icons/VerifiedIcon'
import Button from '../Button/Button'
import DataTable from '../DataTable/DataTable'
import InputTextArea from '../InputTextArea/InputTextArea'
import CustomizeModal from '../Modal/Modal'
import { toastNotify } from '../../utils/helperFunctions/toastNotify'
import {
  PREPAID,
  STATUS,
  TOAST,
  defaultbooksRow,
  projectModel,
  serviceOptions,
} from '../../utils/constants/constant'
import { ReactComponent as AddIcon } from '../../assets/icons/plus.svg'
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete.svg'
import {
  setTimeSheetsData,
  timeSheetsDataSelector,
} from '../../redux/reducer/timeSheetsDataSlice'
import { messageOnInvoiceStatementRule } from '../../utils/constants/validationRules'
import { projectDetailsSelector } from '../../redux/reducer/getProjectsByIdSlice'
import EditableDescriptionCell from '../EditableDescriptionCell/EditableDescriptionCell'
import InputField from '../InputField/InputField'

const InvoiceBooks = ({
  invoiceBooksData = [],
  projectData,
  setShowGenerateInvoiceModal,
  isFormattingTimesheet,
  currentTimsheetNameFormat,
  currentInvoiceTimesheetData,
  fetchRevenueList,
  isCustomInvoice,
  setInvoiceBooksData,
  isTMretainerPrepaidModel,
}) => {
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const [isFormDisabled, setIsFormDisabled] = useState(false)
  const [exchangeRateDetails, setExchangeRateDetails] = useState({})
  const [redirectDetails, setRedirectDetails] = useState('')
  const [isLinkModalOpen, setIsLinkModalOpen] = useState(false)
  const [modifyInBooks, setModifyInBooks] = useState(false)
  const [invoiceAmount, setInvoiceAmount] = useState()
  const { timeSheetsData } = useSelector(timeSheetsDataSelector)
  const { projectDetails } = useSelector(projectDetailsSelector)

  const isViewInvoiceMode = currentTimsheetNameFormat?.includes('view')
  const isViewTaxableMode =
    currentInvoiceTimesheetData?.invoiceGenerateDetails?.taxDetails?.taxable
  const globalTaxSetting =
    currentInvoiceTimesheetData?.invoiceGenerateDetails?.taxDetails
      ?.globalTaxSetting

  const handleFieldChange = (value, field, fieldIndex) => {
    const newInvoiceBooksData = invoiceBooksData?.map((data, index) => {
      if (index === fieldIndex) {
        return {
          ...data,
          [field]: value,
        }
      } else {
        return { ...data }
      }
    })
    setInvoiceBooksData(newInvoiceBooksData)
  }

  const columns = [
    {
      title: 'Product/Service',
      dataIndex: 'name',
      ellipsis: true,
      render: (service) =>
        isCustomInvoice ? (
          <Select
            defaultValue={defaultbooksRow?.name}
            options={serviceOptions}
          />
        ) : (
          service
        ),
    },
    {
      title: 'Description',
      dataIndex: 'description',
      ellipsis: true,
      height: 'auto',
      width: '35%',
      render: (description, _, index) => (
        <EditableDescriptionCell
          description={description}
          index={index}
          handleFieldChange={handleFieldChange}
        />
      ),
    },
    {
      title: 'Qty',
      dataIndex: 'quantity',
      ellipsis: true,
      render: (quantity, _, index) =>
        isCustomInvoice || isTMretainerPrepaidModel ? (
          <InputNumber
            controls={false}
            precision={2}
            value={quantity}
            onChange={(value) => handleFieldChange(value, 'quantity', index)}
          />
        ) : (
          <p>{quantity?.toFixed(2)}</p>
        ),
    },
    {
      title: 'Rate',
      dataIndex: 'rate',
      ellipsis: true,
      render: (rate, _, index) =>
        isCustomInvoice ? (
          <InputNumber
            controls={false}
            precision={2}
            value={rate}
            onChange={(value) => handleFieldChange(value, 'rate', index)}
          />
        ) : (
          <p>{typeof rate === 'number' && rate?.toFixed(2)}</p>
        ),
    },
    {
      title: 'Tax',
      dataIndex: 'taxName',
      ellipsis: true,
      hidden: !isViewTaxableMode,
    },
    {
      title: 'Tax Amount',
      dataIndex: 'taxAmount',
      ellipsis: true,
      hidden: !isViewTaxableMode,
    },
    {
      title: (
        <Tooltip
          color="#ffffff"
          overlayClassName="tooltip"
          title={() => <p>(Rate × Quantity) + Tax</p>}
        >
          Amount
        </Tooltip>
      ),
      render: (_, record) => {
        return record?.quantity * record?.rate + record?.taxAmount
      },
    },
    {
      title: 'Action',
      dataIndex: 'action',
      ellipsis: true,
      width: '7%',
      hidden: !isCustomInvoice,
      render: (_, record, index) => (
        <Button
          title={'Delete Service'}
          icon={<DeleteIcon />}
          onClick={() => deleteRowHandler(record, index)}
        />
      ),
    },
  ].filter((column) => !column.hidden)

  const saveNewInvoice = async (modifyInBooks) => {
    setIsFormDisabled(true)
    const customerMemo = form.getFieldValue('messageOnInvoice')
    const privateNote = form.getFieldValue('messageOnStatement')
    const updatedInoviceGenerateDetails = {
      ...currentInvoiceTimesheetData?.invoiceGenerateDetails,
      exchangeRate:
        exchangeRateDetails?.exchangeRate ||
        currentInvoiceTimesheetData?.invoiceGenerateDetails?.exchangeRate,
      customerMemo,
      privateNote,
      invoiceAmount,
      modifyInBooks,
    }

    const response = isViewInvoiceMode
      ? await updateInvoice(
          currentInvoiceTimesheetData?.zohoEmployeeMetadataList,
          updatedInoviceGenerateDetails,
          invoiceBooksData
        )
      : await saveInvoice(
          currentInvoiceTimesheetData?.zohoEmployeeMetadataList,
          updatedInoviceGenerateDetails,
          invoiceBooksData
        )
    if (response?.status === STATUS.ok) {
      dispatch(getPredictionDetailsById(projectData?.projectId))
      toastNotify(TOAST.success, response?.data?.message)
      response?.data?.data?.redirectUrl !== '' && setIsLinkModalOpen(true)
      setRedirectDetails(response?.data?.data)
      setShowGenerateInvoiceModal(false)
      const newTimeSheetsData = { ...timeSheetsData }
      delete newTimeSheetsData[currentTimsheetNameFormat]
      dispatch(setTimeSheetsData(newTimeSheetsData))
      fetchRevenueList && fetchRevenueList()
      setIsFormDisabled(false)
    } else {
      toastNotify(
        TOAST.error,
        response?.data?.message ?? TOASTIFY_MESSAGES.somethingWentWrongMessage
      )
      setIsFormDisabled(false)
    }
  }
  const addNewDeveloperHandler = () => {
    const newInvoiceBooksData = [...invoiceBooksData]
    newInvoiceBooksData.push(defaultbooksRow)
    setInvoiceBooksData(newInvoiceBooksData)
  }
  const deleteRowHandler = (record, index) => {
    const newInvoiceBooksData = [...invoiceBooksData]
    newInvoiceBooksData.splice(index, 1)
    setInvoiceBooksData(newInvoiceBooksData)
  }
  const isDisplayCalculateAmount = Boolean(
    projectDetails?.model === projectModel?.hourly &&
      projectDetails?.payModel === PREPAID &&
      currentInvoiceTimesheetData?.zohoEmployeeMetadataList?.length === 0
  )
  const isNotProviderZohobooks =
    currentInvoiceTimesheetData?.provider !== 'ZOHO_BOOKS'
  const isNotCurrencyUSD = currentInvoiceTimesheetData?.currency !== 'USD'

  return (
    <div className="invoice-table">
      <Spin spinning={isFormattingTimesheet} tip="Formatting all logs...">
        <Form
          form={form}
          onFinish={() =>
            modifyInBooks ? saveNewInvoice(true) : saveNewInvoice(false)
          }
          scrollToFirstError
          disabled={isFormDisabled}
        >
          <Row gutter={40}>
            <Col sm={6} md={6} lg={6}>
              <AutoFieldComponent
                label="Project Name"
                placeholder={PLACEHOLDER_MESSAGES.projectNameMessage}
                name="projectName"
                type="text"
                disabled
                existingValue={projectData?.name}
              />
            </Col>
            <Col sm={6} md={6} lg={6}>
              <AutoFieldComponent
                label="Customer Name"
                placeholder={PLACEHOLDER_MESSAGES.customerNameMessage}
                name="customerName"
                type="text"
                existingValue={`${currentInvoiceTimesheetData?.customerName} (${currentInvoiceTimesheetData?.provider}- ${currentInvoiceTimesheetData?.customerRegion})`}
                disabled
              />
            </Col>
            {currentInvoiceTimesheetData?.invoiceGenerateDetails?.taxDetails
              ?.taxable &&
              isNotProviderZohobooks && (
                <Col sm={6} md={6} lg={6}>
                  <AutoFieldComponent
                    label="Amount are:"
                    placeholder={PLACEHOLDER_MESSAGES.taxApplicabilityMessage}
                    name="globalTaxSetting"
                    type="text"
                    existingValue={globalTaxSetting}
                    disabled
                  />
                </Col>
              )}
            {isNotCurrencyUSD && (
              <Col sm={6} md={6} lg={6}>
                <InputField
                  name="exchangeRate"
                  label="Exchange Rate"
                  type="number"
                  required={true}
                  placeholder={PLACEHOLDER_MESSAGES.exchangeRateMessage}
                  onChange={(e) =>
                    setExchangeRateDetails((prevDeatails) => {
                      const newDetails = {
                        ...prevDeatails,
                        exchangeRate: Number(e.target.value),
                      }
                      return newDetails
                    })
                  }
                  existingValue={
                    currentInvoiceTimesheetData?.invoiceGenerateDetails
                      ?.exchangeRate
                  }
                  disabled={
                    currentInvoiceTimesheetData?.invoiceGenerateDetails
                      ?.modifyInBooks
                  }
                />
              </Col>
            )}
            <Col sm={6} md={6} lg={6}>
              <InputTextArea
                name="messageOnInvoice"
                label="Message on invoice"
                type="text"
                className={'message-text-area'}
                required={true}
                placeholder={PLACEHOLDER_MESSAGES.messageOnInvoice}
                rules={messageOnInvoiceStatementRule}
                existingValue={
                  currentInvoiceTimesheetData?.invoiceGenerateDetails
                    ?.customerMemo
                }
              />
            </Col>
            <Col sm={6} md={6} lg={6}>
              <InputTextArea
                name="messageOnStatement"
                label="Message on statement"
                type="text"
                rules={messageOnInvoiceStatementRule}
                required={true}
                placeholder={PLACEHOLDER_MESSAGES.messageOnStatement}
                existingValue={
                  currentInvoiceTimesheetData?.invoiceGenerateDetails
                    ?.privateNote
                }
              />
            </Col>
          </Row>
        </Form>
        <div className={'quickbook-data-table'}>
          {isCustomInvoice && (
            <div className="add-new-row">
              <Button
                danger
                label="Add New Row"
                icon={<AddIcon />}
                // disabled={disabled}
                onClick={() => addNewDeveloperHandler()}
              />
            </div>
          )}
          <DataTable
            columns={columns}
            dataSource={invoiceBooksData}
            rowKey={(_, index) => index}
            pagination={false}
            summary={(pageData) => {
              let totalQuantity = 0
              let totalAmount = 0

              pageData.forEach(({ rate, quantity, taxAmount }) => {
                totalQuantity += Number(quantity) || 0
                totalAmount +=
                  Number(rate) * Number(quantity) + Number(taxAmount)
              })
              totalAmount = totalAmount || 0
              setInvoiceAmount(totalAmount)
              return (
                <>
                  <Table.Summary.Row className="bold-text">
                    <Table.Summary.Cell>Sub Total</Table.Summary.Cell>
                    <Table.Summary.Cell />
                    <Table.Summary.Cell>
                      {totalQuantity?.toFixed(2)}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell />
                    {isViewTaxableMode && (
                      <>
                        <Table.Summary.Cell />
                        <Table.Summary.Cell />
                      </>
                    )}

                    <Table.Summary.Cell>
                      {isDisplayCalculateAmount && !totalAmount
                        ? currentInvoiceTimesheetData?.invoiceGenerateDetails?.invoiceAmount?.toFixed(
                            2
                          )
                        : totalAmount?.toFixed(2)}
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                </>
              )
            }}
          />
        </div>
        <div>
          <Col md={24} className="create-btn-container">
            <Button
              type="primary"
              label="Save Invoice"
              onClick={() => {
                setModifyInBooks(false)
                form.submit()
              }}
              disabled={isFormDisabled}
            />
            <Button
              className="save-in-quickbook-btn"
              danger
              label={'Save in Books'}
              icon={<VerifiedIcon color="currentColor" />}
              onClick={() => {
                setModifyInBooks(true)
                form.submit()
              }}
              disabled={isFormDisabled}
            />
          </Col>
        </div>
        <div className="linkModal">
          <CustomizeModal
            title={'View in books'}
            centered={true}
            open={isLinkModalOpen}
            size={1000}
            handleModalOk={() => {
              setIsLinkModalOpen(!isLinkModalOpen)
            }}
            okText={'Ok'}
            closable={false}
            footer={
              <Button
                danger
                label={'OK'}
                onClick={() => {
                  setIsLinkModalOpen(!isLinkModalOpen)
                }}
              />
            }
          >
            <div className="quickbook-link">
              {redirectDetails?.message}
              <p>
                {redirectDetails?.modifyInBooks
                  ? `Note: Please login to Simform's quickbook/zohobooks account if not logged in already before using the link.`
                  : ''}
              </p>
              {redirectDetails?.redirectUrl && (
                <a
                  href={redirectDetails.redirectUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Click Here
                </a>
              )}
              {redirectDetails?.modifyInBooks
                ? `To View or edit invoice in quickbooks/zohobooks`
                : null}
            </div>
          </CustomizeModal>
        </div>
      </Spin>
    </div>
  )
}
export default InvoiceBooks
