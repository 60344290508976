import { API_END_POINTS } from '../constants/apiEndPointConstants'
import axios from 'axios'
import {
  DEFAULT_PAGE_NO,
  DEFAULT_PAGE_SIZE,
  TOAST,
} from '../constants/constant'
import { toastNotify } from '../helperFunctions/toastNotify'

export const activateQR = async (email) => {
  const URL = `${API_END_POINTS.users}/activateQR?email=${email}`
  try {
    const response = await axios.put(URL)
    return response
  } catch (error) {
    return error?.response
  }
}
export const fetchProjectsByUserId = async (userId, page, pageSize) => {
  const URL = `${API_END_POINTS.users}/projects?userId=${userId}&pageNo=${page}&pageSize=${pageSize}`
  try {
    const response = await axios.get(URL)
    return response.data
  } catch (error) {
    return error.response
  }
}

export const updateUserProfile = async ({ userId, name, phoneNumber }) => {
  const URL = `${API_END_POINTS.users}/profile`
  try {
    const response = await axios.put(URL, {
      id: userId,
      name: name,
      phoneNumber: phoneNumber,
    })
    return response
  } catch (error) {
    return error.response
  }
}

export const resendInvitationLink = async (email, newEntry) => {
  const URL = `${API_END_POINTS.users}/password?email=${email}&newEntry=${newEntry}`
  try {
    const response = await axios.put(URL)
    return response
  } catch (error) {
    return error.response
  }
}

export const getUserProfile = async (userId) => {
  const URL = `${API_END_POINTS.users}/${userId}`
  if (userId) {
    try {
      const response = await axios.get(URL)
      return response
    } catch (error) {
      return error.response
    }
  }
}
export const updateUserDetails = async (id, name, email, role) => {
  const URL = `${API_END_POINTS.users}`
  try {
    const response = await axios.put(URL, {
      id,
      name,
      email,
      role,
    })
    return response
  } catch (error) {
    return error?.response
  }
}
export const deleteUserDetail = async (userId) => {
  const URL = `${API_END_POINTS.users}/${userId}`
  try {
    const response = await axios.delete(URL)
    return response
  } catch (error) {
    return error?.response
  }
}
export const changePassword = async (
  id,
  currentPassword,
  newPassword,
  confirmNewPassword
) => {
  const URL = `${API_END_POINTS.users}/profile/password`
  try {
    const response = await axios.put(URL, {
      id,
      currentPassword,
      newPassword,
      confirmNewPassword,
    })
    return response
  } catch (error) {
    return error?.response
  }
}
export const getSearchUsers = async (
  userName,
  signal,
  page = DEFAULT_PAGE_NO,
  pageSize = DEFAULT_PAGE_SIZE
) => {
  const URL = `${API_END_POINTS.users}/search?userName=${userName}&pageNo=${page}&pageSize=${pageSize}`

  try {
    const response = await axios.get(URL, { signal })
    return response
  } catch (err) {
   return err?.response
  }
}
export const addNewUser = async (name, email, role) => {
  const URL = `${API_END_POINTS.users}`
  try {
    const response = await axios.post(URL, { name, email, role })
    return response
  } catch (error) {
    return error?.response
  }
}

export const setUserPassword = async (
  token,
  userId,
  newPassword,
  confirmNewPassword
) => {
  const URL = `${API_END_POINTS.users}/password?token=${token}`
  try {
    const response = await axios.post(URL, {
      userId,
      newPassword,
      confirmNewPassword,
    })
    return response?.data
  } catch (error) {
    return error?.response
  }
}

export const getUserList = async (page, pageSize, signal) => {
  const URL = `${API_END_POINTS.users}?pageNo=${page}&pageSize=${pageSize}`
  try {
    const response = await axios.get(URL, { signal })
    return response?.data?.data
  } catch (error) {
    toastNotify(
      TOAST.error,
      error?.data?.message || 'Failed to get latest users list'
    )
  }
}
export const getCsmSuggestionByProjectId = async (projectId, isPrimary) => {
  const URL = `${API_END_POINTS.users}/autoSuggestReplaceCsm?projectId=${projectId}&primary=${isPrimary}`
  try {
    const response = await axios.get(URL)
    return response?.data
  } catch (error) {
    toastNotify(
      TOAST.error,
      error.data.message || 'Failed to get csm suggestions'
    )
  }
}

export const updateProjectUsers = async (projectDetails) => {
  const URL = `${API_END_POINTS.users}/projects`
  try {
    const response = await axios.put(URL, projectDetails)
    return response
  } catch (err) {
    console.log('error while editing Company', err)
    return err.response
  }
}
