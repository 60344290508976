import './Button.scss'
import React from 'react'
import { Button as BaseButton } from 'antd'

const Button = ({ icon, label, ...rest }) => (
  <BaseButton {...rest} icon={icon}>
    {label}
  </BaseButton>
)
export default Button
