import { createSlice } from '@reduxjs/toolkit'
import { resetAllReduxStates } from './resetAllReduxStatesAction'
const initialState = {
  loading: false,
  hasErrors: false,
  transactionDetailsList: [],
}
const getTransactionsDetailsByIdSlice = createSlice({
  name: 'transactionsDetailsById',
  initialState,
  reducers: {
    getTransactionsDetails: (state) => {
      state.loading = true
    },
    getTransactionsDetailsSuccess: (state, { payload }) => {
      state.transactionDetailsList = payload
      state.loading = false
      state.hasErrors = false
    },
    getTransactionsDetailsFailure: (state) => {
      state.loading = false
      state.hasErrors = true
      state.transactionDetailsList = []
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetAllReduxStates, () => initialState)
  },
})

export const {
  getTransactionsDetails,
  getTransactionsDetailsSuccess,
  getTransactionsDetailsFailure,
} = getTransactionsDetailsByIdSlice.actions

export const transactionsDetailsSelector = (state) =>
  state.transactionsDetailsById

export default getTransactionsDetailsByIdSlice.reducer
