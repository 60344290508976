import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import dateFormat from 'dateformat'

import '../RatesWarningModal/RatesWarningModal.scss'
import Button from '../Button/Button'
import CustomizeModal from '../Modal/Modal'
import TechStackInputForm from './TechStackInputForm'
import { Form } from 'antd'
import { addValuesForEmptyRates } from '../../utils/api/rateServices'
import { DATE_FORMATS, STATUS, TOAST } from '../../utils/constants/constant'
import { headerItemLabels } from '../../utils/constants/constant'
import { TOASTIFY_MESSAGES } from '../../utils/constants/messages'
import { useDispatch, useSelector } from 'react-redux'
import {
  setTimeSheetsData,
  timeSheetsDataSelector,
} from '../../redux/reducer/timeSheetsDataSlice'
import { toastNotify } from '../../utils/helperFunctions/toastNotify'

const EmptyRatesModal = ({
  isEmptyRateModalOpen,
  setIsEmptyRateModalOpen,
  emptyRatesList,
  setEmptyRatesList,
  projectId,
  timesheetNameFormat,
  getEmployeeDifference,
}) => {
  const [form] = Form.useForm()
  const history = useHistory()
  const dispatch = useDispatch()
  const [confirmLoading, setConfirmLoading] = useState(false)
  const { timeSheetsData } = useSelector(timeSheetsDataSelector)

  const currentInvoiceTimesheetData =
    timeSheetsData && timeSheetsData[timesheetNameFormat]

  const goToRates = () => {
    history.push({
      pathname: `../ratedetails/${projectId}`,
      state: {
        headerSelectedItem: headerItemLabels.RATE_DETAILS,
      },
    })
  }

  const handleModalOk = () => {
    form.submit()
    getEmployeeDifference()
  }

  const onFinish = async (values) => {
    setConfirmLoading(true)
    const formattedArray = Object.entries(values).map((item) => {
      return { [item[0]]: item[1] ? item[1] : 0 }
    })

    const formattedObj = Object.assign({}, ...formattedArray)

    const response = await addValuesForEmptyRates(
      formattedObj,
      projectId,
      dateFormat(
        currentInvoiceTimesheetData?.invoiceGenerateDetails?.endDate,
        DATE_FORMATS.DMY
      )
    )
    if (response?.status === STATUS.ok) {
      let newZohoEmployeeMetaData = [
        ...(currentInvoiceTimesheetData.zohoEmployeeMetadataList ?? []),
      ]
      Object.entries(values).forEach((newRateItem) => {
        const updatedZohoEmployeeMetadataList = newZohoEmployeeMetaData.map(
          (item) => {
            return item.userRole === newRateItem[0]
              ? { ...item, rate: Number(newRateItem[1]) }
              : item
          }
        )
        newZohoEmployeeMetaData = updatedZohoEmployeeMetadataList
      })
      const newTimesheetData = {
        ...timeSheetsData,
        [timesheetNameFormat]: {
          ...currentInvoiceTimesheetData,
          zohoEmployeeMetadataList: newZohoEmployeeMetaData,
        },
      }
      dispatch(setTimeSheetsData(newTimesheetData))
      setConfirmLoading(false)
      setIsEmptyRateModalOpen(false)
      setEmptyRatesList([])
      toastNotify(TOAST.success, TOASTIFY_MESSAGES.updateRateMessage)
    } else {
      toastNotify(TOAST.error, TOASTIFY_MESSAGES.failedRateMessage)
      setConfirmLoading(false)
    }
  }
  return (
    <CustomizeModal
      open={isEmptyRateModalOpen}
      title={'Please add rates in following techstacks.'}
      centered={true}
      okText={'Save'}
      cancelText={'Cancel'}
      handleModalCancel={() => {
        setIsEmptyRateModalOpen(false)
        getEmployeeDifference()
      }}
      handleModalOk={handleModalOk}
      className="rates-warning-modal"
      confirmLoading={confirmLoading}
    >
      <div className="value-container">
        <div className="rate">
          <h3>Rates</h3>
          {emptyRatesList?.map((techStackData) => (
            <Form form={form} onFinish={onFinish}>
              <TechStackInputForm techStackData={techStackData} />
            </Form>
          ))}
        </div>
        <div className="btn-container">
          <Button
            danger
            label="Go To Rate Details"
            onClick={() => goToRates()}
          />
        </div>
      </div>
    </CustomizeModal>
  )
}

export default EmptyRatesModal
