import React, { useState } from 'react'

import './EditableDescriptionCell.scss'

import { ReactComponent as EditIcon } from '../../assets/icons/edit.svg'
import Button from '../Button/Button'
import InputTextArea from '../InputTextArea/InputTextArea'

const EditableDescriptionCell = ({ description, index, handleFieldChange }) => {
  const [editMode, setEditMode] = useState(false)
  const [editedDescription, setEditedDescription] = useState(description)

  const handleEditClick = () => {
    setEditMode(!editMode)
  }

  const handleSaveClick = () => {
    handleFieldChange(editedDescription, 'description', index)
    setEditMode(false)
  }

  const handleCancelClick = () => {
    setEditedDescription(description)
    setEditMode(false)
  }

  return (
    <>
      {editMode ? (
        <>
          <InputTextArea
            name="description"
            existingValue={editedDescription}
            placeholder="Write description..."
            onChange={(e) => setEditedDescription(e.target.value)}
            required
            minRows={6}
          />
          <div className="description-button">
            <Button label={'Save'} type="primary" onClick={handleSaveClick} />
            <Button danger label={'Cancel'} onClick={handleCancelClick} />
          </div>
        </>
      ) : (
        <div className='view-description'>
          <div className="description-cell">
            <pre>{description}</pre>
          </div>
          <EditIcon title="Edit Description" onClick={handleEditClick} />
        </div>
      )}
    </>
  )
}

export default EditableDescriptionCell
