import axios from 'axios'
import { API_END_POINTS } from '../constants/apiEndPointConstants'
export const getUserActivities = async (page, signal) => {
  const URL = `${API_END_POINTS.userActivities}?pageNo=${page}&pageSize=${20}`
  try {
    const response = await axios.get(URL, { signal })

    return response?.data?.data
  } catch (err) {
    console.log('error while getting user activities -', err)
    return []
  }
}

export const searchActivities = async (searchedText, page, signal) => {
  const URL = `${
    API_END_POINTS.userActivities
  }/search?key=${searchedText}&pageNo=${page}&pageSize=${20}`
  try {
    const response = await axios.get(URL, { signal })
    return response?.data?.data
  } catch (error) {
    console.log('error while searching activities -', error)
    return []
  }
}

export const activityFilter = async (
  visitor,
  projectName,
  resourceName,
  page
) => {
  let queryParameter = ''
  let numOfParams = 0

  //This condition will append visitor query parameter if it is entered
  if (visitor) {
    queryParameter += `visitor=${visitor}`
    numOfParams += 1
  }

  //This condition will append projectName query parameter if it is entered
  if (projectName) {
    if (numOfParams === 1) queryParameter += '&'
    queryParameter += `projectName=${encodeURIComponent(projectName)}`
    numOfParams += 1
  }

  //This condition will append resourceName query parameter if it is entered
  if (resourceName) {
    if (numOfParams !== (queryParameter.match(/&/g) || []).length) {
      queryParameter += '&'
    }
    queryParameter += `resourceName=${resourceName}`
    numOfParams += 1
  }

  const URL = `${
    API_END_POINTS.userActivities
  }/filter?${queryParameter}&pageNo=${page}&pageSize=${20}`
  try {
    const response = await axios.get(URL)
    return response?.data?.data
  } catch (error) {
    console.log('error while filtering the user activity', error)
    return []
  }
}
