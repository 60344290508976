import {
  getClientNameSuggestions,
} from './api/staticOptionsServices'
import { Country, State, City } from 'country-state-city'


export const getClientOptions = async (comapnyId, value) => {
  const clientList = await getClientNameSuggestions(comapnyId, value)
  return clientList?.map((client) => client.name) || []
}

export const getCountryOptions = () => {
  return (
    Country.getAllCountries()?.map((countryDetail) => ({
      label: countryDetail.name,
      value: countryDetail.name,
    })) || []
  )
}

export const getStatesFromCountry = (country) => {
  const choosenCountry = Country.getAllCountries()?.find(
    (countryDetail) => countryDetail.name === country
  )
  const stateList = State.getStatesOfCountry(choosenCountry?.isoCode)
  return (
    stateList?.map((state) => ({ label: state.name, value: state.name })) || []
  )
}

export const getCitiesFromCountryStates = (country, state) => {
  const choosenCountry = Country.getAllCountries()?.find(
    (countryDetail) => countryDetail.name === country
  )
  const stateList = State.getStatesOfCountry(choosenCountry?.isoCode)
  const choosenState = stateList?.find(
    (stateDetail) => stateDetail.name === state
  )
  const cityList = City.getCitiesOfState(
    choosenCountry?.isoCode,
    choosenState?.isoCode
  )
  return cityList?.map((city) => ({ label: city.name, value: city.name })) || []
} 