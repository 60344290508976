import React, { useContext, useState } from 'react'

import DrawerModule from '../Drawer/Drawer'
import { Col, Form, Row, Input } from 'antd'
import Button from '../Button/Button'
import PopupConfirm from '../PopupConfirm/PopupConfirm'
import { changePassword } from '../../utils/api/userServices'
import { AuthContext } from '../../store/auth'
import { useHistory } from 'react-router-dom'
import { JSEncrypt } from 'jsencrypt'
import { whitespaceRegEx } from '../../utils/validations'
import {
  PLACEHOLDER_MESSAGES,
  TOASTIFY_MESSAGES,
  VALIDATION_MESSAGES,
} from '../../utils/constants/messages'
import { YES, NO, STATUS, TOAST } from '../../utils/constants/constant'
import { toastNotify } from '../../utils/helperFunctions/toastNotify'

const ChangePassword = ({ open, onClose }) => {
  const [formDisabled, setFormDisabled] = useState(false)
  const [isPopupVisible, setIsPopupVisible] = useState(false)
  const [isDisableCancel, setIsDisableCancel] = useState(true)
  const { logout } = useContext(AuthContext)
  const loggedInUserData = JSON.parse(localStorage.getItem('userData'))

  const history = useHistory()
  const [form] = Form.useForm()

  const passwordRegEx =
    /(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.*[0-9])(?=.*[^A-Za-z0-9]).{8,20}/
  const onFinish = async (values) => {
    setFormDisabled(true)
    const encrypt = new JSEncrypt()
    const publicKey = process.env.REACT_APP_PUBLIC_KEY

    // Assign our encryptor to utilize the public key.
    encrypt.setPublicKey(publicKey)

    // Perform our encryption based on our public key - only private key can read it!

    const encryptedCurrentPassword = encrypt.encrypt(values.currentPassword)
    const encryptedNewPassword = encrypt.encrypt(values.newPassword)
    const encryptedConfirmPassword = encrypt.encrypt(values.confirmNewPassword)

    const response = await changePassword(
      loggedInUserData?.userId,
      encryptedCurrentPassword,
      encryptedNewPassword,
      encryptedConfirmPassword
    )
    if (response?.status === STATUS.ok) {
      toastNotify(TOAST.success, response?.data?.message)
      form.resetFields()
      logout()
      setFormDisabled(false)
      onClose()
      history.push('/')
    } else if (response?.status === STATUS.badRequest) {
      setFormDisabled(false)
      toastNotify(TOAST.error, response?.data?.message)
    } else {
      setFormDisabled(false)
      toastNotify(TOAST.error, TOASTIFY_MESSAGES.failedChangePasswordMessage)
    }
  }
  const onFinishFailed = async () => {
    toastNotify(TOAST.error, TOASTIFY_MESSAGES.failedChangePasswordMessage)
  }

  return (
    <DrawerModule
      open={open}
      onClose={onClose}
      closable={!isPopupVisible}
      title="Change Password"
      size={'600px'}
    >
      <Form
        form={form}
        layout="vertical"
        autoComplete="off"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        onFieldsChange={() => setIsDisableCancel(false)}
        disabled={formDisabled}
      >
        <Row gutter={24}>
          <Col lg={24} md={24} sm={24}>
            <Form.Item
              label="Current Password"
              name="currentPassword"
              rules={[
                {
                  required: true,
                  message: VALIDATION_MESSAGES.oldPasswordMessage,
                },
              ]}
            >
              <Input.Password
                placeholder={PLACEHOLDER_MESSAGES.oldPasswordMessage}
                visibilityToggle={true}
                size="large"
              />
            </Form.Item>
          </Col>
          <Col lg={24} md={24} sm={24}>
            <Form.Item
              label="New Password"
              name="newPassword"
              rules={[
                {
                  required: true,
                  message: VALIDATION_MESSAGES.newPasswordMessage,
                },
                {
                  pattern: passwordRegEx,
                  message: VALIDATION_MESSAGES.validPasswordPatternMessage,
                },
                {
                  pattern: whitespaceRegEx,
                  message: VALIDATION_MESSAGES.noWhiteSpaceInPasswordMessage,
                },
              ]}
            >
              <Input.Password
                placeholder={PLACEHOLDER_MESSAGES.newPasswordMessage}
                visibilityToggle={true}
                size="large"
              />
            </Form.Item>
          </Col>
          <Col lg={24} md={24} sm={24}>
            <Form.Item
              label="Confirm Password"
              name="confirmNewPassword"
              dependencies={['newPassword']}
              rules={[
                {
                  required: true,
                  message: VALIDATION_MESSAGES.confirmPasswordMessage,
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('newPassword') === value) {
                      return Promise.resolve()
                    }
                    return Promise.reject(
                      new Error(
                        VALIDATION_MESSAGES.passwordsShouldBeSameMessage
                      )
                    )
                  },
                }),
              ]}
            >
              <Input.Password
                placeholder={PLACEHOLDER_MESSAGES.newPasswordMessage}
                visibilityToggle={false}
                size="large"
              />
            </Form.Item>
          </Col>
        </Row>

        <div className="drawer-button btn-group">
          <PopupConfirm
            onConfirm={() => {
              form.resetFields()
              setIsPopupVisible(false)
              setIsDisableCancel(true)
            }}
            onCancel={() => setIsPopupVisible(false)}
            cancelText={NO}
            okText={YES}
            title={'Do you want to discard the changes?'}
          >
            <Button
              danger
              label="Cancel"
              disabled={isDisableCancel}
              onClick={() => setIsPopupVisible(!isPopupVisible)}
            />
          </PopupConfirm>
          <Button type="primary" label="Change" htmlType="submit" />
        </div>
      </Form>
    </DrawerModule>
  )
}

export default ChangePassword
