import React, { useEffect, useState } from 'react'
import { DATE_FORMATS, STATUS, TOAST } from '../../utils/constants/constant'
import { useDispatch, useSelector } from 'react-redux'
import {
  setTimeSheetsData,
  timeSheetsDataSelector,
} from '../../redux/reducer/timeSheetsDataSlice'
import {
  endDateRule,
  startDateRule,
} from '../../utils/constants/validationRules'
import { Col, Form, Row, Spin } from 'antd'
import Button from '../Button/Button'
import { PLACEHOLDER_MESSAGES } from '../../utils/constants/messages'
import DateField from '../DateField/DateField'
import AutoFieldComponent from '../AutoComplete/AutoComplete'
import { getTimesheet } from '../../utils/api/projectServices'
import dateFormat from 'dateformat'
import { format } from 'date-fns'
import InvoiceTimesheet from '../InvoiceTimesheet/InvoiceTimesheet'
import './CreateCustomInvoice.scss'
import { toastNotify } from '../../utils/helperFunctions/toastNotify'
const CreateCustomInvoice = ({
  projectData,
  customTimesheetNameFormat,
  setCustomTimesheetNameFormat,
  setIsCustomInvoice,
}) => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const [isTimesheetLoading, setIsTimesheetLoading] = useState(false)
  const { timeSheetsData } = useSelector(timeSheetsDataSelector)
  useEffect(() => {
    form.setFieldValue('projectName', projectData?.name)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectData])

  const getNewTimeSheetHanlder = async (values) => {
    const startDateString = dateFormat(
      values?.startDate,
      DATE_FORMATS.dd_mmm_yyyy
    )
    const endDateString = dateFormat(values?.endDate, DATE_FORMATS.dd_mmm_yyyy)
    const timesheetNameFormat = `${projectData?.projectId}_${startDateString}_${endDateString}`

    if (timeSheetsData && timeSheetsData[timesheetNameFormat]) {
      setCustomTimesheetNameFormat(timesheetNameFormat)
    } else {
      setIsTimesheetLoading(true)
      const response = await getTimesheet(
        projectData?.zohoProjectId,
        dateFormat(values?.startDate, DATE_FORMATS.mm_dd_yyyy),
        dateFormat(values?.endDate, DATE_FORMATS.mm_dd_yyyy)
      )
      if (response?.code === STATUS.ok) {
        const currentTime = format(new Date(), DATE_FORMATS.DATE_WITH_TIME)
        const newTimesheetData = {
          ...timeSheetsData,
          [timesheetNameFormat]: {
            ...response.data,
            lastSyncedTime: currentTime,
          },
        }
        Boolean(response?.data?.zohoEmployeeMetadataList?.length)
          ? setIsCustomInvoice(false)
          : setIsCustomInvoice(true)
        setCustomTimesheetNameFormat(timesheetNameFormat)
        dispatch(setTimeSheetsData(newTimesheetData))
      } else {
        toastNotify(TOAST.error, 'Failed to get the timesheet for given dates.')
      }
      setIsTimesheetLoading(false)
    }
  }
  return timeSheetsData && timeSheetsData[customTimesheetNameFormat] ? (
    <InvoiceTimesheet customTimesheetNameFormat={customTimesheetNameFormat} />
  ) : (
    <Spin spinning={isTimesheetLoading} tip="Fetching timesheet details">
      <Form
        Custom
        layout="vertical"
        className="add-project custom-invoice-form-wrapper"
        onFinish={(values) => {
          getNewTimeSheetHanlder(values)
        }}
        form={form}
        disabled={isTimesheetLoading}
      >
        <div className="invoice-details">
          <Row gutter={40}>
            <Col sm={8} md={8} lg={8}>
              <AutoFieldComponent
                label="Project Name"
                name="projectName"
                placeholder={PLACEHOLDER_MESSAGES.projectNameMessage}
                type="text"
                disabled
              />
            </Col>

            <Col sm={8} md={8} lg={8}>
              <DateField
                name="startDate"
                label="Start Date"
                placeholder={PLACEHOLDER_MESSAGES.startDateMessage}
                type="text"
                required
                rules={startDateRule}
              />
            </Col>
            <Col sm={8} md={8} lg={8}>
              <DateField
                name="endDate"
                label="End Date"
                placeholder={PLACEHOLDER_MESSAGES.endDateMessage}
                type="text"
                required
                rules={endDateRule}
                disabledDate={(currentDate) => {
                  return (
                    currentDate && currentDate < form.getFieldValue('startDate')
                  )
                }}
              />
            </Col>
          </Row>
          <Row gutter={40}>
            <Col md={8}>
              <Button
                danger
                label="Get the timesheet"
                className="timesheet-btn"
                htmlType="submit"
              />
            </Col>
          </Row>
        </div>
      </Form>
    </Spin>
  )
}

export default CreateCustomInvoice
