import { createSlice } from '@reduxjs/toolkit'
import { resetAllReduxStates } from './resetAllReduxStatesAction'
const initialState = {
  projectId: null,
}
const projectIdSlice = createSlice({
  name: 'projectId',
  initialState,
  reducers: {
    setCurrentProjectId: (state, action) => {
      state.projectId = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetAllReduxStates, () => initialState)
  },
})
export const { setCurrentProjectId } = projectIdSlice.actions
export default projectIdSlice.reducer
