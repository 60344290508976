import React from 'react'
import './DateField.scss'
import { DatePicker, Form } from 'antd'
import { ReactComponent as CalendarIcon } from '../../assets/icons/calender.svg'
import { DATE_FORMATS, DATE_PICKER } from '../../utils/constants/constant'
import moment from 'moment'

const DateField = ({
  placeholder,
  value,
  name,
  onDateChange,
  label,
  existingValue,
  rules,
  picker,
  disabled,
  disabledDate,
  popupClassName,
  valuePropName,
  suffixIcon,
}) => {
  return (
    <Form.Item
      label={label}
      className="text-field"
      rules={rules}
      name={name}
      valuePropName={valuePropName}
      initialValue={
        existingValue &&
        moment(
          existingValue,
          picker === DATE_PICKER.month
            ? DATE_FORMATS.MMM_YYYY
            : DATE_FORMATS.DD_MMM_YYYY
        )
      }
    >
      <DatePicker
        name={name}
        className="date-field text-field"
        onChange={(date, datestring) => {
          onDateChange && onDateChange(date, datestring, name)
        }}
        placeholder={placeholder}
        value={value}
        format={
          picker === DATE_PICKER.month
            ? DATE_FORMATS.MMM_YYYY
            : DATE_FORMATS.DD_MMM_YYYY
        }
        picker={picker}
        disabled={disabled}
        allowClear
        disabledDate={disabledDate}
        getPopupContainer={(triggerNode) => triggerNode.parentElement}
        popupClassName={popupClassName}
        suffixIcon={suffixIcon === null ? null : <CalendarIcon />}
      />
    </Form.Item>
  )
}

export default DateField
