import { createSlice } from '@reduxjs/toolkit'
import { resetAllReduxStates } from './resetAllReduxStatesAction'
const initialState = {
  loading: false,
  hasErrors: false,
  projectDetails: {},
  persistedProjectId: null,
}
const getProjectsByIdSlice = createSlice({
  name: 'projectById',
  initialState,
  reducers: {
    getProjectDetails: (state) => {
      state.loading = true
    },
    getProjectDetailsSuccess: (state, { payload }) => {
      state.projectDetails = {
        ...payload.projectDetails,
        accStartDate: payload.accStartDate,
      }
      state.accStartDate = payload.accStartDate
      state.persistedProjectId = payload.projectId
      state.loading = false
      state.hasErrors = false
    },
    getProjectDetailsFailure: (state) => {
      state.loading = false
      state.hasErrors = true
      state.projectDetails = {}
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetAllReduxStates, () => initialState)
  },
})

export const {
  getProjectDetails,
  getProjectDetailsSuccess,
  getProjectDetailsFailure,
} = getProjectsByIdSlice.actions

export const projectDetailsSelector = (state) => state.projectById

export default getProjectsByIdSlice.reducer
