import axios from 'axios'
import { API_END_POINTS } from '../constants/apiEndPointConstants'
import {
  getProjectDetails,
  getProjectDetailsSuccess,
  getProjectDetailsFailure,
} from '../../redux/reducer/getProjectsByIdSlice'
import { setCurrentProjectId } from '../../redux/reducer/projectIdSlice'
import {
  DEFAULT_PAGE_NO,
  DEFAULT_PAGE_SIZE,
  TOAST,
} from '../constants/constant'
import { toastNotify } from '../helperFunctions/toastNotify'

// fetchProjects and filter projects for get projectList classicView
export const getFilteredProjectListService = async (
  page,
  pageSize,
  filterSource,
  signal
) => {
  const URL = `${API_END_POINTS.projects}/filter?pageNo=${page}&pageSize=${pageSize}&field=${filterSource.field}&order=${filterSource.order}`
  try {
    const response = await axios.post(URL, filterSource.filterValues, {
      signal,
    })
    return response
  } catch (error) {
    return error?.response
  }
}

export const getProjectById = (projectId) => {
  const URL = `${API_END_POINTS.projects}/${projectId} `

  return async (dispatch) => {
    dispatch(getProjectDetails())
    dispatch(setCurrentProjectId(projectId))

    try {
      const response = await axios.get(URL)
      const projectDetailsData = response?.data?.data
      dispatch(getProjectDetailsSuccess(projectDetailsData))
    } catch (error) {
      toastNotify(
        TOAST.error,
        error?.data?.message || 'Failed to get project details'
      )
      dispatch(getProjectDetailsFailure())
    }
  }
}

export const updateProject = async (projectDetailsUpdate, documents) => {
  const URL = API_END_POINTS.projects
  const formData = new FormData()
  const blob = new Blob([JSON.stringify(projectDetailsUpdate)], {
    type: 'application/json',
  })
  formData.append('projectDetails', blob)

  Array.isArray(documents.addFiles) &&
    documents.addFiles.forEach((document) => {
      document && formData.append('addFiles', document)
    })
  formData.append('removeFiles', documents?.removeFiles)

  try {
    const response = await axios.put(URL, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    return response
  } catch (err) {
    console.log('error while updating Project', err)
    return err?.response
  }
}

export const fetchSearchProjects = async (
  projectName,
  signal,
  page = DEFAULT_PAGE_NO,
  pageSize = DEFAULT_PAGE_SIZE
) => {
  const URL = `${
    API_END_POINTS.projects
  }/search?projectName=${encodeURIComponent(
    projectName
  )}&pageNo=${page}&pageSize=${pageSize}`

  try {
    const response = await axios.get(URL, { signal })
    return response
  } catch (err) {
    return err?.response
  }
}

export const getContractDocumentByProjectId = async (fileName) => {
  const URL = `${API_END_POINTS.getContractDocumentByProjectId}?fileName=${fileName}`
  try {
    // specifying response data type as arraybuffer
    const response = await axios.get(URL, { responseType: 'arraybuffer' })
    return response
  } catch (err) {
     return err?.response
  }
}

export const fetchLeaveBalanceDetails = async (projectId) => {
  const URL = `${API_END_POINTS.employees}/project?projectId=${projectId}`
  try {
    const response = await axios.get(URL)
    return response?.data?.data
  } catch (error) {
    toastNotify(
      TOAST.error,
      error?.data?.message || 'Failed to get leave balance details'
    )
  }
}

export const addNewEmployee = async (projectId, employeeId) => {
  const URL = `${API_END_POINTS.employees}/add?projectId=${projectId}&employeeId=${employeeId}`
  try {
    const response = await axios.put(URL)
    return response
  } catch (error) {
    error?.message && toastNotify(TOAST.error, error?.message)
  }
}
export const deleteEmployee = async (projectId, employeeId) => {
  const URL = `${API_END_POINTS.employees}/remove?projectId=${projectId}&employeeId=${employeeId}`
  try {
    const response = await axios.put(URL)
    return response
  } catch (error) {
    error?.message && toastNotify(TOAST.error, error?.message)
  }
}
export const updateLeaveBalance = async (projectId, leaveBalanceList) => {
  const URL = `${API_END_POINTS.employees}/details?projectId=${projectId}`
  try {
    const response = await axios.put(URL, leaveBalanceList)
    return response
  } catch (error) {
    error?.response?.data?.message &&
      toastNotify(TOAST.error, error?.response?.data?.message)
  }
}

export const getPreRequisiteDataForInvoice = async (
  zohoProjectId,
  startDate,
  endDate
) => {
  const URL = `${API_END_POINTS.timesheet}/validate/${zohoProjectId}?startDate=${startDate}&endDate=${endDate}`
  try {
    const response = await axios.get(URL)
    return response?.data
  } catch (err) {
    console.log(
      'error while getting pre requisite data for invoice generation',
      err
    )
    err?.response?.data?.message &&
      toastNotify(TOAST.error, err?.response?.data?.message)
    return err?.response
  }
}

export const getTimesheet = async (
  projectId,
  startDate,
  endDate,
  predictionId = 0
) => {
  const URL = `${API_END_POINTS.timesheet}/getAllLogs/${projectId}?startDate=${startDate}&endDate=${endDate}&predictionId=${predictionId}`
  try {
    const response = await axios.get(URL)
    return response?.data
  } catch (err) {
    console.log('error while getting timesheetdata', err)
    return err?.response
  }
}

export const getFormattedInvoiceData = async (formateInvoiceData) => {
  const URL = `${API_END_POINTS.projectsInvoices}/lineItems`
  try {
    const response = await axios.post(URL, formateInvoiceData, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    return response?.data
  } catch (err) {
    console.log('error while getting merged logs', err)
    return err?.response
  }
}
