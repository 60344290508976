import React from 'react'
import './PageHeading.scss'
const PageHeading = ({ label }) => {
  return (
    <div className="page-heading">
      <h3 className="heading">{label}</h3>
    </div>
  )
}

export default PageHeading
