import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Col, Form, Radio, Row, Tooltip } from 'antd'

import PopupConfirm from '../PopupConfirm/PopupConfirm'
import Button from '../Button/Button'
import SelectField from '../SelectField/SelectField'
import SearchBar from '../SearchBar/SearchBar'
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete.svg'
import { ReactComponent as AddClient } from '../../assets/icons/plus.svg'
import { staticOptionsSelector } from '../../redux/reducer/getOptionsSlice'
import {
  addClientsToProject,
  getClientForSelectedCompany,
  getCompanyAndClientsByProjectId,
} from '../../utils/api/companyServices'
import { suggestionsOptionsApiService } from '../../utils/api/staticOptionsServices'
import { getProjectById } from '../../utils/api/projectServices'
import {
  PLACEHOLDER_MESSAGES,
  VALIDATION_MESSAGES,
  TOASTIFY_MESSAGES,
} from '../../utils/constants/messages'
import { YES, NO, TOAST, STATUS } from '../../utils/constants/constant'
import { toastNotify } from '../../utils/helperFunctions/toastNotify'
import { getCompanyOptionsLabel } from '../../utils/helperFunctions/helperFunctions'

const AssignExistingClientsForm = ({
  existingCompanyDetails,
  existingClientsList,
  onClose,
  open,
}) => {
  const { companyOptions } = useSelector(staticOptionsSelector)
  const { projectId } = useSelector((state) => state.projectId)
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const [selectedCompanyId, setSelectedCompanyId] = useState()
  const [isFormDisabled, setIsFormDisabled] = useState(false)
  const [isFormDataChanged, setIsFormDataChanged] = useState(false)
  const [searchText, setSearchText] = useState()
  const [clientDetailsForSelectedCompany, setClientDetailsForSelectedCompany] =
    useState([])
  const [clientOptionsForSelectedCompany, setClientOptionsForSelectedCompany] =
    useState([])
  const [searchedCompanyOptions, setSearchedCompanyOptions] =
    useState(companyOptions)
  const [isPrimaryClient, setIsPrimaryClient] = useState([
    { field: 0, value: false },
  ])
  useEffect(() => {
    const getSearchedData = setTimeout(async () => {
      if (searchText) {
        const searchedCompanyList = await suggestionsOptionsApiService(
          'company',
          searchText
        )
        setSearchedCompanyOptions(searchedCompanyList)
      } else {
        setSearchedCompanyOptions(companyOptions)
      }
    }, 500)

    return () => clearTimeout(getSearchedData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText])

  useEffect(() => {
    if (existingCompanyDetails) {
      form.setFieldsValue({
        selectedCompany: `${existingCompanyDetails?.companyName} (${existingCompanyDetails?.region})`,
      })
      open && companySelectHandler(existingCompanyDetails?.id)
      const existingClientsName = []
      const existingPrimaryClientData = []
      existingClientsList &&
        existingClientsList.forEach((clientItem, index) => {
          existingClientsName.push({
            client: `${clientItem?.name} | ${clientItem?.email}`,
          })
          existingPrimaryClientData.push({
            field: index,
            value: clientItem.primary,
          })
        })
      setIsPrimaryClient(existingPrimaryClientData)
      form.setFieldValue('clientsList', existingClientsName)
    } else {
      form.setFieldsValue({
        selectedCompany: null,
        clietsList: [{}],
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [existingCompanyDetails, open])

  const companySelectHandler = async (companyId) => {
    form.setFieldValue('clientsList', [{}])
    setSelectedCompanyId(companyId)
    const clientList = await getClientForSelectedCompany(companyId)
    if (clientList?.length) {
      if (existingCompanyDetails?.id === companyId) {
        const alreadySelectedClients = existingClientsList?.map(
          (item) => item?.email
        )
        const newClientList = clientList.filter(
          (item) => !alreadySelectedClients.includes(item.email)
        )

        setClientOptionsForSelectedCompany(newClientList)
      } else {
        setClientOptionsForSelectedCompany(clientList)
      }
      setClientDetailsForSelectedCompany(clientList)
    } else {
      toastNotify(TOAST.error, TOASTIFY_MESSAGES.noClientInCompanyMessage)
      setClientDetailsForSelectedCompany([])
      setClientOptionsForSelectedCompany([])
    }
  }

  async function onFormFinish(values) {
    setIsFormDisabled(true)
    const selectedClientIdList = []
    const selectedClientList = values?.clientsList
    if (selectedClientList?.length && selectedClientList[0]?.client) {
      selectedClientList.forEach((clientItem, index) => {
        if (clientItem?.client) {
          const filteredResult = clientDetailsForSelectedCompany.filter(
            (item) =>
              item.email?.trim() === clientItem.client?.split('|')[1].trim()
          )
          filteredResult.length > 0 &&
            selectedClientIdList.push({
              clientId: filteredResult[0]?.id,
              primaryClient: isPrimaryClient[index].value,
            })
        }
      })
    }
    const isPrimaryClientSelected = selectedClientIdList.some(
      (item) => item?.primaryClient
    )
    if (!isPrimaryClientSelected) {
      toastNotify(TOAST.warning, TOASTIFY_MESSAGES.selectPrimaryClientMessage)
      setIsFormDisabled(false)
      return
    }
    const response = await addClientsToProject(
      selectedCompanyId,
      projectId,
      selectedClientIdList
    )
    if (response?.status === STATUS.ok) {
      toastNotify(TOAST.success, TOASTIFY_MESSAGES.companyClientSuccessMessage)
      setIsFormDisabled(false)
      onClose()
      dispatch(getProjectById(projectId))
      dispatch(getCompanyAndClientsByProjectId(projectId))
      form.resetFields()
    } else {
      toastNotify(TOAST.error, TOASTIFY_MESSAGES.failedAddCompanyMessage)
      setIsFormDisabled(false)
    }
  }
  async function onFormFinishFailed() {
    toastNotify(TOAST.error, TOASTIFY_MESSAGES.failedToAddExitingClientMessage)
  }

  const handlePopupConfirm = () => {
    form.resetFields()
    existingCompanyDetails?.companyName &&
      form.setFieldsValue({
        selectedCompany: `${existingCompanyDetails?.companyName} (${existingCompanyDetails?.region})`,
      })
    existingCompanyDetails && companySelectHandler(existingCompanyDetails?.id)
    const existingClientsName = []
    const existingPrimaryClientData = []
    existingClientsList &&
      existingClientsList.forEach((clientItem, index) => {
        existingClientsName.push({
          client: `${clientItem?.name} | ${clientItem?.email}`,
          isPrimaryClient: clientItem?.isPrimaryClient,
        })
        existingPrimaryClientData.push({
          field: index,
          value: clientItem.primary,
        })
      })
    setIsPrimaryClient(existingPrimaryClientData)
    form.setFieldValue('clientsList', existingClientsName)
    setIsFormDataChanged(false)
  }

  const clientSelectHandler = (index) => {
    if (index === 0) {
      setIsPrimaryClient([{ field: 0, value: true }])
    }
    const alreadySelectedClients = form
      .getFieldValue('clientsList')
      ?.map((item) => item?.client?.split('|')[1].trim())
    const newClientList = clientDetailsForSelectedCompany.filter(
      (item) => !alreadySelectedClients.includes(item.email)
    )
    setClientOptionsForSelectedCompany(newClientList)
  }

  const handleDeleteClient = useCallback(
    (deletedFieldId) => {
      const alreadySelectedClients = form.getFieldValue('clientsList')
      const deletedClientEmail = alreadySelectedClients[deletedFieldId]?.client
        ?.split('|')[1]
        .trim()
      const deletedClientDetails = clientDetailsForSelectedCompany.filter(
        (item) => item.email === deletedClientEmail
      )
      setClientOptionsForSelectedCompany((prevState) => {
        const oldOptions = [...prevState]
        const newOptions = oldOptions.concat(deletedClientDetails)

        return newOptions
      })
      setIsPrimaryClient((prevState) => {
        const oldPrimaryClient = [...prevState]
        const newPrimaryClient = []
        oldPrimaryClient.forEach((item, index) => {
          if (deletedFieldId !== item.field) {
            if (deletedFieldId > item.field) {
              newPrimaryClient.push(item)
            } else {
              newPrimaryClient.push({
                ...item,
                field: item.field - 1,
              })
            }
          }
        })

        return newPrimaryClient
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [clientDetailsForSelectedCompany]
  )
  return (
    <Form
      className="new-company-info"
      onFinish={onFormFinish}
      onFinishFailed={onFormFinishFailed}
      form={form}
      disabled={isFormDisabled}
      onFieldsChange={() => setIsFormDataChanged(true)}
    >
      <Row gutter={40}>
        <Col md={24}>
          <SelectField
            name="selectedCompany"
            label="Existing companies in this portal"
            className={'select-company'}
            options={searchedCompanyOptions?.map((option) => {
              return {
                label: getCompanyOptionsLabel(option),
                value: option?.id,
              }
            })}
            isFeatured={true}
            placeholder={PLACEHOLDER_MESSAGES.companyNameMessage}
            onFocus={() =>
              !searchText && setSearchedCompanyOptions(companyOptions)
            }
            onChange={(value) => companySelectHandler(value)}
            type="text"
            required
            rules={[
              {
                required: true,
                message: VALIDATION_MESSAGES.companyNameMessage,
              },
            ]}
            dropdownRender={(menu) => {
              return (
                <>
                  <SearchBar
                    placeholder="Search company"
                    searchP="search-project exis_com-search"
                    searchText={searchText}
                    setSearchText={setSearchText}
                  />
                  {menu}
                </>
              )
            }}
          />
        </Col>
        <Form.List name="clientsList" initialValue={[{}]}>
          {(fields, { add, remove }) => {
            return (
              <>
                {fields.map((field) => {
                  return (
                    <Col md={24} key={field.key}>
                      <div className="select-field-with-button">
                        <SelectField
                          name={[field.name, 'client']}
                          label="Select client Name"
                          isFeatured={true}
                          options={clientOptionsForSelectedCompany.map(
                            (option) => {
                              return {
                                label: `${option?.name} | ${option?.email}`,
                                value: `${option?.name} | ${option?.email}`,
                              }
                            }
                          )}
                          disabled={!clientDetailsForSelectedCompany.length}
                          placeholder="Client Name"
                          type="text"
                          onChange={() => clientSelectHandler(field.key)}
                          required
                          rules={[
                            {
                              required: true,
                              message:
                                VALIDATION_MESSAGES.selectOrDeleteClientMessage,
                            },
                          ]}
                        />
                        <Tooltip
                          title="Primary Client"
                          placement="topRight"
                          color="#ffffff"
                          overlayClassName="tooltip"
                        >
                          <Form.Item
                            name={[field.name, 'isPrimary']}
                            className={`detete-btn ${
                              isPrimaryClient[field.name]?.value ? 'active' : ''
                            }`}
                          >
                            <Button
                              icon={
                                <Radio
                                  checked={isPrimaryClient[field.name]?.value}
                                  onChange={() => {
                                    setIsPrimaryClient((prevState) => {
                                      const oldPrimaryClientData = [
                                        ...prevState,
                                      ]
                                      const newPrimaryClientData =
                                        oldPrimaryClientData.map(
                                          (item, index) => {
                                            if (index === field.name) {
                                              item.value =
                                                !oldPrimaryClientData[
                                                  field.name
                                                ].value
                                            } else {
                                              item.value = false
                                            }
                                            return item
                                          }
                                        )
                                      return newPrimaryClientData
                                    })
                                  }}
                                />
                              }
                            />
                          </Form.Item>
                        </Tooltip>

                        <Button
                          className="detete-btn"
                          title={'Delete client'}
                          disabled={isPrimaryClient[field.name]?.value}
                          icon={<DeleteIcon />}
                          onClick={() => {
                            handleDeleteClient(field.name)
                            remove(field.name)
                          }}
                        />
                      </div>
                    </Col>
                  )
                })}
                <Col lg={12} md={12} sm={12} className="sales-btn">
                  <Button
                    onClick={() => {
                      const oldPrimaryClientData = isPrimaryClient
                      const newPrimaryClientData = [
                        ...oldPrimaryClientData,
                        {
                          field: fields.length,
                          value: false,
                        },
                      ]
                      setIsPrimaryClient(newPrimaryClientData)
                      add()
                    }}
                    type="link"
                    className="add-package"
                    icon={<AddClient />}
                    label={
                      fields.length <= 0
                        ? 'Add client from selected company'
                        : 'Add another client'
                    }
                    disabled={
                      fields.length ===
                        clientDetailsForSelectedCompany.length ||
                      !clientDetailsForSelectedCompany.length
                    }
                  />
                </Col>
              </>
            )
          }}
        </Form.List>
      </Row>

      <div className="drawer-button btn-group">
        <PopupConfirm
          onConfirm={() => handlePopupConfirm()}
          cancelText={NO}
          okText={YES}
          title={'Do you want to discard the changes?'}
        >
          <Button danger label="Cancel" disabled={!isFormDataChanged} />
        </PopupConfirm>
        <Button
          type="primary"
          label="Assign Clients"
          htmlType="submit"
          disabled={
            !isFormDataChanged || !clientDetailsForSelectedCompany.length
          }
        />
      </div>
    </Form>
  )
}

export default AssignExistingClientsForm
