import React, { useCallback, useState } from 'react'
import { Modal, Tooltip } from 'antd'
import { formatDistance } from 'date-fns'
import { useDispatch, useSelector } from 'react-redux'

import './ProjectNotesListTable.scss'
import {
  Button,
  Checkbox,
  DataTable,
  InputTextArea,
  TablePagination,
} from '../index'
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete.svg'
import {
  addNewProjectNote,
  deleteProjectNoteById,
  editProjectNote,
} from '../../utils/api/projectNoteServices'
import { STATUS } from '../../utils/constants/constant'
import { TOAST } from '../../utils/constants/constant'
import { toastNotify } from '../../utils/helperFunctions/toastNotify'
import { TOASTIFY_MESSAGES } from '../../utils/constants/messages'
import { debounce, isEmpty } from '../../utils/helperFunctions/helperFunctions'
import {
  projectNotesListSelector,
  setProjectNotesList,
} from '../../redux/reducer/projectNotesByIdSlice'

const ProjectNotesListTable = ({
  projectNotesTableData,
  getProjectNotesList,
  currentStatusFilterValue,
  onTablePaginationChange,
}) => {
  const dispatch = useDispatch()
  const { projectNotesList } = useSelector(projectNotesListSelector)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [selectedNoteData, setSelectedNotesData] = useState(null)
  const [confirmLoading, setConfirmLoading] = useState(false)
  const projectNotesData = projectNotesList?.noteClassicViews

  const saveNewNoteHandler = async (updatedDescription, noteDetails) => {
    const updatedDetails = {
      ...noteDetails,
      notesDescription: updatedDescription?.trim(),
      projectNotesId:0
    }
    if (isEmpty(updatedDescription)) {
      toastNotify(
        TOAST.error,
        TOASTIFY_MESSAGES.emptyInputMessage('description')
      )
    } else {
      const response = await addNewProjectNote([updatedDetails])
      if (response?.status === STATUS?.ok) {
        toastNotify(TOAST.success, response?.data?.message)
        getProjectNotesList(currentStatusFilterValue)
      } else {
        toastNotify(
          TOAST.error,
          response?.data?.message ||
            TOASTIFY_MESSAGES.failedProjectNoteOperationMessage('add')
        )
      }
      return response
    }
  }
  const debouncedAdd = debounce(saveNewNoteHandler, 1500)

  const notesDescriptionChangeHandler = async (
    updatedDescription,
    oldNoteDetails
  ) => {
    const updatedDetails = {
      ...oldNoteDetails,
      notesDescription: updatedDescription,
    }
    if (isEmpty(updatedDescription)) {
      toastNotify(
        TOAST.error,
        TOASTIFY_MESSAGES.emptyInputMessage('description')
      )
    } else {
      const response = await editProjectNote([updatedDetails])
      if (response?.status === STATUS.ok) {
        toastNotify(
          TOAST.success,
          TOASTIFY_MESSAGES.projectNoteDescriptionUpdatedMessage(
            updatedDescription
          )
        )
        getProjectNotesList(currentStatusFilterValue)
      } else {
        toastNotify(
          TOAST.error,
          TOASTIFY_MESSAGES.failedProjectNoteOperationMessage('update')
        )
        getProjectNotesList(currentStatusFilterValue)
      }
    }
  }
  const debouncedUpdate = debounce(notesDescriptionChangeHandler, 1500)

  const statusChangeHanlder = async (updatedStatusValue, oldDetails) => {
    const updatedDetails = { ...oldDetails, isChecked: updatedStatusValue }
    const response = await editProjectNote([updatedDetails])
    if (response?.status === STATUS.ok) {
      toastNotify(
        TOAST.success,
        TOASTIFY_MESSAGES.projectNoteStatusUpdatedMessage(updatedStatusValue)
      )
      getProjectNotesList(currentStatusFilterValue)
    } else {
      toastNotify(
        TOAST.error,
        TOASTIFY_MESSAGES.failedProjectNoteOperationMessage('update')
      )
      getProjectNotesList(currentStatusFilterValue)
    }
  }

  const deleteNoteHandler = (record) => {
    if (record?.projectNotesId) {
      setSelectedNotesData(record)
      setShowDeleteModal(true)
    } else {
      dispatch(
        setProjectNotesList({
          ...projectNotesList,
          noteClassicViews: projectNotesData?.filter(
            (item) => item.key !== record?.key
          ),
        })
      )
    }
  }

  const handleDeleteModalOk = useCallback(async () => {
    setConfirmLoading(true)
    const response = await deleteProjectNoteById(
      selectedNoteData?.projectNotesId
    )
    if (response?.status === STATUS.ok) {
      setShowDeleteModal(false)
      setConfirmLoading(false)
      toastNotify(TOAST.success, response?.data?.message)
      getProjectNotesList(currentStatusFilterValue)
    } else {
      setConfirmLoading(false)
      toastNotify(
        TOAST.error,
        response?.data?.message ||
          TOASTIFY_MESSAGES.failedProjectNoteOperationMessage('delete')
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedNoteData])

  const handleDeleteModalCancel = () => {
    setShowDeleteModal(false)
  }

  const columns = [
    {
      title: 'Status',
      dataIndex: 'isChecked',
      ellipsis: true,
      width: '5%',
      render: (isChecked, record) => {
        return (
          <Checkbox
            className="note-status-checkbox"
            defaultChecked={isChecked}
            onChange={
              record.projectNotesId
                ? (e) => statusChangeHanlder(e.target.checked, record)
                : (e) => {
                    record.isChecked = e.target.checked
                  }
            }
          />
        )
      },
    },
    {
      title: 'Description',
      dataIndex: 'notesDescription',
      ellipsis: true,
      width: '60%',
      render: (notesDescription, record) => {
        return (
          <InputTextArea
            className="note-description"
            name="client"
            existingValue={notesDescription}
            placeholder="Enter note details..."
            type="text"
            onChange={
              record.projectNotesId
                ? (e) => debouncedUpdate(e.target.value, record)
                : (e) => debouncedAdd(e.target.value, record)
            }
            required
          />
        )
      },
    },
    {
      title: 'Updated By',
      dataIndex: 'lastUpdatedByName',
      ellipsis: true,
      width: '15%',
      render: (lastUpdatedByName) => lastUpdatedByName || '-',
    },
    {
      title: 'Last Update',
      dataIndex: 'updatedAt',
      ellipsis: true,
      width: '15%',
      render: (updatedAt, record) => {
        const currentUTCDateString = new Date().toLocaleString('en-US', {
          timeZone: 'UTC',
        })
        return (
          <Tooltip title={updatedAt}>
            {updatedAt
              ? formatDistance(
                  new Date(updatedAt),
                  new Date(currentUTCDateString),
                  {
                    addSuffix: true,
                  }
                )
              : record?.createdAt
              ? formatDistance(
                  new Date(record?.createdAt),
                  new Date(currentUTCDateString),
                  {
                    addSuffix: true,
                  }
                )
              : '-'}
          </Tooltip>
        )
      },
    },
    {
      title: 'Action',
      width: '8%',
      ellipsis: true,
      render: (_, record, index) => {
        return (
          <Button
            title={'Delete Note'}
            onClick={() => deleteNoteHandler(record)}
            icon={<DeleteIcon />}
          />
        )
      },
    },
  ]

  return (
    <>
      <DataTable
        dataSource={projectNotesTableData}
        className="techstack-list-table"
        rowClassName={(record) =>
          !record?.projectNotesId ? 'highlighted-row' : ''
        }
        columns={columns}
        rowKey={(record) => record?.projectNotesId}
        pagination={false}
        scroll={{ y: 550 }}
        footer={() => (
          <TablePagination
            page={projectNotesList?.paginationDetails?.pageNo}
            pageSize={projectNotesList?.paginationDetails?.pageSize}
            totalPages={projectNotesList?.paginationDetails?.totalPages}
            totalElements={projectNotesList?.paginationDetails?.totalElements}
            onTablePaginationChange={onTablePaginationChange}
          />
        )}
      />
      <Modal
        title="Do you want to delete this project note?"
        open={showDeleteModal}
        onOk={handleDeleteModalOk}
        onCancel={handleDeleteModalCancel}
        okText="Yes"
        cancelText="No"
        confirmLoading={confirmLoading}
      >
        <p>
          {' '}
          <span className="bold-text">Status</span>:{' '}
          {selectedNoteData?.isChecked ? 'Completed' : 'Pending'}
        </p>
        <p>
          <span className="bold-text">Description</span>:{' '}
          {selectedNoteData?.notesDescription}
        </p>
        <p>
          <span className="bold-text">Last Updated By</span>:{' '}
          {selectedNoteData?.lastUpdatedByName}
        </p>
      </Modal>
    </>
  )
}

export default ProjectNotesListTable
