import React from 'react'
import { Form, Input } from 'antd'

import './InputTextArea.scss'

const InputTextArea = ({
  placeholder,
  required,
  name,
  disabled,
  rules,
  existingValue,
  className,
  onChange,
  type,
  label,
  minRows,
  maxRows
}) => {
  return (
    <Form.Item
      className={`text-field ${className}`}
      required={required}
      name={name}
      label={label}
      rules={rules}
      initialValue={existingValue}
    >
      <Input.TextArea
        autoSize={{ minRows: minRows || 3, maxRows: maxRows || 3 }}
        onChange={onChange}
        placeholder={placeholder}
        type={type}
        disabled={disabled}
        defaultValue={existingValue}
      />
    </Form.Item>
  )
}

export default InputTextArea
