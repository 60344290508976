import React from 'react'
import Button from '../Button/Button'
import { ReactComponent as FilterIcon } from '../../assets/icons/filter.svg'
import './Filter.scss'

const Filter = ({ openFilter, setOpenFilter = () => {} }) => (
  <Button
    className="filter-btn"
    icon={<FilterIcon />}
    onClick={() => setOpenFilter(!openFilter)}
  />
)
export default Filter
