import React, { Suspense } from 'react'
import { Layout, Spin } from 'antd'
import { Redirect } from 'react-router'
import { routes } from '../../config/routes'
import AppHeader from '../Header/Header'
import Sidebar from '../Sidebar/Sidebar'
import { useCallback } from 'react'

const { Content } = Layout

function ProtectedRoute({ route }) {
  const currentUserData = JSON.parse(localStorage.getItem('userData'))
  const renderLayout = useCallback(
    (val) => {
      if (val) {
        return currentUserData?.token ? (
          <route.component />
        ) : (
          <Redirect to={routes?.loginpage?.path} />
        )
      } else {
        return currentUserData?.token && route.path === '/' ? (
          <Redirect to={routes?.projectlist?.path} />
        ) : (
          <route.component />
        )
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentUserData?.token]
  )

  return (
    <Layout>
      {!route.noHeader && <AppHeader />}
      <div
        className={!route.noHeader ? 'page-wrapper' : 'no-header-page-wrapper'}
      >
        <Layout>
          {!route.noSidebar && <Sidebar />}
          <Suspense
            className="suspense"
            fallback={
              <div className="fallback-spin">
                <Spin />
              </div>
            }
          >
            <Layout>
              <div className="page-content">
                <Content className="site-layout-background">
                  {renderLayout(route.secure)}
                </Content>
              </div>
            </Layout>
          </Suspense>
        </Layout>
      </div>
    </Layout>
  )
}

export default ProtectedRoute
