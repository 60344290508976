import { createSlice } from '@reduxjs/toolkit'
import { resetAllReduxStates } from './resetAllReduxStatesAction'

const initialState = {
  userList: {},
}
const userListSlice = createSlice({
  name: 'userList',
  initialState,
  reducers: {
    setUserList: (state, { payload }) => {
      state.userList = payload
    },
    setSwitchToggle: (state, { payload }) => {
      const userClassicViews = state?.userList?.userClassicViews?.map(
        (user) => {
          if (user?.email === payload.email) {
            return { ...user, verified: false }
          }
          return { ...user }
        }
      )
      state.userList = { ...state.userList, userClassicViews }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetAllReduxStates, () => initialState)
  },
})

export const { setUserList, setSwitchToggle } = userListSlice.actions

export const userListSelector = (state) => state.userList

export default userListSlice.reducer
