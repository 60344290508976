import React, { useRef, useState, useEffect, Suspense } from 'react'
import { Form, Spin } from 'antd'
import './ReceivedInvoice.scss'
import { DrawerModule, CustomizeRenderEmpty } from '../index'
import {
  getFilteredRevenueListService,
  getPredictionDetailsById,
  updateRevenueDetails,
} from '../../utils/api/predictionAndInvoiceServices'
import { useDispatch, useSelector } from 'react-redux'
import {
  DEFAULT_PAGE_NO,
  DEFAULT_PAGE_SIZE,
  DEFAULT_REV_FILTER,
  STATUS,
  TOAST,
} from '../../utils/constants/constant'
import { staticOptionsSelector } from '../../redux/reducer/getOptionsSlice'
import SearchInvoiceField from '../SearchInvoiceField/SearchInvoiceField'
import { suggestionsOptionsApiService } from '../../utils/api/staticOptionsServices'
import { toastNotify } from '../../utils/helperFunctions/toastNotify'

const RevenueListTable = React.lazy(() =>
  import('../RevenueListTable/RevenueListTable')
)

const ReceivedInvoice = ({ open, onClose }) => {
  const [dataSource, setDataSource] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [autoSearchOptions, setAutoSearchOptions] = useState([])
  const inputRef = useRef(null)
  const { projectId } = useSelector((state) => state.projectId)
  const { invoiceNumberOptions } = useSelector(staticOptionsSelector)
  const [searchFormInstance] = Form.useForm()
  const invoiceNumber = Form.useWatch('searchInvoice', searchFormInstance)
  const dispatch = useDispatch()

  useEffect(() => {
    inputRef.current.input.value = null
    // eslint-disable-next-line react-hooks/exhaustive-deps
    // onClose dependency reset invoice number field
  }, [onClose])
  const onSearchInvoiceNumber = async () => {
    setIsLoading(true)
    const response = await getFilteredRevenueListService(
      DEFAULT_PAGE_NO,
      DEFAULT_PAGE_SIZE,
      {
        ...DEFAULT_REV_FILTER,
        invoiceNumber: [invoiceNumber],
        receivedMonth: [],
      }
    )
    if (response?.status === STATUS.ok) {
      const data = response?.data?.data
      setDataSource(data)
    }
    setIsLoading(false)
  }

  const fieldChangeHandler = async (field, value, record) => {
    const data =
      field === 'invoiceDueDate'
        ? {
            ...record,
            invoiceDetails: {
              ...record.invoiceDetails,
              invoiceDueDate: value,
            },
          }
        : { ...record, [field]: value }
    const response = await updateRevenueDetails(data)
    if (response?.status === STATUS.ok) {
      const data = response?.data?.data
      const updatedRevenueList = dataSource?.revenueDetails?.map((revenue) => {
        if (revenue?.billingId === data?.billingId) {
          return { ...data }
        } else {
          return revenue
        }
      })
      setDataSource((prevDatasource) => {
        return { ...prevDatasource, revenueDetails: updatedRevenueList }
      })
    } else {
      response?.data?.message && toastNotify(TOAST.error, response.data.message)
    }
  }

  const onCloseReceiveModule = () => {
    projectId && dispatch(getPredictionDetailsById(projectId))
    setDataSource(null)
    setIsLoading(false)
    onClose()
  }
  return (
    <DrawerModule
      className="invoice-received-wrapper"
      size="100%"
      title={'Received Invoices'}
      open={open}
      onClose={onCloseReceiveModule}
    >
      <Form
        form={searchFormInstance}
        onFinish={(value) => {
          if (!value.searchInvoice) return setDataSource(null)
          onSearchInvoiceNumber(value.searchInvoice)
        }}
      >
        <SearchInvoiceField
          label={'Search Invoice'}
          searchFormInstance={searchFormInstance}
          onClear={() => setDataSource(null)}
          invoiceNumberOptions={invoiceNumberOptions}
          inputRef={inputRef}
          onChange={async (value) => {
            const invoiceNumbers = await suggestionsOptionsApiService(
              'invoiceNumber',
              value
            )
            setAutoSearchOptions(invoiceNumbers)
          }}
          autoSearchOptions={autoSearchOptions}
          setAutoSearchOptions={setAutoSearchOptions}
        />
      </Form>

      <Suspense fallback={<Spin spinning={isLoading} />}>
        {dataSource?.revenueDetails?.length > 0 ? (
          <div className="receive-invoice">
            <div className="receive-invoice-wrap">
              <RevenueListTable
                revenueList={dataSource}
                fieldChangeHandler={fieldChangeHandler}
                fetchRevenueList={onSearchInvoiceNumber}
                isFilterRequired={false}
              />
            </div>
          </div>
        ) : (
          dataSource?.revenueDetails?.length === 0 && (
            <CustomizeRenderEmpty
              setDataSource={setDataSource}
              inputRef={inputRef}
            />
          )
        )}
      </Suspense>
    </DrawerModule>
  )
}

export default ReceivedInvoice
