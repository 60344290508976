import './Card.scss'
import React, { useState } from 'react'
import { Card, Col, Row, Spin } from 'antd'
import { getContractDocumentByProjectId } from '../../utils/api/projectServices'
import CustomizeModal from '../Modal/Modal'
import { saveAs } from 'file-saver'
import { STATUS, TOAST } from '../../utils/constants/constant'
import { toastNotify } from '../../utils/helperFunctions/toastNotify'

const CardComponent = ({ title, data, extra, className, ...res }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [documentURLOrData, setDocumentURLOrData] = useState()
  const [embedType, setEmbedType] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [documentName, setDocumentName] = useState()

  const showDocumentHandler = async (fileName) => {
    setIsModalOpen(true)
    setIsLoading(true)
    const response = await getContractDocumentByProjectId(fileName)
    if (response?.status === STATUS.ok) {
      const blob = new Blob([response.data], {
        type: response.headers['content-type'],
      })
      const url = URL.createObjectURL(blob)

      setEmbedType(response.headers['content-type'])
      setDocumentURLOrData(url)
    } else {
      response?.data?.message && toastNotify(TOAST.error, response.data.message)
      setIsModalOpen(false)
    }
    setIsLoading(false)
  }
  const handleDownloadDocument = (e) => {
    saveAs(documentURLOrData, documentName)
  }

  const handleModalCancel = () => {
    setEmbedType()
    setDocumentURLOrData()
    setIsModalOpen(false)
  }
  return (
    <div className="card-wrap">
      <Card title={title} bordered={false} extra={extra}>
        <Row gutter={[24, 20]}>
          {data.map((item) => {
            return (
              <Col sm={12} md={12} lg={8} xl={5} key={item?.label}>
                <span className="title">{item?.label}</span>
                <h3
                  className={
                    item?.label?.startsWith('Contract Document') &&
                    item?.value !== '-'
                      ? className
                      : null
                  }
                  onClick={() => {
                    if (item?.label?.startsWith('Contract Document')) {
                      if (item?.value?.startsWith('http')) {
                        // Open URL in a new tab
                        window.open(item.value, '_blank')
                      } else if (item.value !== '-') {
                        // Handle file using showDocumentHandler()
                        showDocumentHandler(item.value)
                        setDocumentName(item.value)
                      }
                    }
                  }}
                >
                  {item?.value}
                </h3>
                {item.children ? (
                  <div className="other-special-rate">
                    <h3>
                      <span className="title">UserName: </span>
                      {item.children.userName}
                    </h3>
                    <h3>
                      <span className="title">Price: </span>
                      {item.children.price}
                    </h3>
                  </div>
                ) : (
                  ''
                )}
              </Col>
            )
          })}
        </Row>
      </Card>
      <CustomizeModal
        title="Contract Document"
        centered={false}
        open={isModalOpen}
        size={1800}
        handleModalOk={handleDownloadDocument}
        handleModalCancel={handleModalCancel}
        setShowSearchField={setIsModalOpen}
        wrapClassName="exist-data-modal"
        okText="Download"
        cancelText="Close"
        closable={true}
        className="contract-document-modal"
      >
        <Spin spinning={isLoading}>
          <div className="embed-image ">
            <embed
              width={'100%'}
              height={'100%'}
              type={embedType}
              src={documentURLOrData}
            />
          </div>
        </Spin>
      </CustomizeModal>
    </div>
  )
}
export default CardComponent
