import { Table } from 'antd'
import React from 'react'
import './DataTable.scss'

const DataTable = ({
  dataSource,
  columns,
  pagination,
  scroll,
  footer,
  onRow,
  expandedRowRender,
  showHeader,
  className,
  rowClassName,
  rowSelection,
  rowKey,
  title,
  summary,
  onChange,
  ...rest
}) => {
  return (
    <Table
      title={title}
      className={className}
      showHeader={showHeader}
      dataSource={dataSource}
      columns={columns}
      rowClassName={rowClassName}
      rowKey={rowKey}
      pagination={pagination}
      scroll={scroll}
      footer={footer}
      onRow={onRow}
      showSorterTooltip={false}
      expandable={expandedRowRender}
      rowSelection={rowSelection}
      summary={summary}
      onChange={onChange}
    />
  )
}

export default DataTable
