import { createSlice } from '@reduxjs/toolkit'
import { resetAllReduxStates } from './resetAllReduxStatesAction'

const initialState = {
  techStackRates: [],
  specialRateDetails: [],
  startDate: '',
  endDate: '',
  dateRangeOptions: [],
}
const userListSlice = createSlice({
  name: 'rateCardDetails',
  initialState,
  reducers: {
    setRateCardDetails: (state, { payload }) => {
      state.techStackRates = payload.techStackRates
      state.specialRateDetails = payload.specialRateDetails
      state.startDate = payload.startDate
      state.endDate = payload.endDate
      state.dateRangeOptions = payload.dateRangeOptions
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetAllReduxStates, () => initialState)
  },
})

export const { setRateCardDetails } = userListSlice.actions

export const rateCardDetailsSelector = (state) => state.rateCardDetails

export default userListSlice.reducer
