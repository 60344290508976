import { createSlice } from '@reduxjs/toolkit'
import { resetAllReduxStates } from './resetAllReduxStatesAction'
import { DEFAULT_REV_FILTER } from '../../utils/constants/constant'
import { setAllAccountantState, setAllCsmState } from './setCsmAccountantAction'

const initialState = {
  revenueList: [],
  appliedFilter: DEFAULT_REV_FILTER,
}

const revenueListSlice = createSlice({
  name: 'revenueList',
  initialState,
  reducers: {
    clearCache: (state) => {
      state.revenueList = []
    },
    setRevenueList: (state, { payload }) => {
      state.revenueList = payload
    },
    setAppliedFilter: (state, { payload }) => {
      state.appliedFilter = payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(resetAllReduxStates, () => initialState)
      .addCase(setAllCsmState, (state, { payload }) => {
        state.appliedFilter = {
          ...DEFAULT_REV_FILTER,
          csmEmail: [payload.email],
        }
      })
      .addCase(setAllAccountantState, (state, { payload }) => {
        state.appliedFilter = {
          ...DEFAULT_REV_FILTER,
          accountantEmail: [payload.email],
        }
      })
  },
})

export const { setRevenueList, setAppliedFilter, clearCache } =
  revenueListSlice.actions

export const revenueListSelector = (state) => state.revenueList

export default revenueListSlice.reducer
