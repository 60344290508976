import { Col, Row } from 'antd'
import React, { useEffect } from 'react'
import AutoFieldComponent from '../AutoComplete/AutoComplete'
import SelectField from '../SelectField/SelectField'
import './ProjectDetailField.scss'
import { PLACEHOLDER_MESSAGES } from '../../utils/constants/messages'
const ProjectDetailField = ({
  projectName,
  projectModel,
  payModel,
  updateValue,
}) => {
  useEffect(() => {
    updateValue && updateValue()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectName, projectModel, payModel])
  return (
    <div className="project-details">
      <h3>Project Details</h3>
      <Row gutter={40}>
        <Col sm={12} md={12} lg={12}>
          <AutoFieldComponent
            label="Project Name"
            placeholder={PLACEHOLDER_MESSAGES.projectNameMessage}
            name="projectName"
            type="text"
            disabled
            existingValue={projectName}
          />
        </Col>
        <Col sm={12} md={12} lg={12}>
          <SelectField
            label="Project Model"
            placeholder={PLACEHOLDER_MESSAGES.projectModelMessage}
            type="text"
            name="projectModel"
            disabled
            existingValue={projectModel}
          />
        </Col>
        <Col lg={12} md={12} sm={12}>
          <SelectField
            name="payModel"
            label="Pay Model"
            existingValue={payModel}
            placeholder={PLACEHOLDER_MESSAGES.payModelMessage}
            type="text"
            disabled
          />
        </Col>
      </Row>
    </div>
  )
}

export default ProjectDetailField
