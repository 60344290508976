import React, { useEffect, useState } from 'react'

import DataTable from '../DataTable/DataTable'
import { Table } from 'antd'
import Button from '../Button/Button'
import { leavesBreakColumn } from '../TimeSheetModule/TimeSheetColumns'

const LeavesTable = ({
  leaveDetails,
  recalculateLeaves,
  setShowDetailsModal,
}) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [selectedRows, setSelectedRows] = useState([])
  const [updatedLeavesRow, setUpdatedLeavesRow] = useState(leaveDetails)

  const handleCheckboxChange = (selectedRowKeys, selectedRows) => {
    const updatedSelectedRows = leaveDetails?.map((row) => {
      return {
        ...row,
        approved: selectedRowKeys.includes(row.date),
      }
    })
    setUpdatedLeavesRow(updatedSelectedRows)
    setSelectedRowKeys(selectedRowKeys)
    setSelectedRows(selectedRows)
  }

  useEffect(() => {
    const selectedApprovedRows = leaveDetails?.filter(
      (leave) => leave?.approved
    )
    const selectLeaves = selectedApprovedRows?.map((row) => row?.date)
    setSelectedRows(selectedApprovedRows)
    setSelectedRowKeys(selectLeaves)
  }, [leaveDetails])

  const rowSelection = {
    onChange: handleCheckboxChange,
    selectedRowKeys: selectedRowKeys,
  }
  const calculateTotalApprovedDays = () => {
    return selectedRows.reduce((acc, curr) => {
      return acc + curr.numberOfDays
    }, 0)
  }
  return (
    <div>
      <DataTable
        dataSource={leaveDetails}
        columns={leavesBreakColumn}
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
        rowKey={(record) => record?.date}
        pagination={false}
        scroll={{ y: 300 }}
        summary={() => {
          return (
            <>
              <Table.Summary.Row className="bold-text">
                <Table.Summary.Cell />
                <Table.Summary.Cell>Total Approved</Table.Summary.Cell>
                <Table.Summary.Cell className="bold-text">
                  {calculateTotalApprovedDays()?.toFixed(2)}
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </>
          )
        }}
      />
      <div className="add-button">
        <Button
          label={'Save Changes'}
          type="primary"
          onClick={() => {
            recalculateLeaves(calculateTotalApprovedDays(), updatedLeavesRow)
            setShowDetailsModal(false)
          }}
        />
      </div>
    </div>
  )
}

export default LeavesTable
