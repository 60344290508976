import React from 'react'
import { Select, Typography } from 'antd'
import { useDispatch, useSelector } from 'react-redux'

import './GroupSelectField.scss'
import Button from '../Button/Button'

import { staticOptionsSelector } from '../../redux/reducer/getOptionsSlice'
import {
  setAllAccountantState,
  setAllCsmState,
} from '../../redux/reducer/setCsmAccountantAction'
import {
  setSelectedUserDetails,
  userAccessManagementSelector,
} from '../../redux/reducer/userAccessManagementSlice'
import { resetAllReduxStates } from '../../redux/reducer/resetAllReduxStatesAction'

const { Option, OptGroup } = Select
const { Text } = Typography

const GroupSelectField = () => {
  const dispatch = useDispatch()
  const { selectedUserName } = useSelector(userAccessManagementSelector)
  const { csmOptions, accountantOptions } = useSelector(staticOptionsSelector)
  // handle select csm/accountant user
  const handleChange = (value, option) => {
    const group = option['data-group']
    const selectedOption = JSON.parse(value)
    dispatch(
      setSelectedUserDetails({
        selectedUserName: selectedOption.name,
        selectedUserRole: group,
      })
    )
    if (group === 'csm') {
      dispatch(setAllCsmState(selectedOption))
    } else {
      dispatch(setAllAccountantState(selectedOption))
    }
  }

  const renderOptions = (options, group) =>
    options?.map((option) => (
      <Option key={option.id} value={JSON.stringify(option)} data-group={group}>
        {option.name}
      </Option>
    ))

  const handleReset = () => {
    dispatch(resetAllReduxStates())
  }

  return (
    <Select
      className="group-select-field"
      style={{ width: 300 }}
      placeholder="Select CSM or Accountant"
      onChange={handleChange}
      value={selectedUserName}
      dropdownRender={(menu) => (
        <div className="group-select-dropdown">
          {menu}
          <div className="dropdown-footer">
            <Text type="secondary">
              Select from CSM or Accountant options
              <Button
                className="reset-button"
                label="Reset"
                danger
                onClick={handleReset}
                disabled={!selectedUserName}
              />
            </Text>
          </div>
        </div>
      )}
    >
      <OptGroup label="CSM">{renderOptions(csmOptions, 'csm')}</OptGroup>
      <OptGroup label="Accountant">
        {renderOptions(accountantOptions, 'accountant')}
      </OptGroup>
    </Select>
  )
}

export default GroupSelectField
