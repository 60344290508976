import { createSlice } from '@reduxjs/toolkit'
import { resetAllReduxStates } from './resetAllReduxStatesAction'
const initialState = {
  techStackList: [],
}
const techStackListSlice = createSlice({
  name: 'techStackList',
  initialState,
  reducers: {
    setTechStackList: (state, { payload }) => {
      state.techStackList = payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetAllReduxStates, () => initialState)
  },
})

export const { setTechStackList } = techStackListSlice.actions

export const techStackListSelector = (state) => state.techStackList

export default techStackListSlice.reducer
