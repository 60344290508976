import { createSlice } from '@reduxjs/toolkit'
import { resetAllReduxStates } from './resetAllReduxStatesAction'
const initialState = {
  leaveBalanceList: [],
}
const leaveBalanceSlice = createSlice({
  name: 'leaveBalanceList',
  initialState,
  reducers: {
    setLeaveBalanceList: (state, { payload }) => {
      state.leaveBalanceList = payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetAllReduxStates, () => initialState)
  },
})

export const { setLeaveBalanceList } = leaveBalanceSlice.actions

export const leaveBalanceSelector = (state) => state.leaveBalanceList

export default leaveBalanceSlice.reducer
