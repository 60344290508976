import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  fetchProjectOptionsService,
  fetchRegionBasedCompanyOptionsService,
  fetchSalesPersonsOptionsService,
  suggestionsOptionsApiService,
} from '../api/staticOptionsServices'
import { getClientOptions } from '../getOptions'
import { STATUS } from '../constants/constant'

//  fetch company provider & region options
const fetchRegionProviderOptions = async () => {
  const response = await fetchRegionBasedCompanyOptionsService()
  if (response?.status === STATUS.ok) {
    return response?.data?.data
  } else {
    return {
      provider: [],
      region: [],
    }
  }
}

// Define an object to map option names to their corresponding API functions
const optionFetchers = {
  companyOptions: () => suggestionsOptionsApiService('company'),
  clientNameOptions: getClientOptions,
  companyCountry: () => suggestionsOptionsApiService('companyCountry'),
  companyState: () => suggestionsOptionsApiService('companyState'),
  companyCity: () => suggestionsOptionsApiService('companyCity'),
  displayNameOptions: () => suggestionsOptionsApiService('displayName'),
  projectOptions: fetchProjectOptionsService,
  salesPersonsOptions: fetchSalesPersonsOptionsService,
  regionProviderOptions : fetchRegionProviderOptions
}

export const fetchAllOptions = createAsyncThunk(
  'staticOptions/fetchAllOptions',
  async () => {
    try {
      const results = await Promise.allSettled(
        Object.entries(optionFetchers).map(([optionName, fetcher]) =>
          fetcher()
            .then((value) => ({ status: 'fulfilled', value }))
            .catch((reason) => ({ status: 'rejected', reason }))
        )
      )

      const fulfilledOptions = {}

      results.forEach(({ status, value }, index) => {
        if (status === 'fulfilled') {
          fulfilledOptions[Object.keys(optionFetchers)[index]] = value.value
        } else {
          fulfilledOptions[Object.keys(optionFetchers)[index]] = []
        }
      })
      return fulfilledOptions
    } catch (error) {
      throw error
    }
  }
)
                         