import React from 'react'
import { Popover } from 'antd'

import './ccBccPopOver.scss'
import Button from '../Button/Button'
import InputField from '../InputField/InputField'
import { ccBccEmailRule } from '../../utils/constants/validationRules'
import { PLACEHOLDER_MESSAGES } from '../../utils/constants/messages'

const CcBccPopOver = ({ isEditMode, companyEditableData }) => {
  const popOverBox = (
    <div>
      <InputField
        name="cc"
        label={'Cc'}
        existingValue={isEditMode && companyEditableData?.cc}
        placeholder={PLACEHOLDER_MESSAGES.companyEmailMessage}
        rules={ccBccEmailRule(true)}
      />
      <InputField
        name="bcc"
        label={'Bcc'}
        existingValue={isEditMode && companyEditableData?.bcc}
        placeholder={PLACEHOLDER_MESSAGES.companyEmailMessage}
        rules={ccBccEmailRule(false)}
      />
    </div>
  )
  return (
    <Popover placement="bottomRight" content={popOverBox} trigger={'click'}>
      <Button className="cc-bcc-btn" label={'CC/BCC'} />
    </Popover>
  )
}

export default CcBccPopOver
