import React, { useEffect, useState } from 'react'
import { DrawerModule, GenerateInvoiceModal } from '..'
import { removeItemFromLocalStorage } from '../../utils/helperFunctions/localStorageHelperFunctions'
import './AddInvoiceOrPredictionModule.scss'
import AddMultiplePredictions from './AddMultiplePredictions'

const AddInvoiceOrPredictionModule = ({
  open,
  onClose,
  isCurrentPrediction,
  todoProjectId,
  fetchTodoList,
  showAddInvoiceModal,
  setShowAddInvoiceModal,
  isTMretainerPrepaidModel,
  currencySymbol,
}) => {
  const [isPopupVisible, setIsPopupVisible] = useState(false)
  useEffect(() => {
    removeItemFromLocalStorage('existingPredictionsData')
    removeItemFromLocalStorage('existingInvoicesForInvoiceDateData')
    removeItemFromLocalStorage('existingInvoicesForInvoiceNumberData')
  }, [isCurrentPrediction])

  const existingPredictionsColumns = [
    {
      title:
        'There are some already existing data between the dates you have entered,Do you want to continue? ',
      dataIndex: 'url',
      sorter: false,
      ellipsis: true,
      render: (text) => {
        return (
          <a
            className="prediction-url"
            onClick={(event) => event.stopPropagation()}
            href={text}
            target="_blank"
            rel="noopener noreferrer"
          >
            {text}
          </a>
        )
      },
    },
    {
      title: 'Start Date',
      dataIndex: 'startdate',
      sorter: false,
      ellipsis: true,
      width: 120,
    },
    {
      title: 'End Date',
      dataIndex: 'enddate',
      sorter: false,
      ellipsis: true,
      width: 120,
    },
  ]

  return (
    <DrawerModule
      open={open}
      onClose={onClose}
      title={isCurrentPrediction ? 'Add Prediction' : 'Add invoice'}
      closable={!isPopupVisible}
      size={'600px'}
    >
      {isCurrentPrediction ? (
        <AddMultiplePredictions
          onClose={onClose}
          existingPredictionsColumns={existingPredictionsColumns}
          setIsPopupVisible={setIsPopupVisible}
          todoProjectId={todoProjectId}
          fetchTodoList={fetchTodoList}
          isTMretainerPrepaidModel={isTMretainerPrepaidModel}
          currencySymbol={currencySymbol || '$'}
        />
      ) : (
        <GenerateInvoiceModal
          showGenerateInvoiceModal={showAddInvoiceModal}
          setShowGenerateInvoiceModal={setShowAddInvoiceModal}
        />
      )}
    </DrawerModule>
  )
}
export default AddInvoiceOrPredictionModule
