import { createSlice } from '@reduxjs/toolkit'
import { fetchAllOptions } from '../../utils/helperFunctions/fetchAllOptions'

const initialState = {
  invoiceStatusOptions: [],
  financialTypeOptions: [],
  invoiceCycleOptions: [],
  accountantOptions: [],
  csmOptions: [],
  userRoleOptions: [],
  salesPersonOptions: [],
  companyOptions: [],
  leadSourceOptions: [],
  activeBillingOptions: [],
  marketingChannelOptions: [],
  projectModelOptions: [],
  accTypeOptions: [],
  payModelOptions: [],
  accuracyOptions: [],
  invoiceNumberOptions: [],
  projectNameOptions: [],
  clientNameOptions: [],
  companyCountryOptions: [],
  companyStateOptions: [],
  companyCityOptions: [],
  projectCodeOptions: [],
  technologyOptions: [],
  salesCategoriesOptions: [],
  salesCountryOptions: [],
  displayNameOptions: [],
  invoiceLineItemOptions: [],
  companyRegionsOptions: {},
  companyproviderOptions: [],
  allProviderOptions: [],
  submissionTypeOptions: [],
  allocationTypeOptions: [],
  currencyOptions: [],
  status: 'idle',
  error: null,
}
const getStaticOptionsSlice = createSlice({
  name: 'staticOptions',
  initialState,
  reducers: {
    setProjectBasedOptions: (state, { payload }) => {
      state.invoiceStatusOptions = payload.invoiceStatuses
      state.financialTypeOptions = payload.financialTypes
      state.invoiceCycleOptions = payload.invoiceCycles
      state.accountantOptions = payload.accountantNames
      state.csmOptions = payload.csmNames
      state.userRoleOptions = payload.userRoleNames
      state.leadSourceOptions = payload.leadSources
      state.activeBillingOptions = payload.activeBillingFlags
      state.marketingChannelOptions = payload.marketingChannels
      state.projectModelOptions = payload.projectModels
      state.accTypeOptions = payload.accTypes
      state.payModelOptions = payload.payModels
      state.accuracyOptions = payload.accuracies
      state.invoiceNumberOptions = payload.invoiceNumbers
      state.projectNameOptions = payload.projectNames
      state.projectCodeOptions = payload.projectCodes
      state.submissionTypeOptions = payload.submissionTypes
      state.allocationTypeOptions = payload.resourceAllocation
      state.currencyOptions = payload.currency
    },
    setCompanyBasedOptions: (state, { payload }) => {
      state.companyOptions = payload.companyOptions
      state.clientNameOptions = payload.clientNameOptions
      state.companyCountryOptions = payload.companyCountry
      state.companyStateOptions = payload.companyState
      state.companyCityOptions = payload.companyCity
      state.displayNameOptions = payload.displayNameOptions
    },
    setSalesPersonsBasedOptions: (state, { payload }) => {
      state.salesPersonOptions = payload.salesPersons
      state.salesCountryOptions = payload.countries
      state.salesCategoriesOptions = payload.categories
      state.userRoleOptions = payload.zohoRoleNames
      state.technologyOptions = payload.technologyNames
    },
    setInvoiceLineItemOptions: (state, { payload }) => {
      state.invoiceLineItemOptions = payload
    },
    setIdentityProviderOptions: (state, { payload }) => {
      state.allProviderOptions = payload.allProviderOptions
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllOptions.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(fetchAllOptions.fulfilled, (state, { payload }) => {
        state.status = 'succeeded'
        state.invoiceStatusOptions = payload.projectOptions.invoiceStatuses
        state.financialTypeOptions = payload.projectOptions.financialTypes
        state.invoiceCycleOptions = payload.projectOptions.invoiceCycles
        state.accountantOptions = payload.projectOptions.accountantNames
        state.csmOptions = payload.projectOptions.csmNames
        state.userRoleOptions = payload.projectOptions.userRoleNames
        state.salesPersonOptions = payload.salesPersonsOptions.salesPersons
        state.companyOptions = payload.companyOptions
        state.leadSourceOptions = payload.projectOptions.leadSources
        state.activeBillingOptions = payload.projectOptions.activeBillingFlags
        state.marketingChannelOptions = payload.projectOptions.marketingChannels
        state.projectModelOptions = payload.projectOptions.projectModels
        state.accTypeOptions = payload.projectOptions.accTypes
        state.payModelOptions = payload.projectOptions.payModels
        state.accuracyOptions = payload.projectOptions.accuracies
        state.invoiceNumberOptions = payload.projectOptions.invoiceNumbers
        state.projectNameOptions = payload.projectOptions.projectNames
        state.clientNameOptions = payload.clientNameOptions
        state.companyCountryOptions = payload.companyCountry
        state.companyStateOptions = payload.companyState
        state.companyCityOptions = payload.companyCity
        state.projectCodeOptions = payload.projectOptions.projectCodes
        state.technologyOptions = payload.salesPersonsOptions.technologyNames
        state.salesCategoriesOptions = payload.salesPersonsOptions.categories
        state.salesCountryOptions = payload.salesPersonsOptions.countries
        state.displayNameOptions = payload.displayNameOptions
        state.companyRegionsOptions = payload.regionProviderOptions
        state.companyproviderOptions = Object.keys(
          payload.regionProviderOptions
        )
        state.submissionTypeOptions = payload.projectOptions.submissionTypes
        state.allocationTypeOptions = payload.projectOptions.resourceAllocation
        state.currencyOptions = payload.projectOptions.currency
      })
      .addCase(fetchAllOptions.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      })
  },
})

export const {
  setProjectBasedOptions,
  setCompanyBasedOptions,
  setSalesPersonsBasedOptions,
  setInvoiceLineItemOptions,
  setIdentityProviderOptions,
} = getStaticOptionsSlice.actions

export const staticOptionsSelector = (state) => state.staticOptions

export default getStaticOptionsSlice.reducer
