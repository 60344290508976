import { createSlice } from '@reduxjs/toolkit'
import { resetAllReduxStates } from './resetAllReduxStatesAction'
const initialState = {
  loadedPage: 0,
  userActivities: [],
  filterObj: {},
}
const userActivitiesSlice = createSlice({
  name: 'userActivities',
  initialState,
  reducers: {
    setUserActivities: (state, { payload }) => {
      state.userActivities = [...state.userActivities, ...payload]
    },
    setFilteredActivities: (state, { payload }) => {
      state.userActivities = payload
    },
    setLoadedPage: (state, { payload }) => {
      state.loadedPage = payload
    },
    setFilterObj: (state, { payload }) => {
      state.filterObj = { ...payload }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetAllReduxStates, () => initialState)
  },
})

export const {
  setLoadedPage,
  setFilteredActivities,
  setUserActivities,
  setFilterObj,
} = userActivitiesSlice.actions

export const userActivitiesSelector = (state) => state.userActivities

export default userActivitiesSlice.reducer
