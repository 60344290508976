import { useState, useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { resetAllReduxStates } from '../../redux/reducer/resetAllReduxStatesAction'
import { setAPIHeader } from '../helperFunctions/helperFunctions'
import { logoutService } from '../api/authenticationServices'

export const useAuth = () => {
  const [token, setToken] = useState('')
  const [tokenExpiry, setTokenExpiry] = useState('')
  const [userRole, setUserRole] = useState('')
  const [userId, setUserId] = useState('')
  const history = useHistory()
  const dispatch = useDispatch()

  const currentUserData = JSON.parse(localStorage.getItem('userData'))
  useEffect(() => {
    setAPIHeader(currentUserData?.token)
    setToken(currentUserData?.token)
    setUserId(currentUserData?.userId)
    setUserRole(currentUserData?.userRole)
    setTokenExpiry(currentUserData?.tokenExpiry)
  }, [currentUserData])

  const login = useCallback((userData) => {
    const { userId, authToken, authTokenExpiry, role, isUserLoggingIn } =
      userData

    setAPIHeader(authToken)
    setTokenExpiry(authTokenExpiry)
    setToken(authToken)
    setUserId(userId)
    setUserRole(role)

    if (isUserLoggingIn) {
      localStorage.setItem(
        'userData',
        JSON.stringify({
          userId: userId,
          token: authToken,
          userRole: role,
          tokenExpiry: authTokenExpiry,
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const logout = useCallback(() => {
    logoutService()
    setAPIHeader(null, true)
    setToken(null)
    setTokenExpiry(null)
    setUserRole(null)
    setUserId(null)
    dispatch(resetAllReduxStates())
    localStorage.clear()
    if (history) {
      history.push('/')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const storageData = JSON.parse(localStorage.getItem('userData'))
    if (storageData?.token) {
      login(storageData.userId, storageData.token, storageData.userRole)
    }
  }, [login])

  return {
    login,
    logout,
    token,
    tokenExpiry,
    userId,
    userRole,
  }
}
