import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Steps } from 'antd'

import './GenerateInvoiceModal.scss'
import TimeSheetVerification from '../TimeSheetVerification/TimeSheetVerification'
import InvoiceTimesheet from '../InvoiceTimesheet/InvoiceTimesheet'
import InvoiceBooks from '../InvoiceBooks/InvoiceBooks'
import CustomizeModal from '../Modal/Modal'
import Button from '../Button/Button'
import CreateCustomInvoice from '../CreateCustomInvoice/CreateCustomInvoice'

import { timeSheetsDataSelector } from '../../redux/reducer/timeSheetsDataSlice'
import { projectDetailsSelector } from '../../redux/reducer/getProjectsByIdSlice'
import { getFormattedInvoiceData } from '../../utils/api/projectServices'
import { STATUS, TOAST } from '../../utils/constants/constant'
import { toastNotify } from '../../utils/helperFunctions/toastNotify'
import {
  removeExtraFieldsFromTimesheetData,
  shallowCopyObject,
} from '../../utils/helperFunctions/helperFunctions'
import { setInvoiceLineItemOptions } from '../../redux/reducer/getOptionsSlice'
import { TOASTIFY_MESSAGES } from '../../utils/constants/messages'
import { fetchRegionBasedCompanyOptionsService } from '../../utils/api/staticOptionsServices'

const { Step } = Steps

const GenerateInvoiceModal = ({
  showGenerateInvoiceModal,
  setShowGenerateInvoiceModal,
  predictionData,
  todoVerificationId,
  viewInvoiceTimesheetName,
  isTMretainerPrepaidModel,
  viewInvoiceId,
  fetchRevenueList,
  defaultCurrentStep = 0,
}) => {
  const [customTimesheetNameFormat, setCustomTimesheetNameFormat] = useState('')
  const [currentStep, setCurrentStep] = useState(defaultCurrentStep)
  const [invoiceBooksData, setInvoiceBooksData] = useState()
  const [isFormattingTimesheet, setIsFromattingTimesheet] = useState(false)
  const [isCustomInvoice, setIsCustomInvoice] = useState(false)
  const [isEmployeeNameNull, setIsEmployeeNameNull] = useState(false)
  const [isServiceNull, setIsServiceNull] = useState(false)
  const [isZohoTimesheeetChange, setIsZohoTimesheetChange] = useState(false)
  const { timeSheetsData } = useSelector(timeSheetsDataSelector)
  const dispatch = useDispatch()
  const isUserCreatingCustomInvoice =
    !predictionData && !viewInvoiceTimesheetName
  const showVerificationStep = predictionData && !viewInvoiceTimesheetName

  const { projectDetails } = useSelector(projectDetailsSelector)
  const timesheetNameFormat =
    viewInvoiceTimesheetName ||
    customTimesheetNameFormat ||
    `${projectDetails?.projectId}_${predictionData?.startDate}_${
      predictionData?.endDate || 'noEndDate'
    }`

  const currentInvoiceTimesheetData =
    timeSheetsData && timeSheetsData[timesheetNameFormat]
  const fetchInvoiceLineItemOptions = async () => {
    const invoiceLineItemOptionsRes =
      await fetchRegionBasedCompanyOptionsService(
        currentInvoiceTimesheetData?.provider,
        currentInvoiceTimesheetData?.customerRegion
      )
    if (invoiceLineItemOptionsRes?.status === STATUS.ok) {
      dispatch(
        setInvoiceLineItemOptions(
          invoiceLineItemOptionsRes?.data?.data?.service_names
        )
      )
    } else {
      invoiceLineItemOptionsRes?.data?.message &&
        toastNotify(TOAST.error, invoiceLineItemOptionsRes?.data?.message)
    }
  }

  useEffect(() => {
    fetchInvoiceLineItemOptions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentInvoiceTimesheetData])
  useEffect(() => {
    // Check if employee names are null
    const newIsEmployeeNameNull = currentInvoiceTimesheetData
      ?.zohoEmployeeMetadataList?.length
      ? currentInvoiceTimesheetData?.zohoEmployeeMetadataList?.every(
          (data) => data?.employeeName === null
        )
      : false

    // Check if service names are null
    const newIsServiceNull = currentInvoiceTimesheetData?.invoiceLineItemList
      ?.length
      ? currentInvoiceTimesheetData?.invoiceLineItemList?.every(
          (data) => data?.name === null
        )
      : false

    setIsEmployeeNameNull(newIsEmployeeNameNull)
    setIsServiceNull(newIsServiceNull)
  }, [currentInvoiceTimesheetData])

  const getBooksInvoiceData = async () => {
    setIsFromattingTimesheet(true)
    const timeSheetData = removeExtraFieldsFromTimesheetData(
      shallowCopyObject(currentInvoiceTimesheetData),
      ['lastSyncedTime', 'timesheetAvailableEmployees']
    )
    if (
      timeSheetData?.zohoEmployeeMetadataList?.length ||
      timeSheetData?.invoiceLineItemList?.length ||
      isTMretainerPrepaidModel
    ) {
      setIsCustomInvoice(false)
      // regenerate invoice-lineitems only when zohotimesheet change in view timesheet mode
      if (showVerificationStep || isZohoTimesheeetChange) {
        const filteredTimesheetData = {
          ...timeSheetData,
          invoiceLineItemList: timeSheetData?.invoiceLineItemList.filter(
            (item) => item?.manuallyAdded
          ),
        }
        const response = await getFormattedInvoiceData(filteredTimesheetData)

        if (response?.code === STATUS.ok) {
          setInvoiceBooksData(response?.data)
          toastNotify(TOAST.success, response?.message)
        } else {
          toastNotify(
            TOAST.error,
            response?.data?.message ||
              'Failed to merge all logs according to books.'
          )
          setCurrentStep((prev) => prev - 1)
        }
      } else {
        setInvoiceBooksData(timeSheetData?.invoiceLineItemList)
      }
    } else {
      setIsCustomInvoice(true)
    }
    setIsFromattingTimesheet(false)
  }

  const onStepChange = (value) => {
    if (currentStep === 1) {
      isEmployeeNameNull &&
        toastNotify(TOAST.error, TOASTIFY_MESSAGES.employeeNameNullMessage)
      isServiceNull &&
        toastNotify(TOAST.error, TOASTIFY_MESSAGES.serviceNameNullMessage)
      if (Boolean(!isEmployeeNameNull && !isServiceNull)) {
        setCurrentStep(value)
      }
    } else {
      setCurrentStep(value)
    }
  }

  const showTimesheetStep = () => {
    return !isUserCreatingCustomInvoice && !customTimesheetNameFormat ? (
      <InvoiceTimesheet
        predictionData={predictionData}
        viewInvoiceTimesheetName={viewInvoiceTimesheetName}
        viewInvoiceId={viewInvoiceId}
        isCustomInvoice={isCustomInvoice}
        setInvoiceBooksData={setInvoiceBooksData}
        isTMretainerPrepaidModel={isTMretainerPrepaidModel}
        isServiceNull={isServiceNull}
        setIsZohoTimesheetChange={setIsZohoTimesheetChange}
      />
    ) : (
      <CreateCustomInvoice
        projectData={projectDetails}
        customTimesheetNameFormat={customTimesheetNameFormat}
        setCustomTimesheetNameFormat={setCustomTimesheetNameFormat}
        setIsCustomInvoice={setIsCustomInvoice}
      />
    )
  }

  return (
    <CustomizeModal
      open={showGenerateInvoiceModal}
      title="Create: Invoice Details"
      size="1800px"
      bodyStyle={{ height: '85vh', top: '3vh' }}
      className={'generate-invoice-modal-container'}
      closable={true}
      okButtonProps={false}
      handleModalCancel={() => {
        setShowGenerateInvoiceModal(false)
        setCustomTimesheetNameFormat('')
        setCurrentStep(0)
      }}
      maskClosable={'noClose'}
      footer={false}
    >
      <div className="steps-modal-wrapper">
        <div className="steps-container">
          <Steps
            onChange={onStepChange}
            current={currentStep}
            responsive={true}
            size="small"
          >
            {showVerificationStep && <Step title="Verification"></Step>}
            {!todoVerificationId && <Step title="Timesheet"></Step>}
            {!todoVerificationId && (
              <Step
                title="QuickBooks/ZohoBooks"
                onClick={() =>
                  Boolean(!isEmployeeNameNull && !isServiceNull) &&
                  getBooksInvoiceData()
                }
              ></Step>
            )}
          </Steps>
        </div>
        <div className="step-content-wrapper">
          {(showVerificationStep || todoVerificationId) &&
            currentStep === 0 && (
              <TimeSheetVerification
                predictionData={predictionData}
                todoVerificationId={todoVerificationId}
                setShowGenerateInvoiceModal={setShowGenerateInvoiceModal}
                isTMretainerPrepaidModel={isTMretainerPrepaidModel}
              />
            )}
          {!todoVerificationId && !showVerificationStep
            ? currentStep === 0 && showTimesheetStep()
            : currentStep === 1 && showTimesheetStep()}
          {!showVerificationStep
            ? currentStep === 1 && (
                <InvoiceBooks
                  currentInvoiceTimesheetData={currentInvoiceTimesheetData}
                  invoiceBooksData={invoiceBooksData}
                  projectData={projectDetails}
                  setShowGenerateInvoiceModal={setShowGenerateInvoiceModal}
                  isFormattingTimesheet={isFormattingTimesheet}
                  currentTimsheetNameFormat={timesheetNameFormat}
                  fetchRevenueList={fetchRevenueList}
                  isCustomInvoice={isCustomInvoice}
                  setInvoiceBooksData={setInvoiceBooksData}
                  isTMretainerPrepaidModel={isTMretainerPrepaidModel}
                />
              )
            : currentStep === 2 && (
                <InvoiceBooks
                  currentInvoiceTimesheetData={currentInvoiceTimesheetData}
                  invoiceBooksData={invoiceBooksData}
                  projectData={projectDetails}
                  setShowGenerateInvoiceModal={setShowGenerateInvoiceModal}
                  isFormattingTimesheet={isFormattingTimesheet}
                  currentTimsheetNameFormat={timesheetNameFormat}
                  isCustomInvoice={isCustomInvoice}
                  setInvoiceBooksData={setInvoiceBooksData}
                  isTMretainerPrepaidModel={isTMretainerPrepaidModel}
                />
              )}
        </div>
        {!todoVerificationId && (
          <div className="steps-footer btn-group">
            {currentStep > 0 && (
              <Button
                label="Previous"
                danger
                onClick={() => setCurrentStep((prev) => prev - 1)}
              />
            )}
            {showVerificationStep
              ? currentStep !== 2 && (
                  <Button
                    label="Next"
                    type="primary"
                    disabled={currentStep === 1 && !currentInvoiceTimesheetData}
                    onClick={() => {
                      isEmployeeNameNull &&
                        toastNotify(
                          TOAST.error,
                          TOASTIFY_MESSAGES.employeeNameNullMessage
                        )
                      isServiceNull &&
                        toastNotify(
                          TOAST.error,
                          TOASTIFY_MESSAGES.serviceNameNullMessage
                        )
                      if (Boolean(!isEmployeeNameNull && !isServiceNull)) {
                        currentStep === 1 && getBooksInvoiceData()
                        setCurrentStep((prev) => prev + 1)
                      }
                    }}
                  />
                )
              : currentStep !== 1 && (
                  <Button
                    label="Next"
                    type="primary"
                    onClick={() => {
                      getBooksInvoiceData()
                      setCurrentStep((prev) => prev + 1)
                    }}
                  />
                )}
          </div>
        )}
      </div>
    </CustomizeModal>
  )
}

export default GenerateInvoiceModal
