import React, { useEffect } from 'react'
import './LineChart.scss'
import { Line } from 'react-chartjs-2'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from 'chart.js'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
)

const getOrCreateLegendList = (chart, id = 'legend-container') => {
  const legendContainer = document.getElementById(id)
  legendContainer.style.display = 'flex'
  legendContainer.style.justifyContent = 'flex-end'
  legendContainer.style.paddingRight = '8px'
  let listContainer = legendContainer.querySelector('ul')
  if (!listContainer) {
    listContainer = document.createElement('ul')
    listContainer.style.display = 'flex'
    listContainer.style.flexDirection = 'row'
    listContainer.style.margin = 0
    listContainer.style.padding = 0
    listContainer.style.marginTop = '8px'
    legendContainer.appendChild(listContainer)
  }
  return listContainer
}
const htmlLegendPlugin = {
  id: 'htmlLegend',
  afterUpdate(chart, args, options) {
    const ul = getOrCreateLegendList(chart, options.containerID)
    ul.style.flexDirection = 'column'
    ul.style.border = '1px solid #C1C1C1'
    ul.style.paddingTop = '12px'
    ul.style.paddingBottom = '12px'
    ul.style.paddingLeft = '14px'
    ul.style.paddingRight = '14px'
    ul.style.gap = '10px'
    // Remove old legend items
    while (ul.firstChild) {
      ul.firstChild.remove()
    }
    // Reuse the built-in legendItems generator
    const items = chart.options.plugins.legend.labels.generateLabels(chart)
    items.forEach((item) => {
      const li = document.createElement('li')
      li.style.alignItems = 'center'
      li.style.cursor = 'pointer'
      li.style.display = 'flex'
      li.style.flexDirection = 'row'
      li.style.lineHeight = '17px'
      li.onclick = () => {
        const { type } = chart.config
        if (type === 'pie' || type === 'doughnut') {
          // Pie and doughnut charts only have a single dataset and visibility is per item
          chart.toggleDataVisibility(item.index)
        } else {
          chart.setDatasetVisibility(
            item.datasetIndex,
            !chart.isDatasetVisible(item.datasetIndex)
          )
        }
        chart.update()
      }
      // Color box
      const boxSpan = document.createElement('span')
      boxSpan.style.background = item.fillStyle
      boxSpan.style.borderColor = item.strokeStyle
      boxSpan.style.borderWidth = item.lineWidth + 'px'
      boxSpan.style.borderRadius = '1px'
      boxSpan.style.display = 'inline-block'
      boxSpan.style.height = '14px'
      boxSpan.style.marginRight = '10px'
      boxSpan.style.width = '14px'
      // Text
      const textContainer = document.createElement('p')
      textContainer.style.color = item.fontColor
      textContainer.style.margin = 0
      textContainer.style.padding = 0
      textContainer.style.textDecoration = item.hidden ? 'line-through' : ''
      const text = document.createTextNode(item.text)
      textContainer.appendChild(text)
      li.appendChild(boxSpan)
      li.appendChild(textContainer)
      ul.appendChild(li)
    })
  },
}
const LineChart = () => {
  useEffect(() => {}, [])
  const data = () => {
    return {
      labels: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ],
      datasets: [
        {
          label: 'Current',
          data: [233, 225, 435, 151, 554, 276, 233, 225, 435, 151, 554, 276],
          fill: 'start',
          backgroundColor: (context) => {
            const ctx = context.chart.ctx
            const gradient = ctx.createLinearGradient(0, 0, 0, 400)
            gradient.addColorStop(0, 'rgba(167, 230, 231, 0.29)')
            gradient.addColorStop(1, 'rgba(87, 186, 187, 0)')
            return gradient
          },
          borderColor: '#57BABB',
          pointBackgroundColor: '#FFFFFF',
          pointRadius: '4',
          strokeStyle: '#57BABB',
          fillStyle: '#57BABB',
        },
        {
          label: 'Last Year',
          data: [133, 253, 285, 341, 344, 465, 133, 253, 285, 341, 344, 465],
          fill: 'start',
          backgroundColor: (context) => {
            const ctx = context.chart.ctx
            const gradient = ctx.createLinearGradient(0, 0, 0, 300)
            gradient.addColorStop(0, 'rgba(255, 124, 129, 0.21)')
            gradient.addColorStop(1, 'rgba(255, 247, 247, 0)')
            return gradient
          },
          borderColor: '#FF7C81',
          pointBackgroundColor: '#FFFFFF',
          pointRadius: '4',
          fillStyle: '#FF7C81',
          strokeStyle: '#FF7C81',
        },
      ],
    }
  }

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {},
        title: {
          display: true,
          text: 'Timeline',
          color: '#3D3D3D',
          font: {
            size: 12,
            weight: 600,
            lineHeight: '15px',
          },
        },
      },
      y: {
        title: {
          display: true,
          text: 'Revenue',
          color: '#3D3D3D',
          font: {
            size: 12,
            weight: 600,
            lineHeight: '15px',
          },
        },
        ticks: {
          min: 0,
          max: 600,
          beginAtZero: true,
          maxTicksLimit: 6,
          stepSize: 150,
          padding: 12,
          callback: (value) => value,
        },
        grid: {
          display: false,
        },
      },
    },
    elements: {
      line: {
        tension: 0.35,
      },
    },
    plugins: {
      filler: {
        propagate: false,
      },
      htmlLegend: {
        containerID: 'legend-container',
      },
      legend: {
        display: false,
        position: 'top',
        align: 'end',
        reverse: true,
        fullSize: true,
        labels: {
          boxWidth: 14,
          color: '#4D4D4D',
          font: {
            size: 14,
          },
          generateLabels: (chart) => {
            return chart.data.datasets.map((d, i) => ({
              text: d.label,
              fillStyle: d.fillStyle,
              strokeStyle: d.strokeStyle,
              borderRadius: 1,
            }))
          },
        },
      },
    },
    interaction: {
      intersect: true,
    },
  }

  return (
    <>
      <div id="legend-container"></div>
      <div className="linechart-wrap">
        <Line data={data()} options={options} plugins={[htmlLegendPlugin]} />
      </div>
    </>
  )
}

export default LineChart
