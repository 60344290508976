import React from 'react'
import Button from '../Button/Button'
import CustomizeModal from '../Modal/Modal'

const InvoicePreRequisiteModal = ({
    showInvoicePreRequisiteModal,
    setShowInvoicePreRequisiteModal,
    invoicePreRequisiteData,
    fetchInvoicePreRequisiteData,
    fetchTimesheetData,
}) => {
    return (
        <CustomizeModal
            open={showInvoicePreRequisiteModal}
            className="rates-warning-modal"
            title={'Attention!'}
            centered={true}
            handleModalCancel={() => {
                setShowInvoicePreRequisiteModal(false)
            }}
            footer={
                <>
                    <Button
                        danger
                        label={'Retry'}
                        onClick={() => {
                            setShowInvoicePreRequisiteModal(false)
                            fetchInvoicePreRequisiteData()
                        }}
                    />
                    <Button
                        type="primary"
                        label={'Continue anyways'}
                        onClick={() => {
                            setShowInvoicePreRequisiteModal(false)
                            fetchTimesheetData()
                        }}
                    />
                </>
            }
        >
            <div className="value-container">
                <div>
                    {invoicePreRequisiteData?.map((item, i) => (
                        <p key={i}>
                            <span className="bold-text">{i + 1}).</span>
                            {item?.message}{item?.link && <a href={item.link} target="_blank" rel="noopener noreferrer">
                                here.
                            </a>}
                            <span className="danger-text">{item?.mandatory && '*'}</span>
                        </p>

                    ))}
                </div>
                <p><span className='bold-text'>Note:</span> <span className='danger-text'>*</span> stands for mandatory pre requisites for generating invoice. </p>
            </div>
        </CustomizeModal>
    )
}

export default InvoicePreRequisiteModal
