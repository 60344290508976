import { UploadOutlined } from '@ant-design/icons'
import { Form, Upload } from 'antd'
import React from 'react'
import Button from '../Button/Button'
import { toastNotify } from '../../utils/helperFunctions/toastNotify'
import { TOAST } from '../../utils/constants/constant'
import { TOASTIFY_MESSAGES } from '../../utils/constants/messages'

const ImageUpload = ({ setImage, image, customPreview, showUploadList }) => {
  const props = {
    multiple: false,
    accept: '.jpg,.jpeg,.png',
    showUploadList: showUploadList,
    onRemove: (file) => {
      setImage([])
    },
    beforeUpload: (file) => {
      const fileTypes = ['image/jpeg', 'image/png']
      if (!fileTypes.includes(file.type)) {
        toastNotify(
          TOAST.error,
          `${file.name} ${TOASTIFY_MESSAGES.imageNotValidMessage}`
        )
      }
      if (fileTypes.includes(file.type)) {
        setImage([file])
      }
      return false
    },
    fileList: image,
  }

  return (
    <Form.Item label="" requiredMark="">
      <Upload {...props}>
        {customPreview ? (
          customPreview
        ) : (
          <Button label="Select File" icon={<UploadOutlined />} />
        )}
      </Upload>
    </Form.Item>
  )
}

export default ImageUpload
