/**
 * Helper function accepts key and value as arguments and stores data in local storage
 */
export const setItemInLocalStorage = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value))
}
/**
 * Helper function accepts key as argument and returns value stored  in local storage
 */
export const getItemFromLocalStorage = (key) => {
  return JSON.parse(localStorage.getItem(key))
}
/**
 * Helper function accepts key as argument and removes that key data from local storage
 */
export const removeItemFromLocalStorage = (key) => {
  localStorage.removeItem(key)
}
