import React, { useCallback, useEffect, useState } from 'react'
import _ from 'lodash'
import {
  Tooltip,
  Row,
  Col,
  Spin,
  DatePicker,
  Input,
  Select,
  InputNumber,
  Form,
} from 'antd'
import moment from 'moment/moment'
import { useDispatch, useSelector } from 'react-redux'
import dateFormat from 'dateformat'
import { format } from 'date-fns'
import scrollIntoView from 'scroll-into-view'

import './InvoiceTimesheet.scss'
import DataTable from '../DataTable/DataTable'
import {
  DATE_FORMATS,
  DATE_PICKER,
  STATUS,
  TOAST,
  colorIntensity,
  projectModel,
  payModel,
  PREPAID,
  deductedServices,
  billingModelOptions,
} from '../../utils/constants/constant'

import { ReactComponent as Refresh } from '../../assets/icons/refresh-icon.svg'
import {ReactComponent as DatabaseRefresh } from '../../assets/icons/databaseRefresh.svg'
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete.svg'
import Button from '../Button/Button'
import {
  PLACEHOLDER_MESSAGES,
  VALIDATION_MESSAGES,
} from '../../utils/constants/messages'
import { toastNotify } from '../../utils/helperFunctions/toastNotify'
import {
  setTimeSheetsData,
  timeSheetsDataSelector,
} from '../../redux/reducer/timeSheetsDataSlice'
import DetailsModal from '../DetailsModal/DetailsModal'
import {
  getPreRequisiteDataForInvoice,
  getTimesheet,
} from '../../utils/api/projectServices'
import { projectDetailsSelector } from '../../redux/reducer/getProjectsByIdSlice'
import InoviceDetailsHeader from './InvoiceDetailsHeader'
import {
  defaultRowData,
  defaultServiceRowData,
  getEmployeeServiceOptions,
  timesheetSummary,
} from '../TimeSheetModule/timesheetFormSchema'
import {
  fetchTimesheetEmployee,
  getEmployeeDifference,
  getViewInvoiceDetailsByInvoiceId,
  refreshTimesheetDataService,
} from '../../utils/api/predictionAndInvoiceServices'
import DownloadTimeSheetModal from '../DownloadTimeSheetModal/DownloadTimeSheetModal'
import SolidCircle from '../../assets/icons/SolidCircle'
import EmptyRatesModal from './EmptyRatesModal'
import EmployeeDifferenceWarningModal from '../EmployeeDifferenceWarningModal/EmployeeDifferenceWarningModal'
import InvoicePreRequisiteModal from './InvoicePreRequisiteModal'
import TimesheetActionPopover from './TimesheetActionPopover'
import AddNewRowPopover from './AddNewRowPopover'
import InputTextArea from '../InputTextArea/InputTextArea'
import NotePopover from './NotePopover'
import { calculateGetTimesheetEndDate } from '../../utils/helperFunctions/helperFunctions'
import { staticOptionsSelector } from '../../redux/reducer/getOptionsSlice'
import DeleteLineItemModal from './DeleteLineItemModal'
import EmptyBillingWarningModal from '../EmptyBillingWanrningModal/EmptyBillingWarningModal'
import { useHistory } from 'react-router-dom'
import SelectField from '../SelectField/SelectField'
import ProjectNamesTagRender from '../AddInvoiceOrPredictionModule/ProjectNamesTagRender'

const InvoiceTimesheet = ({
  predictionData,
  customTimesheetNameFormat,
  viewInvoiceTimesheetName,
  viewInvoiceId,
  isCustomInvoice,
  setInvoiceBooksData,
  isTMretainerPrepaidModel,
  isServiceNull,
  setIsZohoTimesheetChange,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const { timeSheetsData } = useSelector(timeSheetsDataSelector)
  const [showDetailsModal, setShowDetailsModal] = useState(false)
  const [showDownloadTimesheetModal, setShowDownloadTimesheetModal] =
    useState(false)
  const [modalType, setModalType] = useState('')
  const [detailData, setDetailData] = useState([])

  const { projectId } = useSelector((state) => state?.projectId)
  const { projectDetails } = useSelector(projectDetailsSelector)
  const { invoiceLineItemOptions } = useSelector(staticOptionsSelector)

  const history = useHistory()

  const timesheetNameFormat =
    viewInvoiceTimesheetName ||
    customTimesheetNameFormat ||
    `${projectId}_${predictionData?.startDate}_${
      predictionData?.endDate || 'noEndDate'
    }`
  const [isEmptyRateModalOpen, setIsEmptyRateModalOpen] = useState(false)
  const [emptyRatesList, setEmptyRatesList] = useState([])
  const [isEmployeeDiffModalOpen, setIsEmployeeDiffModalOpen] = useState(false)
  const [employeeDifferenceList, setEmployeeDifferenceList] = useState([])
  const [showInvoicePreRequisiteModal, setShowInvoicePreRequisiteModal] =
    useState(false)
  const [invoicePreRequisiteData, setInvoicePreRequisiteData] = useState([])
  const [isEmptyBillingModalOpen, setIsEmptyBillingModalOpen] = useState(false)
  const [emptyBillingModelList, setEmptyBillingModelList] = useState([])
  const [showRemoveEmployeeModal, setShowRemoveEmployeeModal] = useState(false)
  const [selectedEmployeeData, setSelectedEmployeeData] = useState()
  const [openPopover, setOpenPopover] = useState(false)

  const dispatch = useDispatch()
  const [form] = Form.useForm()

  const currentInvoiceTimesheetData =
    timeSheetsData && timeSheetsData[timesheetNameFormat]
  const isAnyUserRoleEmpty =
    currentInvoiceTimesheetData?.zohoEmployeeMetadataList
      ?.map((userData) => userData?.userRole)
      .includes('')

  const timesheetTableLength =
    currentInvoiceTimesheetData?.zohoEmployeeMetadataList?.length
  const isCurrentInvoiceAddedInQB =
    currentInvoiceTimesheetData?.invoiceGenerateDetails?.modifyInBooks

  const currentDate = new Date()
  const isDedicated = projectDetails?.model === projectModel?.DEDICATED
  const isHourlyPrepaid =
    projectDetails?.model === projectModel?.hourly &&
    projectDetails.payModel === payModel.prepaid

  const showDeductionColumn =
    isDedicated ||
    isHourlyPrepaid ||
    projectDetails?.model === projectModel?.MIXED

  const showModelColumn = projectDetails?.model === projectModel?.MIXED
  const isMixedPrepaidModel = Boolean(
    projectDetails?.model === projectModel?.MIXED &&
      projectDetails?.payModel === PREPAID
  )
  const isShowDatabaseRefresh = viewInvoiceTimesheetName

  const invoiceTimesheetColumns = [
    {
      title: 'Name',
      dataIndex: 'employeeName',
      width: '9%',
      ellipsis: true,
      fixed: true,
      render: (employeeName, record, index) => {
        return record?.userRole ? (
          <Input
            value={employeeName}
            onChange={(e) =>
              editDataInZohoTimesheet('employeeName', e.target.value, record)
            }
          />
        ) : (
          <Select
            className="select-field"
            value={record?.isService ? record?.name : employeeName}
            onChange={(value) =>
              record?.isService
                ? selectLineItemHandler(value, record)
                : selectDeveloperHandler(value, record)
            }
            getPopupContainer={false}
            showSearch={true}
            placeholder={
              record?.isService
                ? PLACEHOLDER_MESSAGES.serviceMessage
                : PLACEHOLDER_MESSAGES.employeeMessage
            }
            options={getEmployeeServiceOptions(
              record?.isService,
              record?.isService
                ? invoiceLineItemOptions
                : currentInvoiceTimesheetData?.timesheetAvailableEmployees
            )}
          />
        )
      },
    },
    {
      title: 'Billing(%)',
      dataIndex: 'billingPercentage',
      ellipsis: true,
      width: '8%',
      render: (billingPercentage, record) => {
        if (record?.isService) {
          return null
        }
        return (
          <div className="billing-percentage">
            <Input
              value={billingPercentage}
              onChange={(e) =>
                editDataInZohoTimesheet(
                  'billingPercentage',
                  e.target.value,
                  record
                )
              }
            />
            <NotePopover
              notes={record?.note}
              onSave={(value) => {
                editDataInZohoTimesheet('note', value, record)
              }}
            />
          </div>
        )
      },
    },
    {
      title: 'Model',
      dataIndex: 'billingModel',
      width: '9%',
      ellipsis: true,
      hidden: !showModelColumn,
      sorter: (a, b) => {
        // Custom sorting function
        const sortOrder = ['Dedicated', 'Hourly']
        const aValue = a.billingModel
          ? sortOrder.indexOf(a.billingModel)
          : sortOrder.length
        const bValue = b.billingModel
          ? sortOrder.indexOf(b.billingModel)
          : sortOrder.length
        return aValue - bValue
      },
      render: (billimodel, record) =>
        billimodel ? (
          billimodel
        ) : (
          <Select
            className="select-field"
            onChange={(value) =>
              editDataInZohoTimesheet('billingModel', value, record)
            }
            getPopupContainer={false}
            placeholder={PLACEHOLDER_MESSAGES.billingModelMessage}
            options={billingModelOptions}
          />
        ),
    },
    {
      title: 'Role',
      dataIndex: 'userRole',
      width: '7%',
      ellipsis: true,
    },
    {
      title: (
        <>
          <Tooltip
            color="#ffffff"
            overlayClassName="tooltip"
            title={() => (
              <>
                <div className="color-info">
                  <span>
                    <SolidCircle radious="10" fillColor="red" />
                  </span>{' '}
                  <h4> : User specific rate</h4>
                </div>
                <div className="color-info">
                  <span>
                    <SolidCircle radious="10" fillColor="green" />
                  </span>{' '}
                  <h4> : Default rate</h4>
                </div>
              </>
            )}
          >
            Rate
          </Tooltip>
        </>
      ),
      dataIndex: 'rate',
      width: '6%',
      ellipsis: true,
      render: (rate, record, index) => {
        if (record?.isService) {
          return null
        }
        return (
          <InputNumber
            className={record?.defaultRateFlag ? 'green' : 'dark-red'}
            precision={2}
            controls={false}
            value={rate}
            onChange={(value) =>
              editDataInZohoTimesheet('rate', Number(value), record)
            }
          />
        )
      },
    },
    {
      title: (
        <>
          <Tooltip
            color="#ffffff"
            overlayClassName="tooltip"
            title={() => (
              <>
                <p>PLB/LT/ULB/DLB/CLB</p>
                <p>PLB = Previous Leave balance</p>
                <p>LT = Leave Taken</p>
                <p>ULB = Used Leave balance</p>
                <p>DLB = Deducted Leave balance</p>
                <p>CLB = Current Leave balance</p>
              </>
            )}
          >
            Leaves Details
          </Tooltip>
        </>
      ),
      dataIndex: 'userLeaveData',
      width: '20%',
      ellipsis: true,
      render: (totalLeaves, record) => {
        if (record?.isService) {
          return null
        }
        return (
          <>
            <span>
              {totalLeaves?.previousLeaveBalance?.toFixed(2) || 0}/
              {totalLeaves?.leaveTaken?.toFixed(2) || 0}/
              {totalLeaves?.leaveUsed?.toFixed(2) || 0} /
              {(totalLeaves?.leaveDeduced / 8)?.toFixed(2) || 0} /
              {totalLeaves?.currentLeaveBalance?.toFixed(2) || 0}
            </span>
            {record?.userLeaveData?.leaveDetails?.length > 0 && (
              <Button
                type="link"
                className="add-package"
                label="details"
                onClick={() => {
                  setDetailData(record)
                  setModalType('leave')
                  setShowDetailsModal(!showDetailsModal)
                }}
              />
            )}
          </>
        )
      },
    },
    {
      title: 'Billable',
      dataIndex: 'billedHours',
      ellipsis: true,
      width: '8%',
      render: (billedHours, record) => {
        return (
          <>
            <span>{billedHours}</span>
            {record?.billableTasks?.length > 0 && (
              <Button
                type="link"
                className="add-package"
                label="details"
                onClick={() => {
                  setDetailData(record)
                  setModalType('billableTasks')
                  setShowDetailsModal(!showDetailsModal)
                }}
              />
            )}
          </>
        )
      },
    },
    {
      title: 'Non Billable',
      dataIndex: 'nonBilledHours',
      ellipsis: true,
      width: '8%',
      render: (nonBilledHours, record) => {
        return (
          <>
            <span>{nonBilledHours}</span>
            {record?.nonBillableTasks?.length > 0 && (
              <Button
                type="link"
                className="add-package"
                label="details"
                onClick={() => {
                  setDetailData(record)
                  setModalType('nonBillableTasks')
                  setShowDetailsModal(!showDetailsModal)
                }}
              />
            )}
          </>
        )
      },
    },
    {
      title: 'OnTime Logs',
      dataIndex: 'onTimeHoursSum',
      ellipsis: true,
      width: '8%',
      render: (onTimeHoursSum, record) => {
        return (
          <>
            <span>{onTimeHoursSum}</span>
            {record?.onTimeLogs?.length > 0 && (
              <Button
                type="link"
                className="add-package"
                label="details"
                onClick={() => {
                  setDetailData(record)
                  setModalType('onTimeLogs')
                  setShowDetailsModal(!showDetailsModal)
                }}
              />
            )}
          </>
        )
      },
    },
    {
      title: (
        <>
          <Tooltip
            color="#ffffff"
            overlayClassName="tooltip"
            title={() => (
              <>
                <p>Ontime vs Zoho hours</p>
                <div className="color-info">
                  <span>
                    <SolidCircle radious="10" fillColor="rgb(49, 164, 51)" />
                  </span>{' '}
                  <h4>{` : -1 < difference < 1`}</h4>
                </div>
                <div className="color-info">
                  <span>
                    <SolidCircle radious="10" fillColor="orange" />
                  </span>{' '}
                  <h4>{` : -4 < difference < 4`}</h4>
                </div>
                <div className="color-info">
                  <span>
                    <SolidCircle radious="10" fillColor="rgb(229, 19, 19)" />
                  </span>{' '}
                  <h4>{` : -4 > difference > 4`}</h4>
                </div>
              </>
            )}
          >
            O vs Z
          </Tooltip>
        </>
      ),
      dataIndex: 'ontimeVsZoho',
      ellipsis: true,
      width: '5%',
      render: (ontimeVsZoho) => {
        const className = colorIntensity(ontimeVsZoho)
        return <p className={className}>{ontimeVsZoho}</p>
      },
    },
    {
      title: (
        <>
          <Tooltip
            color="#ffffff"
            overlayClassName="tooltip"
            title={() => (
              <>
                <div className="color-info">
                  <span>
                    <SolidCircle radious="10" fillColor="rgb(49, 164, 51)" />
                  </span>{' '}
                  <h4>{` : -1 < missing time < 1`}</h4>
                </div>
                <div className="color-info">
                  <span>
                    <SolidCircle radious="10" fillColor="orange" />
                  </span>{' '}
                  <h4>{` : -4 < missing time < 4`}</h4>
                </div>
                <div className="color-info">
                  <span>
                    <SolidCircle radious="10" fillColor="rgb(229, 19, 19)" />
                  </span>{' '}
                  <h4>{` : -4 > missing time > 4`}</h4>
                </div>
              </>
            )}
          >
            <div className="custom-title">Missing Time</div>
          </Tooltip>
        </>
      ),
      dataIndex: 'missingTime',
      ellipsis: true,
      width: '6%',
      render: (missingTime) => {
        const className = colorIntensity(missingTime)
        return <p className={className}>{missingTime}</p>
      },
    },
    {
      title: 'Billed',
      dataIndex: 'calculatedBillingHours',
      width: '6%',
      ellipsis: true,
      render: (billedHours, record, index) => {
        if (record?.isService) {
          return null
        }
        return (
          <InputNumber
            value={billedHours}
            controls={false}
            precision={2}
            onChange={(value) =>
              editDataInZohoTimesheet(
                'calculatedBillingHours',
                Number(value),
                record
              )
            }
          />
        )
      },
    },
    {
      title: 'Over Time',
      dataIndex: 'extraLoggedHours',
      width: '7%',
      ellipsis: true,
      render: (extraLoggedHours, record, index) => {
        if (record?.isService) {
          return null
        }
        return (
          <InputNumber
            value={extraLoggedHours}
            controls={false}
            precision={2}
            onChange={(value) =>
              editDataInZohoTimesheet('extraLoggedHours', Number(value), record)
            }
          />
        )
      },
    },
    {
      title: <div className="custom-title">Leave Deduction</div>,
      dataIndex: 'userLeaveData',
      width: '7%',
      ellipsis: true,
      hidden: !showDeductionColumn,
      render: (userLeaveData, record) => {
        if (record?.isService) {
          return null
        }
        return (
          <InputNumber
            value={userLeaveData?.leaveDeduced || 0}
            precision={2}
            controls={false}
            onChange={(value) => {
              const newUserLeaveData = {
                ...userLeaveData,
                leaveDeduced: Number(value) || 0,
              }
              editDataInZohoTimesheet('userLeaveData', newUserLeaveData, record)
            }}
          />
        )
      },
    },
    {
      title: <div className="custom-title">Other Discount</div>,
      dataIndex: 'discountedHours',
      width: '7%',
      ellipsis: true,
      hidden: isTMretainerPrepaidModel,
      render: (discountedHours, record) => {
        if (record?.isService) {
          return null
        }
        return (
          <InputNumber
            value={discountedHours || 0}
            precision={2}
            controls={false}
            onChange={(value) => {
              editDataInZohoTimesheet('discountedHours', value, record)
            }}
          />
        )
      },
    },
    {
      title: (
        <>
          <Tooltip title={() => 'Billed + Overtime hours - Deduction'}>
            Total
          </Tooltip>
        </>
      ),
      dataIndex: 'totalHours',
      width: '7%',
      render: (_, record) => {
        if (record?.isService) {
          return null
        }
        const calculatedTotalHours =
          record?.calculatedBillingHours +
          record?.extraLoggedHours -
          record?.userLeaveData?.leaveDeduced -
          record?.discountedHours
        return calculatedTotalHours?.toFixed(2)
      },
      ellipsis: true,
    },
    {
      title: 'Off Board Date',
      dataIndex: 'offBoard',
      ellipsis: true,
      width: '13%',
      render: (offBoardDate, record, index) => {
        if (record?.isService) {
          return null
        }
        return (
          <DatePicker
            className="date-field"
            name={`offBoard${index}`}
            value={offBoardDate ? moment(offBoardDate) : null}
            onChange={(_, dateString) => {
              editDataInZohoTimesheet('offBoard', dateString, record)
            }}
            format={DATE_FORMATS.DD_MMM_YYYY}
            picker={DATE_PICKER.date}
            allowClear
          />
        )
      },
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      width: '8%',
      render: (_, record) => {
        if (record?.isService) {
          return (
            <InputNumber
              value={record?.rate || 0}
              precision={2}
              controls={false}
              onChange={(value) => {
                editDataInZohoTimesheet('rate', value, record)
              }}
            />
          )
        }
        const calculatedAmount =
          record?.rate *
          (record?.calculatedBillingHours +
            record?.extraLoggedHours -
            record?.userLeaveData?.leaveDeduced -
            record?.discountedHours)
        return calculatedAmount?.toFixed(2)
      },
    },
    {
      title: 'Action',
      width: '5%',
      ellipsis: true,
      render: (_, record, index) => {
        return (
          <Button
            title={'Remove Developer'}
            onClick={() => {
              setSelectedEmployeeData(record)
              setShowRemoveEmployeeModal(true)
            }}
            icon={<DeleteIcon />}
          />
        )
      },
    },
  ].filter((column) => !column?.hidden)

  const emptyRatesWarningHandler = (zohoEmployeeMetadataList) => {
    const employeeDataWithZeroRate = zohoEmployeeMetadataList?.filter(
      (employee) => employee?.rate === 0
    )
    // check empty billing model for mixed+prepaid model
    const emptyBillingModelList =
      isMixedPrepaidModel &&
      zohoEmployeeMetadataList?.filter(
        (employee) => employee?.billingModel === null
      )
    if (emptyBillingModelList?.length) {
      setIsEmptyBillingModalOpen(true)
      setEmptyBillingModelList(emptyBillingModelList)
    }
    // Set only unique value of techstack into the array

    const employeesWithUniqueTechstack = employeeDataWithZeroRate?.filter(
      (value, index, self) =>
        self.map((userData) => userData?.userRole).indexOf(value?.userRole) ===
        index
    )
    const uniqueTechStacksWithEmptyRate = employeesWithUniqueTechstack?.map(
      (employee) => {
        return { techStack: employee?.userRole, value: 0 }
      }
    )
    if (uniqueTechStacksWithEmptyRate?.length) {
      setEmptyRatesList(uniqueTechStacksWithEmptyRate)
      setIsEmptyRateModalOpen(true)
    } else {
      getEmployeeDifferenceHandler()
    }
  }

  const getEmployeeDifferenceHandler = async () => {
    const zohoEmployeeMetadataList =
      currentInvoiceTimesheetData?.zohoEmployeeMetadataList
    try {
      if (zohoEmployeeMetadataList?.length) {
        const response = await getEmployeeDifference(
          projectId,
          zohoEmployeeMetadataList
        )
        if (
          response?.code === STATUS.ok &&
          response?.data?.['Removed Users']?.length
        ) {
          setIsEmployeeDiffModalOpen(true)
          setEmployeeDifferenceList(response?.data)
        }
      }
    } catch (error) {
      error?.message && toastNotify(TOAST.error, error?.message)
    }
  }

  const fetchInvoicePreRequisiteData = useCallback(
    async () => {
      setIsLoading(true)
      const response = await getPreRequisiteDataForInvoice(
        projectDetails?.zohoProjectId,
        dateFormat(
          currentInvoiceTimesheetData?.invoiceGenerateDetails?.startDate ||
            predictionData?.startDate,
          DATE_FORMATS.mm_dd_yyyy
        ),
        dateFormat(
          currentInvoiceTimesheetData?.invoiceGenerateDetails?.endDate ||
            predictionData?.endDate,
          DATE_FORMATS.mm_dd_yyyy
        )
      )
      if (response?.data?.length) {
        setInvoicePreRequisiteData(response.data)
        setShowInvoicePreRequisiteModal(true)
        setIsLoading(false)
      } else {
        fetchTimesheetData()
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [projectDetails?.zohoProjectId, currentInvoiceTimesheetData]
  )
  const fetchTimesheetData = useCallback(
    async () => {
      setIsLoading(true)
      const getTimesheetEndDate =
        currentInvoiceTimesheetData?.invoiceGenerateDetails?.endDate ||
        predictionData?.endDate
          ? currentInvoiceTimesheetData?.invoiceGenerateDetails?.endDate ||
            predictionData?.endDate
          : calculateGetTimesheetEndDate(
              currentInvoiceTimesheetData?.invoiceGenerateDetails?.startDate ||
                predictionData?.startDate
            )
      const response = viewInvoiceTimesheetName
        ? await getViewInvoiceDetailsByInvoiceId(
            currentInvoiceTimesheetData?.invoiceGenerateDetails?.invoiceId ||
              viewInvoiceId
          )
        : await getTimesheet(
            projectDetails?.zohoProjectId,
            dateFormat(
              currentInvoiceTimesheetData?.invoiceGenerateDetails?.startDate ||
                predictionData?.startDate,
              DATE_FORMATS.mm_dd_yyyy
            ),
            dateFormat(getTimesheetEndDate, DATE_FORMATS.mm_dd_yyyy),
            predictionData?.predictionId
          )
      const currentTime = format(currentDate, DATE_FORMATS.DATE_WITH_TIME)
      const newTimesheetData = {
        ...timeSheetsData,
        [timesheetNameFormat]: {
          ...response?.data,
          lastSyncedTime: currentTime,
        },
      }
      // set quickbook/zohobook data while generated custom invoce
      if (isCustomInvoice && viewInvoiceTimesheetName) {
        setInvoiceBooksData(response?.data?.invoiceLineItemList)
      }
      // set default values when prediction/invoice doesn't have an end date
      const invoiceGenerateDetails =
        newTimesheetData[timesheetNameFormat]?.invoiceGenerateDetails
      if (!predictionData?.endDate && invoiceGenerateDetails) {
        invoiceGenerateDetails.endDateNull = isTMretainerPrepaidModel
        invoiceGenerateDetails.financialType = 'Revenue'
        invoiceGenerateDetails.invoiceStatus = 'Pending'
        invoiceGenerateDetails.invoiceDate = dateFormat(
          currentDate,
          DATE_FORMATS.dd_mmm_yyyy
        )
        invoiceGenerateDetails.predictionId = predictionData?.predictionId
      }
      dispatch(setTimeSheetsData(newTimesheetData))
      emptyRatesWarningHandler(response?.data?.zohoEmployeeMetadataList)
      setIsLoading(false)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [projectDetails?.zohoProjectId, currentInvoiceTimesheetData]
  )
  //  fetch timesheet data from zoho while view Invoice
  const fetchTimesheetDataFromZoho = async () => {
    setIsLoading(true)
    const response = await refreshTimesheetDataService(
      currentInvoiceTimesheetData?.invoiceGenerateDetails?.invoiceId ||
        viewInvoiceId
    )
    if (response?.status === STATUS.ok) {
      const currentTime = format(currentDate, DATE_FORMATS.DATE_WITH_TIME)
      const newTimesheetData = {
        ...timeSheetsData,
        [timesheetNameFormat]: {
          ...response?.data?.data,
          lastSyncedTime: currentTime,
        },
      }
      dispatch(setTimeSheetsData(newTimesheetData))
      emptyRatesWarningHandler(response?.data?.data.zohoEmployeeMetadataList)
    } else {
      response?.data?.message &&
        toastNotify(TOAST.error, response?.data?.message)
    }
    setIsLoading(false)
  }
  useEffect(() => {
    if (!timeSheetsData[timesheetNameFormat]?.zohoEmployeeMetadataList) {
      predictionData?.endDate && fetchInvoicePreRequisiteData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectDetails?.zohoProjectId, predictionData])

  useEffect(() => {
    !predictionData?.endDate &&
      !currentInvoiceTimesheetData &&
      fetchTimesheetData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [predictionData])
  // to set associated projects value to field
  useEffect(() => {
    form.setFieldsValue({
      associatedProjects:
        currentInvoiceTimesheetData?.invoiceGenerateDetails?.associatedProjects,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentInvoiceTimesheetData])
  /**
   *  This function updates value in cached timesheet data
   */
  const editDataInZohoTimesheet = useCallback(
    (updatedKey, newValue, record) => {
      if (record?.isService) {
        const updatedInvoiceLineItemList = [
          ...(currentInvoiceTimesheetData?.invoiceLineItemList ?? [])?.map(
            (item) => {
              return item?.id === record?.id
                ? { ...item, [updatedKey]: newValue }
                : item
            }
          ),
        ]
        const newTimesheetData = {
          ...timeSheetsData,
          [timesheetNameFormat]: {
            ...currentInvoiceTimesheetData,
            invoiceLineItemList: updatedInvoiceLineItemList,
          },
        }
        const isdataChange = _.isEqual(newTimesheetData, timeSheetsData)
        setIsZohoTimesheetChange(!isdataChange)
        dispatch(setTimeSheetsData(newTimesheetData))
      } else {
        const updatedZohoEmployeeMetadataList = [
          ...(currentInvoiceTimesheetData?.zohoEmployeeMetadataList ?? [])?.map(
            (item) => {
              return item.userId === record?.userId
                ? { ...item, [updatedKey]: newValue }
                : item
            }
          ),
        ]
        const newTimesheetData = {
          ...timeSheetsData,
          [timesheetNameFormat]: {
            ...currentInvoiceTimesheetData,
            zohoEmployeeMetadataList: updatedZohoEmployeeMetadataList,
          },
        }
        const isdataChange = _.isEqual(newTimesheetData, timeSheetsData)
        setIsZohoTimesheetChange(!isdataChange)
        dispatch(setTimeSheetsData(newTimesheetData))
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [timeSheetsData, currentInvoiceTimesheetData]
  )

  const addNewDeveloperHandler = async () => {
    setOpenPopover(false)
    setIsLoading(true)
    const timesheetEmployees = await fetchTimesheetEmployee(
      projectDetails?.zohoProjectId,
      dateFormat(predictionData?.endDate, DATE_FORMATS.yyyy_mm_dd),
      currentInvoiceTimesheetData?.zohoEmployeeMetadataList,
      format(
        new Date(
          currentInvoiceTimesheetData?.invoiceGenerateDetails?.timesheetStartDate
        ),
        DATE_FORMATS.yyyy_MM_dd
      ),
      format(
        new Date(
          currentInvoiceTimesheetData?.invoiceGenerateDetails?.timesheetEndDate
        ),
        DATE_FORMATS?.yyyy_MM_dd
      )
    )
    if (timesheetEmployees?.length) {
      const newZohoEmployeeMetaData = [
        ...(currentInvoiceTimesheetData?.zohoEmployeeMetadataList ?? []),
      ]
      const uniqueKeyForNewUser = timesheetTableLength
        ? Math.max(
            ...(
              currentInvoiceTimesheetData?.zohoEmployeeMetadataList ?? []
            )?.map((item) => item?.userId)
          ) + 1
        : 0
      
      newZohoEmployeeMetaData.splice(
        timesheetTableLength,
        0,
        defaultRowData(uniqueKeyForNewUser)
      )
      
      const newTimesheetData = {
        ...timeSheetsData,
        [timesheetNameFormat]: {
          ...currentInvoiceTimesheetData,
          zohoEmployeeMetadataList: newZohoEmployeeMetaData,
          timesheetAvailableEmployees: timesheetEmployees,
        },
      }
      dispatch(setTimeSheetsData(newTimesheetData))
      setIsLoading(false)
      scrollIntoView(document.querySelector('.highlighted-row'), {
        align: {
          top: 0,
          left: 0,
        },
      })
    } else {
      setIsLoading(false)
      toastNotify(TOAST.warning, VALIDATION_MESSAGES.noEmployeeDataMessage)
    }
  }

  const addNewItemHandler = async () => {
    setOpenPopover(false)
    setIsLoading(true)
    const newInvoiceLineItemList = [
      ...(currentInvoiceTimesheetData?.invoiceLineItemList ?? []),
    ]
    const uniqueKeyForNewUser = currentInvoiceTimesheetData?.invoiceLineItemList
      ?.length
      ? Math.max(
          ...(currentInvoiceTimesheetData?.invoiceLineItemList ?? [])?.map(
            (item) => item?.id
          )
        ) + 1
      : 0

    newInvoiceLineItemList.splice(
      timesheetTableLength,
      0,
      defaultServiceRowData(uniqueKeyForNewUser)
    )
    const newTimesheetData = {
      ...timeSheetsData,
      [timesheetNameFormat]: {
        ...currentInvoiceTimesheetData,
        invoiceLineItemList: newInvoiceLineItemList,
      },
    }
    dispatch(setTimeSheetsData(newTimesheetData))
    setIsLoading(false)
    scrollIntoView(document.querySelector('.highlighted-row'), {
      align: {
        top: 0,
        left: 0,
      },
    })
  }
  const selectLineItemHandler = (value, record) => {
    const updatedInvoiceLineItemList = [
      ...(currentInvoiceTimesheetData?.invoiceLineItemList ?? [])?.map(
        (item) => {
          return item.id === record?.id
            ? {
                ...item,
                name: value,
                quantity: deductedServices.includes(value) ? -1 : 1,
                manuallyAdded: true,
              }
            : item
        }
      ),
    ]
    const newTimesheetData = {
      ...timeSheetsData,
      [timesheetNameFormat]: {
        ...currentInvoiceTimesheetData,
        invoiceLineItemList: updatedInvoiceLineItemList,
      },
    }
    dispatch(setTimeSheetsData(newTimesheetData))
  }
  const selectDeveloperHandler = (value, record) => {
    const [employeeName, role] = value.split(' (')
    const selectedEmployee =
      currentInvoiceTimesheetData?.timesheetAvailableEmployees?.filter((data) =>
        Boolean(
          data?.employeeName === employeeName &&
            data?.userRole === role.slice(0, -1)
        )
      )
    const addedEmployeeData = selectedEmployee?.map((data) => {
      return {
        ...data,
        isDeletable: true,
      }
    })
    const newZohoEmployeeMetaData = [
      ...(currentInvoiceTimesheetData?.zohoEmployeeMetadataList ?? []),
    ]
    newZohoEmployeeMetaData?.[
      currentInvoiceTimesheetData?.zohoEmployeeMetadataList?.length - 1
    ] &&
      newZohoEmployeeMetaData.splice(
        timesheetTableLength - 1,
        1,
        addedEmployeeData[0]
      )
    const newTimesheetData = {
      ...timeSheetsData,
      [timesheetNameFormat]: {
        ...currentInvoiceTimesheetData,
        zohoEmployeeMetadataList: newZohoEmployeeMetaData,
      },
    }
    dispatch(setTimeSheetsData(newTimesheetData))
  }

  const deleteRowHandler = (record) => {
    const deletableIndex = record?.isService
      ? currentInvoiceTimesheetData?.invoiceLineItemList?.findIndex(
          (data) => data?.id === record?.id
        )
      : currentInvoiceTimesheetData?.zohoEmployeeMetadataList?.findIndex(
          (data) => data?.userId === record?.userId
        )
    if (record?.isService) {
      const newInvoiceLineItemList = [
        ...(currentInvoiceTimesheetData?.invoiceLineItemList ?? []),
      ]
      newInvoiceLineItemList[deletableIndex] &&
        newInvoiceLineItemList.splice(deletableIndex, 1)
      const newTimesheetData = {
        ...timeSheetsData,
        [timesheetNameFormat]: {
          ...currentInvoiceTimesheetData,
          invoiceLineItemList: newInvoiceLineItemList,
        },
      }
      dispatch(setTimeSheetsData(newTimesheetData))
    } else {
      const newZohoEmployeeMetaData = [
        ...(currentInvoiceTimesheetData?.zohoEmployeeMetadataList ?? []),
      ]
      newZohoEmployeeMetaData[deletableIndex] &&
        newZohoEmployeeMetaData.splice(deletableIndex, 1)
      const newTimesheetData = {
        ...timeSheetsData,
        [timesheetNameFormat]: {
          ...currentInvoiceTimesheetData,
          zohoEmployeeMetadataList: newZohoEmployeeMetaData,
        },
      }
      dispatch(setTimeSheetsData(newTimesheetData))
    }
    setShowRemoveEmployeeModal(false)
    setSelectedEmployeeData()
  }

  const NotesChangeHandler = (value) => {
    const newInvoiceGenerateDetails = {
      ...currentInvoiceTimesheetData?.invoiceGenerateDetails,
      note: value,
    }

    const newTimesheetData = {
      ...timeSheetsData,
      [timesheetNameFormat]: {
        ...currentInvoiceTimesheetData,
        invoiceGenerateDetails: newInvoiceGenerateDetails,
      },
    }

    dispatch(setTimeSheetsData(newTimesheetData))
  }
  // if (
  //   !viewInvoiceTimesheetName &&
  //   !predictionData?.endDate &&
  //   !isCustomInvoice
  // ) {
  //   return (
  //     <Spin spinning={isLoading}>
  //       <div className="not-found-message">
  //         <Button
  //           onClick={() => {
  //             viewInvoiceTimesheetName
  //               ? fetchTimesheetData()
  //               : fetchInvoicePreRequisiteData()
  //           }}
  //           icon={<Refresh />}
  //           className="refresh-btn"
  //         />
  //       </div>
  //       <h3 className="not-found-message">
  //         There is no end date in current prediction.
  //       </h3>
  //       <h3 className="not-found-message">
  //         Please proceed for next step to generate invoice or add end date.
  //       </h3>
  //     </Spin>
  //   )
  // }

  const isDisplayCalculateAmount = Boolean(
    projectDetails?.model === projectModel?.hourly &&
      projectDetails?.payModel === PREPAID &&
      currentInvoiceTimesheetData?.zohoEmployeeMetadataList?.length === 0
  )
  const invoiceLineItemFilteredList = Array.isArray(
    currentInvoiceTimesheetData?.invoiceLineItemList
  )
    ? currentInvoiceTimesheetData.invoiceLineItemList?.filter(
        (lineitem) => lineitem?.manuallyAdded
      )
    : []
  const invoiceTableDataSource = [
    ...(Array.isArray(currentInvoiceTimesheetData?.zohoEmployeeMetadataList)
      ? currentInvoiceTimesheetData.zohoEmployeeMetadataList
      : []),
    ...invoiceLineItemFilteredList?.map((lineitem) => {
      return { ...lineitem, isService: true }
    }),
  ]
  return (
    <Spin spinning={isLoading}>
      <div className="invoice-table-wrapper">
        <div className="invoice-timesheet-table">
          <div className="add-project invoice-collapse">
            <InoviceDetailsHeader
              projectData={projectDetails}
              timesheetNameFormat={timesheetNameFormat}
              isCurrentInvoiceAddedInQB={isCurrentInvoiceAddedInQB}
            />
          </div>
          <div>
            <Row className="download-btn-row">
              <Col>
                <div className="timesheet-title">
                  <>
                    <p className="timesheet-name">
                      Timesheet Details from{' '}
                      {currentInvoiceTimesheetData?.invoiceGenerateDetails
                        ?.timesheetStartDate || 'Start Date'}{' '}
                      to{' '}
                      {currentInvoiceTimesheetData?.invoiceGenerateDetails
                        ?.timesheetEndDate || 'End Date'}
                    </p>
                  </>
                  <Tooltip
                    color="#ffffff"
                    overlayClassName="tooltip"
                    title={() =>
                      isShowDatabaseRefresh
                        ? 'Refresh timesheet'
                        : `Re-fetch Timesheet (Zoho) :  Last synced  ${
                            currentInvoiceTimesheetData?.lastSyncedTime ||
                            'Never'
                          }`
                    }
                  >
                    <Button
                      onClick={() => {
                        viewInvoiceTimesheetName
                          ? fetchTimesheetData()
                          : fetchInvoicePreRequisiteData()
                      }}
                      icon={
                        isShowDatabaseRefresh ? (
                          <DatabaseRefresh />
                        ) : (
                          <Refresh />
                        )
                      }
                      className="database-refresh-btn"
                    />
                  </Tooltip>
                  {isShowDatabaseRefresh && (
                    <Tooltip
                      color="#ffffff"
                      overlayClassName="tooltip"
                      title={() =>
                        `Re-fetch Timesheet (Zoho) :  Last synced  ${
                          currentInvoiceTimesheetData?.lastSyncedTime || 'Never'
                        }`
                      }
                    >
                      <Button
                        onClick={() => {
                          fetchTimesheetDataFromZoho()
                        }}
                        icon={<Refresh />}
                        className="refresh-btn"
                      />
                    </Tooltip>
                  )}
                </div>
              </Col>
              <div className="btn-group timesheet-name">
                <TimesheetActionPopover
                  setIsDownloadModalOpen={setShowDownloadTimesheetModal}
                  zohoProjectId={projectDetails?.zohoProjectId}
                  startDate={
                    currentInvoiceTimesheetData?.invoiceGenerateDetails
                      ?.startDate || predictionData?.startDate
                  }
                  endDate={
                    currentInvoiceTimesheetData?.invoiceGenerateDetails
                      ?.startDate || predictionData?.endDate
                  }
                  timesheetStartDate={
                    currentInvoiceTimesheetData?.invoiceGenerateDetails
                      ?.timesheetStartDate
                  }
                  timesheetEndDate={
                    currentInvoiceTimesheetData?.invoiceGenerateDetails
                      ?.timesheetEndDate
                  }
                  predictionId={predictionData?.predictionId}
                  sheetURL={
                    currentInvoiceTimesheetData?.invoiceGenerateDetails
                      ?.sheetURL
                  }
                  setIsLoading={setIsLoading}
                  isLoading={isLoading}
                />
                <AddNewRowPopover
                  addNewDeveloperHandler={addNewDeveloperHandler}
                  addNewItemHandler={addNewItemHandler}
                  open={openPopover}
                  setOpen={setOpenPopover}
                  disabled={isAnyUserRoleEmpty || isServiceNull}
                />
              </div>
            </Row>
            <DataTable
              columns={invoiceTimesheetColumns}
              scroll={{ x: 2000, y: '40vh' }}
              dataSource={invoiceTableDataSource}
              rowKey={(record) => record?.userId || record?.index || record?.id}
              rowClassName={(record) =>
                record?.userRole === '' || record?.isService
                  ? 'highlighted-row'
                  : ''
              }
              pagination={false}
              summary={(pageData) =>
                timesheetSummary(
                  pageData,
                  showDeductionColumn,
                  isDisplayCalculateAmount,
                  currentInvoiceTimesheetData,
                  showModelColumn,
                  isTMretainerPrepaidModel
                )
              }
            />
            <div className="verification-notes">
              <InputTextArea
                name="messageOnStatement"
                label="Notes:"
                type="text"
                placeholder={PLACEHOLDER_MESSAGES.timesheetNoteMessage}
                onChange={(e) => NotesChangeHandler(e.target.value)}
                maxRows={1}
                existingValue={
                  currentInvoiceTimesheetData?.invoiceGenerateDetails?.note
                }
                className={'notes'}
              />
              {currentInvoiceTimesheetData?.invoiceGenerateDetails
                ?.associatedProjects?.length > 0 && (
                <Form form={form}>
                  <SelectField
                    name={'associatedProjects'}
                    label="Associated Projects"
                    className={'multiple-projects'}
                    placeholder={
                      PLACEHOLDER_MESSAGES.associatedProjectNamesMessage
                    }
                    type="text"
                    mode={'multiple'}
                    tagRender={(props) =>
                      ProjectNamesTagRender({ ...props, closable: false })
                    }
                    existingValue={
                      currentInvoiceTimesheetData?.invoiceGenerateDetails
                        ?.associatedProjects
                    }
                    disabled={true}
                  />
                </Form>
              )}
            </div>
          </div>
        </div>
      </div>
      <DetailsModal
        showDetailsModal={showDetailsModal}
        setShowDetailsModal={setShowDetailsModal}
        modalType={modalType}
        detailData={detailData}
        setIsLoading={setIsLoading}
        startDate={dateFormat(
          currentInvoiceTimesheetData?.invoiceGenerateDetails?.startDate ||
            predictionData?.startDate,
          DATE_FORMATS.mm_dd_yyyy
        )}
        endDate={dateFormat(
          currentInvoiceTimesheetData?.invoiceGenerateDetails?.endDate ||
            predictionData?.endDate,
          DATE_FORMATS.mm_dd_yyyy
        )}
        timesheetStartDate={
          currentInvoiceTimesheetData?.invoiceGenerateDetails
            ?.timesheetStartDate
        }
        timesheetEndDate={
          currentInvoiceTimesheetData?.invoiceGenerateDetails?.timesheetEndDate
        }
        timesheetNameFormat={timesheetNameFormat}
        showDeductionColumn={showDeductionColumn}
        projectName={projectDetails?.name}
      />
      <DownloadTimeSheetModal
        isDownloadModalOpen={showDownloadTimesheetModal}
        setIsDownloadModalOpen={setShowDownloadTimesheetModal}
        timesheetStartDate={
          currentInvoiceTimesheetData?.invoiceGenerateDetails
            ?.timesheetStartDate
        }
        timesheetEndDate={
          currentInvoiceTimesheetData?.invoiceGenerateDetails?.timesheetEndDate
        }
        zohoProjectId={projectDetails?.zohoProjectId}
      />
      <EmptyRatesModal
        isEmptyRateModalOpen={isEmptyRateModalOpen}
        setIsEmptyRateModalOpen={setIsEmptyRateModalOpen}
        emptyRatesList={emptyRatesList}
        setEmptyRatesList={setEmptyRatesList}
        projectId={projectDetails?.projectId}
        editDataInZohoTimesheet={editDataInZohoTimesheet}
        timesheetNameFormat={timesheetNameFormat}
        getEmployeeDifference={getEmployeeDifferenceHandler}
      />
      <EmployeeDifferenceWarningModal
        employeeDifferenceList={employeeDifferenceList}
        isEmployeeModalOpen={isEmployeeDiffModalOpen}
        setIsEmployeeModalOpen={setIsEmployeeDiffModalOpen}
      />
      <InvoicePreRequisiteModal
        showInvoicePreRequisiteModal={showInvoicePreRequisiteModal}
        invoicePreRequisiteData={invoicePreRequisiteData}
        setShowInvoicePreRequisiteModal={setShowInvoicePreRequisiteModal}
        fetchInvoicePreRequisiteData={fetchInvoicePreRequisiteData}
        fetchTimesheetData={fetchTimesheetData}
      />
      <DeleteLineItemModal
        deleteRowHandler={deleteRowHandler}
        selectedEmployeeData={selectedEmployeeData}
        setShowRemoveEmployeeModal={setShowRemoveEmployeeModal}
        showRemoveEmployeeModal={showRemoveEmployeeModal}
        setSelectedEmployeeData={setSelectedEmployeeData}
      />
      <EmptyBillingWarningModal
        isEmptyBillingModalOpen={isEmptyBillingModalOpen}
        setIsEmptyBillingModalOpen={setIsEmptyBillingModalOpen}
        emptyBillingModelList={emptyBillingModelList}
        projectId={projectId}
        history={history}
      />
    </Spin>
  )
}

export default InvoiceTimesheet
