import { createSlice } from '@reduxjs/toolkit'
import { resetAllReduxStates } from './resetAllReduxStatesAction'
const initialState = {
  timeSheetName: null,
}
const generateInvoiceSlice = createSlice({
  name: 'timeSheetName',
  initialState,
  reducers: {
    setTimeSheetName: (state, action) => {
      state.timeSheetName = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetAllReduxStates, () => initialState)
  },
})
export const { setTimeSheetName } = generateInvoiceSlice.actions
export default generateInvoiceSlice.reducer

export const generateInvoiceSelector = (state) => state.timeSheetName
