import './Modal.scss'
import React from 'react'

import { Modal } from 'antd'

const CustomizeModal = ({
  open,
  setShowSearchField,
  children,
  footer,
  title,
  size,
  wrapClassName,
  centered,
  okText,
  handleModalOk,
  handleModalCancel,
  cancelText,
  closable,
  className,
  modalRender,
  okButtonProps,
  cancelButtonProps,
  maskClosable,
  confirmLoading,
  bodyStyle,
}) => {
  const handleCancel = () => {
    setShowSearchField && setShowSearchField(false)
    handleModalCancel && handleModalCancel()
  }
  const handleOk = () => {
    setShowSearchField && setShowSearchField(false)
    handleModalOk && handleModalOk()
  }
  return (
    <Modal
      title={title}
      closable={closable}
      open={open}
      footer={footer}
      centered={centered}
      width={size}
      wrapClassName={wrapClassName}
      onOk={handleOk}
      onCancel={handleCancel}
      className={`modal-wrap ${className}`}
      okText={okText}
      cancelText={cancelText}
      okButtonProps={okButtonProps || { disabled: false }}
      cancelButtonProps={cancelButtonProps || { danger: true }}
      modalRender={modalRender}
      maskClosable={maskClosable === 'noClose' ? false : true}
      confirmLoading={confirmLoading}
      bodyStyle={bodyStyle}
    >
      <div className="modal-content">{children}</div>
    </Modal>
  )
}

export default CustomizeModal
