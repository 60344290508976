import { createSlice } from '@reduxjs/toolkit'
import { resetAllReduxStates } from './resetAllReduxStatesAction'
import { DEFAULT_DASHBOARD_FILTER } from '../../utils/constants/constant'
import { setAllCsmState } from './setCsmAccountantAction'

const initialState = {
  dashboardData: {},
  appliedFilter: DEFAULT_DASHBOARD_FILTER,
}
const invoiceDashboardSlice = createSlice({
  name: 'dashboardData',
  initialState,
  reducers: {
    clearCache: (state) => {
      state.dashboardData = {}
    },
    setDashboardData: (state, { payload }) => {
      state.dashboardData = payload
    },
    setAppliedFilter: (state, { payload }) => {
      state.appliedFilter = payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(resetAllReduxStates, () => initialState)
      .addCase(setAllCsmState, (state, { payload }) => {
        state.appliedFilter = {
          ...DEFAULT_DASHBOARD_FILTER,
          primaryCsm: [payload.name],
        }
      })
  },
})

export const { setDashboardData, setAppliedFilter, clearCache } =
  invoiceDashboardSlice.actions

export const invoiceDashboardSelector = (state) => state.dashboardData

export default invoiceDashboardSlice.reducer
