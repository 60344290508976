import React from 'react'

import './EmployeeDifferenceWarningModal.scss'
import CustomizeModal from '../Modal/Modal'
import { Badge } from 'antd'

const EmployeeDifferenceWarningModal = ({
  isEmployeeModalOpen,
  setIsEmployeeModalOpen,
  employeeDifferenceList,
}) => (
  <CustomizeModal
    open={isEmployeeModalOpen}
    title={
      'Attention: Discrepancy in Employee Data Compared to Previous Invoice'
    }
    centered={true}
    handleModalCancel={() => {
      setIsEmployeeModalOpen()
    }}
    footer={null}
    className="employeeDifferenceWarningModal"
  >
    <div className="value-container">
      <div className="employee">
        {employeeDifferenceList?.['Removed Users']?.length > 0 && (
          <>
            <h3>Removed Employee</h3>
            {employeeDifferenceList?.['Removed Users']?.map((tech) => (
              <Badge key={tech} count={tech} />
            ))}
          </>
        )}
      </div>
      <div className="employee">
        {employeeDifferenceList?.['New Users']?.length > 0 && (
          <>
            <h3>Newly Added Employee</h3>
            {employeeDifferenceList?.['New Users']?.map((tech) => (
              <Badge key={tech} count={tech} />
            ))}
          </>
        )}
      </div>
    </div>
  </CustomizeModal>
)

export default EmployeeDifferenceWarningModal
