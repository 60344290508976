export const onTimeColumns = [
  {
    title: 'date',
    dataIndex: 'date',
    ellipsis: true,
  },

  {
    title: 'Hours',
    dataIndex: 'ontimeLogs',
    render: (ontimeLogs) =>
      `${ontimeLogs?.hoursWorked} ${
        ontimeLogs?.ontimeCategory && `(${ontimeLogs?.ontimeCategory})`
      }`,
  },
]
export const hoursBreakColumn = [
  {
    title: 'Task',
    dataIndex: 'taskName',
    ellipsis: true,
    width: '75%',
  },
  {
    title: 'Hours spend',
    dataIndex: 'totalHours',
    render: (totalHours) => totalHours,
    ellipsis: true,
  },
]
export const leavesBreakColumn = [
  {
    title: 'Date',
    dataIndex: 'date',
    width: '25%',
  },
  {
    title: 'Days',
    dataIndex: 'numberOfDays',
    ellipsis: true,
    width: '10%',
  },
  {
    title: 'Reason',
    dataIndex: 'reason',
    ellipsis: true,
    width: '65%',
  },
]
