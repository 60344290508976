import { createSlice } from '@reduxjs/toolkit'
import { resetAllReduxStates } from './resetAllReduxStatesAction'
const initialState = {
  salesPersonsList: {},
  appliedFilter: {
    isFilter: true,
    filterSource: { names: null, countries: null },
  },
}
const salesPersonsSlice = createSlice({
  name: 'salesPersonsList',
  initialState,
  reducers: {
    setSalesPersonsList: (state, { payload }) => {
      state.salesPersonsList = payload
    },
    setAppliedFilter: (state, { payload }) => {
     state.appliedFilter = payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetAllReduxStates, () => initialState)
  },
})

export const { setSalesPersonsList, setAppliedFilter } = salesPersonsSlice.actions

export const salesPersonsListSelector = (state) => state.salesPersonsList

export default salesPersonsSlice.reducer
